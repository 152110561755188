.modern-radio-container {
   margin: 12px 8px;
   display: flex;
   cursor: pointer;
   user-select: none;
}
.radio-outer-circle {
   width: 18px;
   height: 18px;
   min-width: 18px;
   min-height: 18px;
   border: 2px solid #9d45f5;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   transition: all 0.1s linear;
}
.radio-inner-circle {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: #9d45f5;
   transition: all 0.1s linear;
}
.unselected {
   border: 2px solid;
}
.unselected-circle {
   width: 0;
   height: 0;
}
.helper-text {
   font-size: 18px;
   padding-right: 8px;
   text-transform: capitalize;
}
@media (min-width: 0px) and (max-width: 599px) {
   .helper-text {
      font-size: 15px;
   }
}
.modern-radio-container {
   margin: 7px 8px;
   display: flex;
   cursor: pointer;
   user-select: none;
}
.radio-outer-circle {
   width: 18px;
   height: 18px;
   min-width: 18px;
   min-height: 18px;
   border: 2px solid #9d45f5;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   transition: all 0.1s linear;
}
.radio-inner-circle {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: #9d45f5;
   transition: all 0.1s linear;
}
.unselected {
   border: 2px solid;
}
.unselected-circle {
   width: 0;
   height: 0;
}
.helper-text {
   font-size: 18px;
   padding-right: 8px;
   text-transform: capitalize;
}
@media (min-width: 0px) and (max-width: 599px) {
   .helper-text {
      font-size: 15px;
   }
}
