.register-section {
   display: flex;
   flex-direction: column;
   padding: 1rem;
}
.register-section .button-group {
   display: flex;
   justify-content: center;
   margin-bottom: 1rem;
}
.register-section .button-group button {
   font-size: 1rem;
   background-color: transparent;
   box-shadow: none;
   padding: 0.5rem;
   border: 1px solid #b061ff;
   width: 14ch;
   transition: 0.2s;
   color: white;
}
.register-section .button-group button:hover {
   cursor: pointer;
   background: rgba(49, 48, 82, 0.9);
}
.register-section .button-group button:first-child {
   border-radius: 6px 0 0 6px;
   border-right: none;
}
.register-section .button-group button:last-child {
   border-radius: 0 6px 6px 0;
}
.register-section .button-group button.active {
   background-color: #b061ff;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   cursor: default;
}
.register-section .create-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
}
.register-section .create-team-subpage form {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   width: 9rem;
   margin: auto;
   margin-top: 1rem;
}
.register-section .create-team-subpage form input[type="text"] {
   font-size: 1rem;
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   width: 9rem;
}
.register-section .create-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 9rem;
   margin: auto;
   margin-top: 0.5rem;
   font-size: 1rem;
   cursor: pointer;
}
.register-section .create-team-subpage form input[type="submit"]:hover {
   transform: scale(1.05);
   transition: 0.5s;
}
.register-section .create-team-subpage form .fake-create-button {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 12ch;
   margin: auto;
   margin-top: 0.5rem;
}
.register-section .join-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
}
.register-section .join-team-subpage form {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   width: 9rem;
   margin: auto;
   font-size: 1rem;
   margin-top: 1rem;
}
.register-section .join-team-subpage form input[type="number"] {
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   font-size: 1rem;
   width: 9rem;
}
.register-section .join-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: fit-content;
   margin: auto;
   margin-top: 0.5rem;
   font-size: 1rem;
   cursor: pointer;
}
.register-section .join-team-subpage form input[type="submit"]:hover {
   transform: scale(1.05);
   transition: 0.5s;
}
.register-section .join-team-subpage form .fake-join-button {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 10ch;
   margin: auto;
   margin-top: 0.5rem;
}

@media screen and (max-width: 1400px) {
   .register-section {
      margin-top: 0.1rem;
   }

   .register-section .create-team-subpage {
      margin-top: 1rem;
   }

   .register-section .join-team-subpage {
      margin-top: 1rem;
   }
}
