@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
html {
   scroll-behavior: smooth !important;
}
.app-container {
   height: 100%;
   width: 100%;
   font-family: "Open Sans", sans-serif;
}
.app-container .mobile-profile-icon {
   display: none;
   z-index: 103;
}

.nav-shift {
   margin-left: 110px;
   height: auto;
   min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
   width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
   box-shadow: none;
   border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #211f2e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #801dc2;
}
#root {
   overflow-x: hidden;
   width: 100vw;
   position: relative;
}
.backdrop-filter-blur {
   border-radius: 20px;
   background: rgba(217, 177, 250, 0.233);
   position: relative;
}
/* support backdrop-filter */
@supports ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   .backdrop-filter-blur::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      -webkit-backdrop-filter: blur(10.5px);
      backdrop-filter: blur(10.5px);
      z-index: -1;
      border-radius: 20px;
   }
}
/* no support backdrop-filter */
@supports not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   .backdrop-filter-blur {
      background: rgba(189, 185, 243, 0.281);
      box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
   }
}

/* ---------------------------- Responsiveness ----------------------------*/

/* MOBILE */
@media (min-width: 0px) and (max-width: 599px) {
   .app-container .mobile-profile-icon {
      display: block;
      position: absolute;
      top: 2.5rem;
      right: 1rem;
      height: 40px;
      width: 40px;
      z-index: 10;
   }
   .app-container .mobile-profile-icon .navbarProfile {
      padding: 0;
   }
   .nav-shift {
      margin-left: 0px;
   }
}

/* TABLET */
@media (min-width: 600px) and (max-width: 1023) {
}

/* Page transition */

.home-body,
.events-body,
.schedule-body,
.contacts-body,
.competitions-body {
   transition: opacity 100ms ease-in-out;
}

/* .fade-enter {
  opacity: 0;
  transform: translateX(-100%);
  z-index: 10;
} */

.fade-enter.fade-enter-active {
   background: #6c10aa;
   /* opacity: 1; */
   transform: translateX(-10%);
   margin-bottom: 30% !important;
   animation-name: swipe;
   animation-duration: 1000ms;
   height: 100vh;
   animation-timing-function: cubic-bezier(0.46, 0.96, 0.6, 0.07);
   z-index: 10;
}

.fade-enter.fade-enter-active .home-body,
.fade-exit.fade-exit-active .home-body,
.fade-enter.fade-enter-active .events-body,
.fade-exit.fade-exit-active .events-body,
.fade-enter.fade-enter-active .schedule-body,
.fade-exit.fade-exit-active .schedule-body,
.fade-enter.fade-enter-active .contacts-body,
.fade-exit.fade-exit-active .contacts-body,
.fade-enter.fade-enter-active .competitions-body,
.fade-exit.fade-exit-active .competitions-body {
   opacity: 0 !important;
}

@keyframes swipe {
   0% {
      transform: translateX(-100%);
   }
   100% {
      /* opacity: 1; */
      transform: translateX(100%);
   }
}
