.schedule {
  z-index: 100;
  padding-bottom: 100px;
  overflow: hidden;
}
.scheduleContainer {
  max-width: 75%;
  min-width: 280px;
  margin: 0 auto;
}
.scheduleFilter {
  color: white;
  display: flex;
  pointer-events: none;
}
.scheduleFilterSensitive {
  display: flex;
  align-items: center;
  padding: 12px 6px;
  font-weight: bold;
  cursor: pointer;
  pointer-events: initial;
}
.scheduleFilterSensitive:hover {
  transform: scale(1.1);
  color: rgb(185, 185, 185);
}
.scheduleFilterInsensitive {
  display: flex;
  flex-grow: 1;
  cursor: default;
}
.scheduleDayContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 0.5rem;
}
.scheduleDayContainer div {
  text-transform: uppercase;
  font-weight: 700;
}
.scheduleDay {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px 0;
  transition: all 0.2s ease;
  color: white;
  z-index: 1;
  border: 2px solid #fff;
  background: #1d1b27;
  border-radius: 20px 2px;
}

.scheduleDay:hover {
  opacity: 1;
  transition-duration: 0.2s;
  color: white;
  background-color: #100f16;
  box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
}

.scheduleSelectedDay {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background: #b061ff;

  color: white;
  z-index: 2;
  cursor: default;
  border-radius: 20px 2px;
}
.sheduledata__container {
  background: #2f1c479d;
  border-radius: 20px;
}
.sheduledata__container2 {
  padding: 2rem;
}

@media only screen and (max-width: 980px) {
  .scheduleContainer {
    max-width: 90%;
  }
  .scheduleDayContainer div {
    font-variant-caps: petite-caps;
  }
  .scheduleDay,
  .scheduleSelectedDay {
    display: flex;
    padding: 8px 0;
    font-size: 0.7rem;
    transition: all 0.2s ease;
    color: white;
    z-index: 1;
    border: 2px solid #fff;
  }

  .sheduledata__container2 {
    padding: 0;
    padding-left: 0.5rem;
  }
}
