@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap");
body {
   background-color: var(--background-color);
}
select {
   -webkit-appearance: none;
   -moz-appearance: none;
   text-indent: 1px;
   text-overflow: "";
}

.page-title {
   color: var(--heading);
   font-size: 65px;
   font-weight: 800;
   font-family: "Raleway", sans-serif;
   text-align: center;
   margin-top: 24px;
   margin-bottom: 9px;
}
select {
   padding-right: 15px !important;
}
.event-page-title {
   margin-top: 10px;
}

@media only screen and (max-width: 480px) {
   .page-title {
      font-size: 47px;
   }
}
@media only screen and (max-width: 601px) {
   .eventsContainer {
      margin-top: 30px;
      top: -61px;
   }
   .page-title {
      margin-top: 18% !important;
   }
}

@media (min-width: 1800px) and (max-width: 2070px) {
   .page-title {
      font-size: 57px;
   }
}
/* STAR BACKGROUND */
#stars {
   width: 1px;
   height: 1px;
   background: transparent;
   box-shadow: 331px 1458px #adb5bd, 678px 31px #adb5bd, 1726px 1570px #adb5bd, 351px 38px #adb5bd, 219px 1167px #adb5bd, 1488px 217px #adb5bd, 1725px 96px #adb5bd, 1014px 1763px #adb5bd, 300px 1023px #adb5bd, 1497px 1659px #adb5bd, 1528px 1786px #adb5bd, 353px 518px #adb5bd, 1022px 297px #adb5bd,
      1579px 1746px #adb5bd, 28px 1989px #adb5bd, 1469px 1563px #adb5bd, 1703px 720px #adb5bd, 338px 1361px #adb5bd, 1543px 1997px #adb5bd, 785px 810px #adb5bd, 584px 87px #adb5bd, 1599px 1719px #adb5bd, 605px 1383px #adb5bd, 182px 1195px #adb5bd, 1688px 103px #adb5bd, 316px 1657px #adb5bd,
      1696px 383px #adb5bd, 1919px 1376px #adb5bd, 1817px 176px #adb5bd, 1089px 812px #adb5bd, 620px 276px #adb5bd, 744px 1999px #adb5bd, 1568px 768px #adb5bd, 1961px 1672px #adb5bd, 785px 633px #adb5bd, 1546px 999px #adb5bd, 1145px 29px #adb5bd, 469px 1208px #adb5bd, 691px 1737px #adb5bd,
      695px 1244px #adb5bd, 1920px 923px #adb5bd, 487px 118px #adb5bd, 738px 743px #adb5bd, 508px 1742px #adb5bd, 499px 1333px #adb5bd, 663px 1328px #adb5bd, 1058px 1030px #adb5bd, 314px 912px #adb5bd, 555px 1174px #adb5bd, 709px 1190px #adb5bd, 1216px 375px #adb5bd, 1107px 400px #adb5bd,
      956px 569px #adb5bd, 862px 187px #adb5bd, 761px 119px #adb5bd, 601px 986px #adb5bd, 1288px 1367px #adb5bd, 597px 1146px #adb5bd, 564px 592px #adb5bd, 1372px 1052px #adb5bd, 952px 363px #adb5bd, 983px 822px #adb5bd, 1731px 517px #adb5bd, 482px 105px #adb5bd, 1309px 1639px #adb5bd,
      1259px 1358px #adb5bd, 337px 731px #adb5bd, 395px 820px #adb5bd, 225px 647px #adb5bd, 1141px 565px #adb5bd, 1224px 533px #adb5bd, 1526px 90px #adb5bd, 1154px 106px #adb5bd, 970px 729px #adb5bd, 293px 1660px #adb5bd, 1195px 734px #adb5bd, 569px 24px #adb5bd, 779px 576px #adb5bd,
      480px 110px #adb5bd, 895px 1787px #adb5bd, 139px 1702px #adb5bd, 1139px 1618px #adb5bd, 1218px 630px #adb5bd, 811px 1229px #adb5bd, 581px 1628px #adb5bd, 868px 683px #adb5bd, 1012px 1358px #adb5bd, 1408px 1326px #adb5bd, 1928px 908px #adb5bd, 221px 221px #adb5bd, 351px 1725px #adb5bd,
      514px 876px #adb5bd, 736px 1083px #adb5bd, 918px 236px #adb5bd, 1062px 1313px #adb5bd, 177px 1289px #adb5bd, 329px 1326px #adb5bd, 1143px 1354px #adb5bd, 1872px 1236px #adb5bd, 1000px 389px #adb5bd, 1685px 568px #adb5bd, 49px 1996px #adb5bd, 1552px 1773px #adb5bd, 1873px 971px #adb5bd,
      1643px 1222px #adb5bd, 1455px 1620px #adb5bd, 1246px 1160px #adb5bd, 743px 1981px #adb5bd, 1340px 1747px #adb5bd, 274px 273px #adb5bd, 528px 68px #adb5bd, 461px 1585px #adb5bd, 728px 1323px #adb5bd, 1035px 1036px #adb5bd, 1536px 978px #adb5bd, 424px 34px #adb5bd, 1466px 958px #adb5bd,
      930px 257px #adb5bd, 1803px 948px #adb5bd, 1750px 1480px #adb5bd, 1008px 488px #adb5bd, 1334px 114px #adb5bd, 1344px 643px #adb5bd, 945px 618px #adb5bd, 1698px 1569px #adb5bd, 399px 1215px #adb5bd, 492px 537px #adb5bd, 806px 875px #adb5bd, 1776px 695px #adb5bd, 728px 1232px #adb5bd,
      714px 118px #adb5bd, 461px 1765px #adb5bd, 1868px 1460px #adb5bd, 1307px 17px #adb5bd, 395px 1759px #adb5bd, 1620px 1818px #adb5bd, 906px 218px #adb5bd, 1398px 181px #adb5bd, 826px 1502px #adb5bd, 1932px 1963px #adb5bd, 388px 292px #adb5bd, 1580px 415px #adb5bd, 436px 1084px #adb5bd,
      305px 1115px #adb5bd, 973px 1252px #adb5bd, 626px 1135px #adb5bd, 974px 1121px #adb5bd, 1632px 215px #adb5bd, 1670px 627px #adb5bd, 551px 448px #adb5bd, 1758px 1674px #adb5bd, 1825px 1939px #adb5bd, 993px 1176px #adb5bd, 1329px 404px #adb5bd, 1818px 1833px #adb5bd, 329px 257px #adb5bd,
      781px 1142px #adb5bd, 25px 1164px #adb5bd, 644px 863px #adb5bd, 1638px 508px #adb5bd, 107px 1949px #adb5bd, 329px 1192px #adb5bd, 481px 70px #adb5bd, 860px 1123px #adb5bd, 714px 1509px #adb5bd, 798px 29px #adb5bd, 1329px 1528px #adb5bd, 533px 81px #adb5bd, 791px 1345px #adb5bd,
      930px 269px #adb5bd, 54px 967px #adb5bd, 309px 460px #adb5bd, 1405px 1979px #adb5bd, 1548px 1344px #adb5bd, 1249px 945px #adb5bd, 222px 1121px #adb5bd, 726px 263px #adb5bd, 1830px 433px #adb5bd, 1772px 1049px #adb5bd, 1883px 868px #adb5bd, 1173px 844px #adb5bd, 1587px 1879px #adb5bd,
      564px 692px #adb5bd, 1635px 1495px #adb5bd, 811px 424px #adb5bd, 1902px 1279px #adb5bd, 202px 1429px #adb5bd, 154px 19px #adb5bd, 1629px 982px #adb5bd, 28px 1087px #adb5bd, 68px 1940px #adb5bd, 363px 129px #adb5bd, 95px 329px #adb5bd, 1758px 95px #adb5bd, 726px 1546px #adb5bd,
      1812px 1643px #adb5bd, 1880px 193px #adb5bd, 1145px 251px #adb5bd, 427px 597px #adb5bd, 1632px 810px #adb5bd, 1206px 15px #adb5bd, 1986px 1382px #adb5bd, 1233px 591px #adb5bd, 549px 1280px #adb5bd, 1382px 1638px #adb5bd, 1447px 500px #adb5bd, 642px 359px #adb5bd, 1933px 809px #adb5bd,
      978px 41px #adb5bd, 515px 1024px #adb5bd, 927px 1349px #adb5bd, 1121px 137px #adb5bd, 1406px 1247px #adb5bd, 217px 1662px #adb5bd, 1947px 85px #adb5bd, 1939px 1548px #adb5bd, 560px 931px #adb5bd, 1139px 343px #adb5bd, 1326px 63px #adb5bd, 1689px 1349px #adb5bd, 1223px 1202px #adb5bd,
      796px 762px #adb5bd, 802px 488px #adb5bd, 832px 519px #adb5bd, 1808px 361px #adb5bd, 515px 1991px #adb5bd, 587px 117px #adb5bd, 1040px 1231px #adb5bd, 1944px 15px #adb5bd, 235px 1826px #adb5bd, 1474px 1172px #adb5bd, 952px 1131px #adb5bd, 1122px 43px #adb5bd, 1305px 246px #adb5bd,
      389px 1811px #adb5bd, 1783px 516px #adb5bd, 1901px 534px #adb5bd, 926px 57px #adb5bd, 1029px 1924px #adb5bd, 1835px 1616px #adb5bd, 1234px 356px #adb5bd, 140px 1176px #adb5bd, 1518px 1312px #adb5bd, 1298px 556px #adb5bd, 916px 1106px #adb5bd, 1397px 1374px #adb5bd, 350px 1444px #adb5bd,
      737px 88px #adb5bd, 1695px 1671px #adb5bd, 432px 1784px #adb5bd, 25px 1911px #adb5bd, 1156px 8px #adb5bd, 401px 620px #adb5bd, 301px 1816px #adb5bd, 526px 1536px #adb5bd, 8px 1706px #adb5bd, 416px 1472px #adb5bd, 1196px 888px #adb5bd, 1110px 877px #adb5bd, 896px 210px #adb5bd,
      840px 595px #adb5bd, 1889px 1096px #adb5bd, 1486px 1568px #adb5bd, 148px 1700px #adb5bd, 1839px 911px #adb5bd, 377px 563px #adb5bd, 890px 1103px #adb5bd, 126px 645px #adb5bd, 1506px 1748px #adb5bd, 1732px 1231px #adb5bd, 1618px 98px #adb5bd, 981px 453px #adb5bd, 1113px 1475px #adb5bd,
      477px 1518px #adb5bd, 1113px 1771px #adb5bd, 73px 334px #adb5bd, 1464px 1368px #adb5bd, 11px 666px #adb5bd, 351px 1328px #adb5bd, 1875px 96px #adb5bd, 1867px 1449px #adb5bd, 272px 171px #adb5bd, 318px 1465px #adb5bd, 1109px 1161px #adb5bd, 273px 1098px #adb5bd, 1968px 848px #adb5bd,
      955px 474px #adb5bd, 1892px 1501px #adb5bd, 432px 107px #adb5bd, 1246px 221px #adb5bd, 945px 1656px #adb5bd, 1450px 819px #adb5bd, 594px 452px #adb5bd, 441px 621px #adb5bd, 594px 1399px #adb5bd, 843px 997px #adb5bd, 823px 885px #adb5bd, 30px 4px #adb5bd, 832px 468px #adb5bd,
      1681px 986px #adb5bd, 1196px 274px #adb5bd, 1593px 328px #adb5bd, 775px 28px #adb5bd, 71px 1706px #adb5bd, 971px 691px #adb5bd, 175px 695px #adb5bd, 145px 321px #adb5bd, 1055px 1059px #adb5bd, 403px 233px #adb5bd, 865px 456px #adb5bd, 950px 1826px #adb5bd, 59px 84px #adb5bd, 740px 68px #adb5bd,
      1744px 1949px #adb5bd, 373px 1286px #adb5bd, 1955px 1607px #adb5bd, 1946px 1606px #adb5bd, 804px 471px #adb5bd, 960px 949px #adb5bd, 1675px 1027px #adb5bd, 97px 1019px #adb5bd, 1594px 894px #adb5bd, 1267px 1736px #adb5bd, 1768px 1974px #adb5bd, 1710px 531px #adb5bd, 1961px 1946px #adb5bd,
      464px 144px #adb5bd, 1354px 1021px #adb5bd, 1485px 681px #adb5bd, 612px 1973px #adb5bd, 305px 1409px #adb5bd, 513px 739px #adb5bd, 234px 813px #adb5bd, 538px 1610px #adb5bd, 404px 508px #adb5bd, 1516px 745px #adb5bd, 436px 1408px #adb5bd, 1917px 1434px #adb5bd, 1488px 1989px #adb5bd,
      1118px 610px #adb5bd, 126px 512px #adb5bd, 1988px 1102px #adb5bd, 1785px 1538px #adb5bd, 1618px 348px #adb5bd, 542px 595px #adb5bd, 48px 1691px #adb5bd, 155px 70px #adb5bd, 358px 1460px #adb5bd, 1796px 332px #adb5bd, 700px 431px #adb5bd, 914px 856px #adb5bd, 500px 1294px #adb5bd,
      1983px 38px #adb5bd, 1655px 1034px #adb5bd, 998px 277px #adb5bd, 742px 1946px #adb5bd, 1022px 862px #adb5bd, 853px 782px #adb5bd, 657px 113px #adb5bd, 170px 1576px #adb5bd, 1163px 1794px #adb5bd, 169px 1837px #adb5bd, 888px 633px #adb5bd, 1717px 647px #adb5bd, 1345px 432px #adb5bd,
      12px 620px #adb5bd, 1599px 891px #adb5bd, 875px 589px #adb5bd, 771px 1596px #adb5bd, 1984px 1445px #adb5bd, 1810px 576px #adb5bd, 630px 396px #adb5bd, 765px 1591px #adb5bd, 364px 1773px #adb5bd, 520px 1984px #adb5bd, 298px 218px #adb5bd, 790px 1846px #adb5bd, 58px 1525px #adb5bd,
      44px 382px #adb5bd, 831px 655px #adb5bd, 376px 1919px #adb5bd, 691px 765px #adb5bd, 1416px 1121px #adb5bd, 284px 1184px #adb5bd, 1123px 765px #adb5bd, 1835px 238px #adb5bd, 1889px 1262px #adb5bd, 1516px 546px #adb5bd, 1989px 1190px #adb5bd, 663px 1291px #adb5bd, 1338px 1759px #adb5bd,
      428px 1715px #adb5bd, 1296px 874px #adb5bd, 984px 929px #adb5bd, 154px 1062px #adb5bd, 1891px 1307px #adb5bd, 93px 378px #adb5bd, 292px 1013px #adb5bd, 808px 238px #adb5bd, 1888px 833px #adb5bd, 634px 629px #adb5bd, 1862px 598px #adb5bd, 239px 85px #adb5bd, 1449px 473px #adb5bd,
      1633px 1867px #adb5bd, 13px 356px #adb5bd, 1569px 961px #adb5bd, 1906px 249px #adb5bd, 973px 866px #adb5bd, 70px 801px #adb5bd, 1202px 1392px #adb5bd, 1446px 1141px #adb5bd, 1938px 663px #adb5bd, 1179px 138px #adb5bd, 573px 1507px #adb5bd, 1693px 595px #adb5bd, 661px 930px #adb5bd,
      74px 575px #adb5bd, 369px 1966px #adb5bd, 1591px 457px #adb5bd, 1858px 252px #adb5bd, 1528px 12px #adb5bd, 67px 872px #adb5bd, 1381px 875px #adb5bd, 445px 465px #adb5bd, 657px 223px #adb5bd, 171px 1194px #adb5bd, 1140px 1571px #adb5bd, 1095px 1282px #adb5bd, 314px 1681px #adb5bd,
      69px 1650px #adb5bd, 99px 1468px #adb5bd, 1540px 1826px #adb5bd, 155px 1291px #adb5bd, 1985px 417px #adb5bd, 266px 62px #adb5bd, 802px 1209px #adb5bd, 662px 1203px #adb5bd, 251px 1267px #adb5bd, 1117px 1127px #adb5bd, 1328px 725px #adb5bd, 17px 1887px #adb5bd, 1528px 270px #adb5bd,
      1802px 1483px #adb5bd, 979px 1003px #adb5bd, 1355px 1299px #adb5bd, 1152px 1335px #adb5bd, 467px 29px #adb5bd, 393px 1210px #adb5bd, 341px 179px #adb5bd, 501px 1043px #adb5bd, 758px 801px #adb5bd, 482px 1225px #adb5bd, 564px 305px #adb5bd, 1401px 1015px #adb5bd, 437px 1044px #adb5bd,
      1889px 64px #adb5bd, 1238px 1121px #adb5bd, 1000px 1805px #adb5bd, 578px 897px #adb5bd, 1387px 1859px #adb5bd, 427px 1422px #adb5bd, 427px 771px #adb5bd, 1234px 1183px #adb5bd, 508px 625px #adb5bd, 490px 1676px #adb5bd, 906px 1327px #adb5bd, 1202px 1050px #adb5bd, 1350px 1600px #adb5bd,
      783px 1671px #adb5bd, 147px 1170px #adb5bd, 1038px 867px #adb5bd, 832px 104px #adb5bd, 34px 1194px #adb5bd, 392px 643px #adb5bd, 1598px 417px #adb5bd, 1254px 1036px #adb5bd, 1111px 1748px #adb5bd, 1503px 1698px #adb5bd, 80px 1459px #adb5bd, 1747px 1905px #adb5bd, 1646px 1958px #adb5bd,
      1196px 1797px #adb5bd, 312px 539px #adb5bd, 1139px 1576px #adb5bd, 910px 691px #adb5bd, 1103px 1073px #adb5bd, 710px 735px #adb5bd, 1636px 244px #adb5bd, 1486px 518px #adb5bd, 1230px 656px #adb5bd, 1058px 1198px #adb5bd, 1591px 1456px #adb5bd, 1715px 1274px #adb5bd, 1617px 180px #adb5bd,
      484px 1337px #adb5bd, 816px 880px #adb5bd, 774px 1172px #adb5bd, 809px 1503px #adb5bd, 1478px 1683px #adb5bd, 195px 347px #adb5bd, 1916px 1403px #adb5bd, 400px 1608px #adb5bd, 1632px 40px #adb5bd, 1045px 444px #adb5bd, 478px 499px #adb5bd, 444px 1000px #adb5bd, 864px 1496px #adb5bd,
      1321px 1979px #adb5bd, 494px 655px #adb5bd, 1509px 1711px #adb5bd, 1366px 1243px #adb5bd, 649px 710px #adb5bd, 1079px 1965px #adb5bd, 839px 1132px #adb5bd, 1899px 1397px #adb5bd, 950px 1408px #adb5bd, 1980px 480px #adb5bd, 1809px 1138px #adb5bd, 1214px 1748px #adb5bd, 1264px 734px #adb5bd,
      850px 1504px #adb5bd, 483px 222px #adb5bd, 826px 1614px #adb5bd, 352px 724px #adb5bd, 1735px 668px #adb5bd, 1235px 283px #adb5bd, 1932px 622px #adb5bd, 483px 326px #adb5bd, 776px 694px #adb5bd, 994px 1334px #adb5bd, 274px 576px #adb5bd, 249px 812px #adb5bd, 1677px 1979px #adb5bd,
      395px 38px #adb5bd, 237px 78px #adb5bd, 1623px 1121px #adb5bd, 1992px 585px #adb5bd, 367px 1678px #adb5bd, 1247px 1882px #adb5bd, 1734px 289px #adb5bd, 1058px 863px #adb5bd, 82px 118px #adb5bd, 1687px 1857px #adb5bd, 513px 407px #adb5bd, 76px 1460px #adb5bd, 119px 7px #adb5bd,
      1836px 1969px #adb5bd, 1809px 1527px #adb5bd, 1792px 1763px #adb5bd, 1858px 1491px #adb5bd, 236px 1284px #adb5bd, 1222px 270px #adb5bd, 1396px 1921px #adb5bd, 1839px 59px #adb5bd, 1283px 277px #adb5bd, 1219px 1705px #adb5bd, 583px 504px #adb5bd, 62px 1456px #adb5bd, 1530px 1801px #adb5bd,
      1318px 1463px #adb5bd, 268px 1636px #adb5bd, 178px 1259px #adb5bd, 1110px 245px #adb5bd, 113px 103px #adb5bd, 1961px 691px #adb5bd, 123px 565px #adb5bd, 474px 112px #adb5bd, 1315px 195px #adb5bd, 682px 1991px #adb5bd, 1925px 574px #adb5bd, 1549px 1587px #adb5bd, 401px 1906px #adb5bd,
      1301px 221px #adb5bd, 1224px 482px #adb5bd, 831px 242px #adb5bd, 1726px 753px #adb5bd, 1088px 1842px #adb5bd, 165px 1950px #adb5bd, 1176px 312px #adb5bd, 1456px 921px #adb5bd, 1519px 1173px #adb5bd, 1337px 293px #adb5bd, 1541px 1473px #adb5bd, 1924px 166px #adb5bd, 298px 779px #adb5bd,
      1896px 1913px #adb5bd, 797px 334px #adb5bd, 182px 282px #adb5bd, 1635px 1459px #adb5bd, 765px 732px #adb5bd, 72px 777px #adb5bd, 1676px 1831px #adb5bd, 541px 1442px #adb5bd, 602px 1773px #adb5bd, 953px 1013px #adb5bd, 1520px 829px #adb5bd, 70px 95px #adb5bd, 840px 888px #adb5bd,
      625px 1675px #adb5bd, 1772px 65px #adb5bd, 1739px 1160px #adb5bd, 422px 937px #adb5bd, 1790px 1982px #adb5bd, 271px 1695px #adb5bd, 1018px 793px #adb5bd, 1088px 1124px #adb5bd, 1811px 865px #adb5bd, 1556px 76px #adb5bd, 390px 634px #adb5bd, 1163px 230px #adb5bd, 665px 1023px #adb5bd,
      1820px 787px #adb5bd, 262px 17px #adb5bd, 1602px 346px #adb5bd, 320px 555px #adb5bd, 266px 1485px #adb5bd, 182px 1555px #adb5bd, 1865px 1881px #adb5bd, 348px 317px #adb5bd, 370px 1747px #adb5bd, 1081px 661px #adb5bd, 1315px 243px #adb5bd, 1536px 1400px #adb5bd, 199px 1174px #adb5bd,
      1421px 1795px #adb5bd, 247px 840px #adb5bd, 356px 882px #adb5bd, 1296px 650px #adb5bd, 513px 1597px #adb5bd, 1094px 1784px #adb5bd, 358px 550px #adb5bd, 1238px 101px #adb5bd, 1985px 1469px #adb5bd, 1444px 1295px #adb5bd, 509px 131px #adb5bd, 119px 1297px #adb5bd, 848px 588px #adb5bd,
      1962px 1464px #adb5bd, 1561px 737px #adb5bd, 1061px 357px #adb5bd, 105px 617px #adb5bd, 1687px 755px #adb5bd, 822px 787px #adb5bd, 1318px 478px #adb5bd, 1678px 1068px #adb5bd, 1871px 816px #adb5bd, 1202px 939px #adb5bd, 187px 1264px #adb5bd, 1909px 861px #adb5bd, 109px 1019px #adb5bd,
      1165px 235px #adb5bd, 923px 1404px #adb5bd, 153px 450px #adb5bd, 1707px 1187px #adb5bd, 1246px 479px #adb5bd, 77px 1232px #adb5bd, 1236px 1125px #adb5bd, 1277px 1873px #adb5bd, 1418px 148px #adb5bd, 284px 930px #adb5bd, 942px 1585px #adb5bd, 1813px 854px #adb5bd, 1822px 875px #adb5bd,
      1190px 1091px #adb5bd, 1909px 1713px #adb5bd, 1914px 1475px #adb5bd, 746px 521px #adb5bd, 936px 1797px #adb5bd, 1423px 50px #adb5bd, 309px 558px #adb5bd, 659px 401px #adb5bd, 446px 1485px #adb5bd, 780px 1276px #adb5bd, 1333px 1437px #adb5bd, 1160px 760px #adb5bd, 1563px 1908px #adb5bd,
      1903px 1902px #adb5bd, 895px 1829px #adb5bd, 1955px 1947px #adb5bd, 1919px 1136px #adb5bd, 1121px 1938px #adb5bd, 597px 1152px #adb5bd, 989px 1996px #adb5bd, 1545px 1223px #adb5bd, 855px 64px #adb5bd, 1515px 143px #adb5bd, 718px 765px #adb5bd, 1607px 1081px #adb5bd, 1768px 1557px #adb5bd,
      1398px 470px #adb5bd, 1149px 1653px #adb5bd, 98px 1296px #adb5bd, 1074px 1390px #adb5bd, 1572px 623px #adb5bd, 1913px 1621px #adb5bd, 1812px 1585px #adb5bd, 1052px 1806px #adb5bd, 1890px 765px #adb5bd, 753px 1959px #adb5bd;
   animation: animStar 80s linear infinite;
}
#stars:after {
   content: " ";
   position: absolute;
   top: 2000px;
   width: 1px;
   height: 1px;
   background: transparent;
   box-shadow: 331px 1458px #adb5bd, 678px 31px #adb5bd, 1726px 1570px #adb5bd, 351px 38px #adb5bd, 219px 1167px #adb5bd, 1488px 217px #adb5bd, 1725px 96px #adb5bd, 1014px 1763px #adb5bd, 300px 1023px #adb5bd, 1497px 1659px #adb5bd, 1528px 1786px #adb5bd, 353px 518px #adb5bd, 1022px 297px #adb5bd,
      1579px 1746px #adb5bd, 28px 1989px #adb5bd, 1469px 1563px #adb5bd, 1703px 720px #adb5bd, 338px 1361px #adb5bd, 1543px 1997px #adb5bd, 785px 810px #adb5bd, 584px 87px #adb5bd, 1599px 1719px #adb5bd, 605px 1383px #adb5bd, 182px 1195px #adb5bd, 1688px 103px #adb5bd, 316px 1657px #adb5bd,
      1696px 383px #adb5bd, 1919px 1376px #adb5bd, 1817px 176px #adb5bd, 1089px 812px #adb5bd, 620px 276px #adb5bd, 744px 1999px #adb5bd, 1568px 768px #adb5bd, 1961px 1672px #adb5bd, 785px 633px #adb5bd, 1546px 999px #adb5bd, 1145px 29px #adb5bd, 469px 1208px #adb5bd, 691px 1737px #adb5bd,
      695px 1244px #adb5bd, 1920px 923px #adb5bd, 487px 118px #adb5bd, 738px 743px #adb5bd, 508px 1742px #adb5bd, 499px 1333px #adb5bd, 663px 1328px #adb5bd, 1058px 1030px #adb5bd, 314px 912px #adb5bd, 555px 1174px #adb5bd, 709px 1190px #adb5bd, 1216px 375px #adb5bd, 1107px 400px #adb5bd,
      956px 569px #adb5bd, 862px 187px #adb5bd, 761px 119px #adb5bd, 601px 986px #adb5bd, 1288px 1367px #adb5bd, 597px 1146px #adb5bd, 564px 592px #adb5bd, 1372px 1052px #adb5bd, 952px 363px #adb5bd, 983px 822px #adb5bd, 1731px 517px #adb5bd, 482px 105px #adb5bd, 1309px 1639px #adb5bd,
      1259px 1358px #adb5bd, 337px 731px #adb5bd, 395px 820px #adb5bd, 225px 647px #adb5bd, 1141px 565px #adb5bd, 1224px 533px #adb5bd, 1526px 90px #adb5bd, 1154px 106px #adb5bd, 970px 729px #adb5bd, 293px 1660px #adb5bd, 1195px 734px #adb5bd, 569px 24px #adb5bd, 779px 576px #adb5bd,
      480px 110px #adb5bd, 895px 1787px #adb5bd, 139px 1702px #adb5bd, 1139px 1618px #adb5bd, 1218px 630px #adb5bd, 811px 1229px #adb5bd, 581px 1628px #adb5bd, 868px 683px #adb5bd, 1012px 1358px #adb5bd, 1408px 1326px #adb5bd, 1928px 908px #adb5bd, 221px 221px #adb5bd, 351px 1725px #adb5bd,
      514px 876px #adb5bd, 736px 1083px #adb5bd, 918px 236px #adb5bd, 1062px 1313px #adb5bd, 177px 1289px #adb5bd, 329px 1326px #adb5bd, 1143px 1354px #adb5bd, 1872px 1236px #adb5bd, 1000px 389px #adb5bd, 1685px 568px #adb5bd, 49px 1996px #adb5bd, 1552px 1773px #adb5bd, 1873px 971px #adb5bd,
      1643px 1222px #adb5bd, 1455px 1620px #adb5bd, 1246px 1160px #adb5bd, 743px 1981px #adb5bd, 1340px 1747px #adb5bd, 274px 273px #adb5bd, 528px 68px #adb5bd, 461px 1585px #adb5bd, 728px 1323px #adb5bd, 1035px 1036px #adb5bd, 1536px 978px #adb5bd, 424px 34px #adb5bd, 1466px 958px #adb5bd,
      930px 257px #adb5bd, 1803px 948px #adb5bd, 1750px 1480px #adb5bd, 1008px 488px #adb5bd, 1334px 114px #adb5bd, 1344px 643px #adb5bd, 945px 618px #adb5bd, 1698px 1569px #adb5bd, 399px 1215px #adb5bd, 492px 537px #adb5bd, 806px 875px #adb5bd, 1776px 695px #adb5bd, 728px 1232px #adb5bd,
      714px 118px #adb5bd, 461px 1765px #adb5bd, 1868px 1460px #adb5bd, 1307px 17px #adb5bd, 395px 1759px #adb5bd, 1620px 1818px #adb5bd, 906px 218px #adb5bd, 1398px 181px #adb5bd, 826px 1502px #adb5bd, 1932px 1963px #adb5bd, 388px 292px #adb5bd, 1580px 415px #adb5bd, 436px 1084px #adb5bd,
      305px 1115px #adb5bd, 973px 1252px #adb5bd, 626px 1135px #adb5bd, 974px 1121px #adb5bd, 1632px 215px #adb5bd, 1670px 627px #adb5bd, 551px 448px #adb5bd, 1758px 1674px #adb5bd, 1825px 1939px #adb5bd, 993px 1176px #adb5bd, 1329px 404px #adb5bd, 1818px 1833px #adb5bd, 329px 257px #adb5bd,
      781px 1142px #adb5bd, 25px 1164px #adb5bd, 644px 863px #adb5bd, 1638px 508px #adb5bd, 107px 1949px #adb5bd, 329px 1192px #adb5bd, 481px 70px #adb5bd, 860px 1123px #adb5bd, 714px 1509px #adb5bd, 798px 29px #adb5bd, 1329px 1528px #adb5bd, 533px 81px #adb5bd, 791px 1345px #adb5bd,
      930px 269px #adb5bd, 54px 967px #adb5bd, 309px 460px #adb5bd, 1405px 1979px #adb5bd, 1548px 1344px #adb5bd, 1249px 945px #adb5bd, 222px 1121px #adb5bd, 726px 263px #adb5bd, 1830px 433px #adb5bd, 1772px 1049px #adb5bd, 1883px 868px #adb5bd, 1173px 844px #adb5bd, 1587px 1879px #adb5bd,
      564px 692px #adb5bd, 1635px 1495px #adb5bd, 811px 424px #adb5bd, 1902px 1279px #adb5bd, 202px 1429px #adb5bd, 154px 19px #adb5bd, 1629px 982px #adb5bd, 28px 1087px #adb5bd, 68px 1940px #adb5bd, 363px 129px #adb5bd, 95px 329px #adb5bd, 1758px 95px #adb5bd, 726px 1546px #adb5bd,
      1812px 1643px #adb5bd, 1880px 193px #adb5bd, 1145px 251px #adb5bd, 427px 597px #adb5bd, 1632px 810px #adb5bd, 1206px 15px #adb5bd, 1986px 1382px #adb5bd, 1233px 591px #adb5bd, 549px 1280px #adb5bd, 1382px 1638px #adb5bd, 1447px 500px #adb5bd, 642px 359px #adb5bd, 1933px 809px #adb5bd,
      978px 41px #adb5bd, 515px 1024px #adb5bd, 927px 1349px #adb5bd, 1121px 137px #adb5bd, 1406px 1247px #adb5bd, 217px 1662px #adb5bd, 1947px 85px #adb5bd, 1939px 1548px #adb5bd, 560px 931px #adb5bd, 1139px 343px #adb5bd, 1326px 63px #adb5bd, 1689px 1349px #adb5bd, 1223px 1202px #adb5bd,
      796px 762px #adb5bd, 802px 488px #adb5bd, 832px 519px #adb5bd, 1808px 361px #adb5bd, 515px 1991px #adb5bd, 587px 117px #adb5bd, 1040px 1231px #adb5bd, 1944px 15px #adb5bd, 235px 1826px #adb5bd, 1474px 1172px #adb5bd, 952px 1131px #adb5bd, 1122px 43px #adb5bd, 1305px 246px #adb5bd,
      389px 1811px #adb5bd, 1783px 516px #adb5bd, 1901px 534px #adb5bd, 926px 57px #adb5bd, 1029px 1924px #adb5bd, 1835px 1616px #adb5bd, 1234px 356px #adb5bd, 140px 1176px #adb5bd, 1518px 1312px #adb5bd, 1298px 556px #adb5bd, 916px 1106px #adb5bd, 1397px 1374px #adb5bd, 350px 1444px #adb5bd,
      737px 88px #adb5bd, 1695px 1671px #adb5bd, 432px 1784px #adb5bd, 25px 1911px #adb5bd, 1156px 8px #adb5bd, 401px 620px #adb5bd, 301px 1816px #adb5bd, 526px 1536px #adb5bd, 8px 1706px #adb5bd, 416px 1472px #adb5bd, 1196px 888px #adb5bd, 1110px 877px #adb5bd, 896px 210px #adb5bd,
      840px 595px #adb5bd, 1889px 1096px #adb5bd, 1486px 1568px #adb5bd, 148px 1700px #adb5bd, 1839px 911px #adb5bd, 377px 563px #adb5bd, 890px 1103px #adb5bd, 126px 645px #adb5bd, 1506px 1748px #adb5bd, 1732px 1231px #adb5bd, 1618px 98px #adb5bd, 981px 453px #adb5bd, 1113px 1475px #adb5bd,
      477px 1518px #adb5bd, 1113px 1771px #adb5bd, 73px 334px #adb5bd, 1464px 1368px #adb5bd, 11px 666px #adb5bd, 351px 1328px #adb5bd, 1875px 96px #adb5bd, 1867px 1449px #adb5bd, 272px 171px #adb5bd, 318px 1465px #adb5bd, 1109px 1161px #adb5bd, 273px 1098px #adb5bd, 1968px 848px #adb5bd,
      955px 474px #adb5bd, 1892px 1501px #adb5bd, 432px 107px #adb5bd, 1246px 221px #adb5bd, 945px 1656px #adb5bd, 1450px 819px #adb5bd, 594px 452px #adb5bd, 441px 621px #adb5bd, 594px 1399px #adb5bd, 843px 997px #adb5bd, 823px 885px #adb5bd, 30px 4px #adb5bd, 832px 468px #adb5bd,
      1681px 986px #adb5bd, 1196px 274px #adb5bd, 1593px 328px #adb5bd, 775px 28px #adb5bd, 71px 1706px #adb5bd, 971px 691px #adb5bd, 175px 695px #adb5bd, 145px 321px #adb5bd, 1055px 1059px #adb5bd, 403px 233px #adb5bd, 865px 456px #adb5bd, 950px 1826px #adb5bd, 59px 84px #adb5bd, 740px 68px #adb5bd,
      1744px 1949px #adb5bd, 373px 1286px #adb5bd, 1955px 1607px #adb5bd, 1946px 1606px #adb5bd, 804px 471px #adb5bd, 960px 949px #adb5bd, 1675px 1027px #adb5bd, 97px 1019px #adb5bd, 1594px 894px #adb5bd, 1267px 1736px #adb5bd, 1768px 1974px #adb5bd, 1710px 531px #adb5bd, 1961px 1946px #adb5bd,
      464px 144px #adb5bd, 1354px 1021px #adb5bd, 1485px 681px #adb5bd, 612px 1973px #adb5bd, 305px 1409px #adb5bd, 513px 739px #adb5bd, 234px 813px #adb5bd, 538px 1610px #adb5bd, 404px 508px #adb5bd, 1516px 745px #adb5bd, 436px 1408px #adb5bd, 1917px 1434px #adb5bd, 1488px 1989px #adb5bd,
      1118px 610px #adb5bd, 126px 512px #adb5bd, 1988px 1102px #adb5bd, 1785px 1538px #adb5bd, 1618px 348px #adb5bd, 542px 595px #adb5bd, 48px 1691px #adb5bd, 155px 70px #adb5bd, 358px 1460px #adb5bd, 1796px 332px #adb5bd, 700px 431px #adb5bd, 914px 856px #adb5bd, 500px 1294px #adb5bd,
      1983px 38px #adb5bd, 1655px 1034px #adb5bd, 998px 277px #adb5bd, 742px 1946px #adb5bd, 1022px 862px #adb5bd, 853px 782px #adb5bd, 657px 113px #adb5bd, 170px 1576px #adb5bd, 1163px 1794px #adb5bd, 169px 1837px #adb5bd, 888px 633px #adb5bd, 1717px 647px #adb5bd, 1345px 432px #adb5bd,
      12px 620px #adb5bd, 1599px 891px #adb5bd, 875px 589px #adb5bd, 771px 1596px #adb5bd, 1984px 1445px #adb5bd, 1810px 576px #adb5bd, 630px 396px #adb5bd, 765px 1591px #adb5bd, 364px 1773px #adb5bd, 520px 1984px #adb5bd, 298px 218px #adb5bd, 790px 1846px #adb5bd, 58px 1525px #adb5bd,
      44px 382px #adb5bd, 831px 655px #adb5bd, 376px 1919px #adb5bd, 691px 765px #adb5bd, 1416px 1121px #adb5bd, 284px 1184px #adb5bd, 1123px 765px #adb5bd, 1835px 238px #adb5bd, 1889px 1262px #adb5bd, 1516px 546px #adb5bd, 1989px 1190px #adb5bd, 663px 1291px #adb5bd, 1338px 1759px #adb5bd,
      428px 1715px #adb5bd, 1296px 874px #adb5bd, 984px 929px #adb5bd, 154px 1062px #adb5bd, 1891px 1307px #adb5bd, 93px 378px #adb5bd, 292px 1013px #adb5bd, 808px 238px #adb5bd, 1888px 833px #adb5bd, 634px 629px #adb5bd, 1862px 598px #adb5bd, 239px 85px #adb5bd, 1449px 473px #adb5bd,
      1633px 1867px #adb5bd, 13px 356px #adb5bd, 1569px 961px #adb5bd, 1906px 249px #adb5bd, 973px 866px #adb5bd, 70px 801px #adb5bd, 1202px 1392px #adb5bd, 1446px 1141px #adb5bd, 1938px 663px #adb5bd, 1179px 138px #adb5bd, 573px 1507px #adb5bd, 1693px 595px #adb5bd, 661px 930px #adb5bd,
      74px 575px #adb5bd, 369px 1966px #adb5bd, 1591px 457px #adb5bd;
}

#stars3 {
   width: 3px;
   height: 3px;
   background: transparent;
   box-shadow: 1559px 582px #845ef7, 277px 632px #845ef7, 767px 989px #845ef7, 1760px 1898px #845ef7, 1523px 1926px #845ef7, 149px 278px #845ef7, 593px 324px #845ef7, 691px 230px #845ef7, 277px 1212px #845ef7, 659px 444px #845ef7, 1672px 1574px #845ef7, 1247px 951px #845ef7, 1800px 235px #845ef7,
      1835px 824px #845ef7, 1325px 37px #845ef7, 123px 1512px #845ef7, 146px 1377px #845ef7, 1642px 1609px #845ef7, 1472px 581px #845ef7, 594px 116px #845ef7, 1609px 1023px #845ef7, 1554px 1174px #845ef7, 1883px 491px #845ef7, 870px 1385px #845ef7, 1546px 1622px #845ef7, 442px 1867px #845ef7,
      1102px 1419px #845ef7, 812px 1210px #845ef7, 69px 594px #845ef7, 1638px 755px #845ef7, 491px 1262px #845ef7, 372px 1222px #845ef7, 700px 858px #845ef7, 451px 535px #845ef7, 240px 1312px #845ef7, 878px 1322px #845ef7, 1510px 969px #845ef7, 151px 309px #845ef7, 254px 1646px #845ef7,
      547px 1450px #845ef7, 1039px 1164px #845ef7, 561px 118px #845ef7, 1190px 1352px #845ef7, 1238px 1624px #845ef7, 323px 1704px #845ef7, 1697px 127px #845ef7, 1497px 551px #845ef7, 624px 442px #845ef7, 1912px 300px #845ef7, 1153px 1137px #845ef7, 860px 797px #845ef7, 1065px 703px #845ef7,
      891px 3px #845ef7, 1186px 885px #845ef7, 82px 389px #845ef7, 1515px 683px #845ef7, 259px 565px #845ef7, 70px 1492px #845ef7, 657px 191px #845ef7, 1224px 1809px #845ef7, 900px 1666px #845ef7, 504px 673px #845ef7, 1001px 370px #845ef7, 51px 641px #845ef7, 1634px 1834px #845ef7,
      771px 1852px #845ef7, 781px 394px #845ef7, 557px 1195px #845ef7, 1846px 1373px #845ef7, 452px 1745px #845ef7, 281px 732px #845ef7, 766px 1505px #845ef7, 1357px 1309px #845ef7, 868px 1797px #845ef7, 670px 190px #845ef7, 1881px 480px #845ef7, 1793px 539px #845ef7, 488px 422px #845ef7,
      529px 599px #845ef7, 230px 874px #845ef7, 88px 1058px #845ef7, 1566px 453px #845ef7, 1596px 1703px #845ef7, 782px 755px #845ef7, 851px 1163px #845ef7, 733px 341px #845ef7, 109px 1047px #845ef7, 758px 526px #845ef7, 1365px 1949px #845ef7, 1239px 52px #845ef7, 334px 189px #845ef7,
      1810px 415px #845ef7, 1387px 1350px #845ef7, 1198px 1789px #845ef7, 131px 1478px #845ef7, 102px 1206px #845ef7, 1687px 1428px #845ef7, 1545px 720px #845ef7, 1547px 783px #845ef7, 1521px 1139px #845ef7;
   animation: animStar 180s linear infinite;
}
#stars3:after {
   content: " ";
   position: absolute;
   top: 2000px;
   width: 3px;
   height: 3px;
   background: transparent;
   box-shadow: 1559px 582px #845ef7, 277px 632px #845ef7, 767px 989px #845ef7, 1760px 1898px #845ef7, 1523px 1926px #845ef7, 149px 278px #845ef7, 593px 324px #845ef7, 691px 230px #845ef7, 277px 1212px #845ef7, 659px 444px #845ef7, 1672px 1574px #845ef7, 1247px 951px #845ef7, 1800px 235px #845ef7,
      1835px 824px #845ef7, 1325px 37px #845ef7, 123px 1512px #845ef7, 146px 1377px #845ef7, 1642px 1609px #845ef7, 1472px 581px #845ef7, 594px 116px #845ef7, 1609px 1023px #845ef7, 1554px 1174px #845ef7, 1883px 491px #845ef7, 870px 1385px #845ef7, 1546px 1622px #845ef7, 442px 1867px #845ef7,
      1102px 1419px #845ef7, 812px 1210px #845ef7, 69px 594px #845ef7, 1638px 755px #845ef7, 491px 1262px #845ef7, 372px 1222px #845ef7, 700px 858px #845ef7, 451px 535px #845ef7, 240px 1312px #845ef7, 878px 1322px #845ef7, 1510px 969px #845ef7, 151px 309px #845ef7, 254px 1646px #845ef7,
      547px 1450px #845ef7, 1039px 1164px #845ef7, 561px 118px #845ef7, 1190px 1352px #845ef7, 1238px 1624px #845ef7, 323px 1704px #845ef7, 1697px 127px #845ef7, 1497px 551px #845ef7, 624px 442px #845ef7, 1912px 300px #845ef7, 1153px 1137px #845ef7, 860px 797px #845ef7, 1065px 703px #845ef7,
      891px 3px #845ef7, 1186px 885px #845ef7, 82px 389px #845ef7, 1515px 683px #845ef7, 259px 565px #845ef7, 70px 1492px #845ef7, 657px 191px #845ef7, 1224px 1809px #845ef7, 900px 1666px #845ef7, 504px 673px #845ef7, 1001px 370px #845ef7, 51px 641px #845ef7, 1634px 1834px #845ef7,
      771px 1852px #845ef7, 781px 394px #845ef7, 557px 1195px #845ef7, 1846px 1373px #845ef7, 452px 1745px #845ef7, 281px 732px #845ef7, 766px 1505px #845ef7, 1357px 1309px #845ef7, 868px 1797px #845ef7, 670px 190px #845ef7, 1881px 480px #845ef7, 1793px 539px #845ef7, 488px 422px #845ef7,
      529px 599px #845ef7, 230px 874px #845ef7, 88px 1058px #845ef7, 1566px 453px #845ef7, 1596px 1703px #845ef7, 782px 755px #845ef7, 851px 1163px #845ef7, 733px 341px #845ef7, 109px 1047px #845ef7, 758px 526px #845ef7, 1365px 1949px #845ef7, 1239px 52px #845ef7, 334px 189px #845ef7,
      1810px 415px #845ef7, 1387px 1350px #845ef7, 1198px 1789px #845ef7, 131px 1478px #845ef7, 102px 1206px #845ef7, 1687px 1428px #845ef7, 1545px 720px #845ef7, 1547px 783px #845ef7, 1521px 1139px #845ef7;
}

@keyframes animStar {
   from {
      transform: translateY(0px);
   }
   to {
      transform: translateY(-2000px);
   }
}
