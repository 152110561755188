.bottombarContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin: 0 15px 10px 15px;
   border-radius: 100px;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 102;
   background: rgba(87, 42, 87, 0.438) !important;
   box-shadow: 0 8px 32px 0 #100f1680;
   border-radius: 20px;
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(15px);
}

/* prettier-ignore */
.activeContainer {
 display: flex;
 align-items: center;
 justify-content: center;
 background: #f1f1f1 !important;
 margin-top: -38px;
 height: 55px;
 width: 55px;
 box-shadow: 7px 5px 7px rgba(0, 0, 0, 0.4);
border-radius:100%;

}

.bottomBarLink {
   padding: 15px 20px;
}

@media screen and (max-width: 334px) {
   .activeContainer {
      height: 50px;
      width: 50px;
   }
}
