.excel2021_logo {
   width: 10.5%;
   height: 10.5%;
   margin-left: 8%;
}
.footerLogo:hover {
   cursor: pointer;
}
.mobileViewMecLogo {
   display: none;
}
.mec_logo {
   width: 14%;
   height: 14%;
}

.icons_footer {
   align-items: center;
   margin-left: 28%;
}
.descrip {
   color: white;
   text-align: center;
   margin-top: 0.5%;
}

.footer_div {
   text-align: center;
   align-items: center;
   display: block !important;
   margin-left: 1%;
   margin-bottom: 1%;
}
.social_logo {
   height: 37px;
   margin-left: 9%;
}
@media screen and (max-width: 1000px) {
   .footer_div {
      margin-bottom: 10%;
      /* padding-bottom: 8%; */
   }
   .mobileViewMecLogo {
      display: unset !important;
   }
   .desktopViewMecLogo {
      display: none;
   }
}
@media screen and (max-width: 800px) {
   .social_logo {
      height: 25px;
      margin-left: 3%;
   }
   .mobileViewMecLogoexcel2021_logo {
      margin-top: 5%;
      width: 30%;
      height: 30%;
   }
}
