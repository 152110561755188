.schedulePopupContainer {
  margin: 0 auto;
  background: #191833fb;
  box-shadow: 0px 5px 30px 0px #0000004d;
  border-radius: 12px;
  padding: 64px 72px;
  font-family: "Open Sans", sans-serif;
  margin-left: -6rem;
  margin-top: -8rem;
  width: 55rem;
  position: relative;
}

.schedulePopupContainer::before {
  position: absolute;
  content: "";
  top: -160px;
  left: -400px;
  width: 150vw;
  height: 150vh;
  background-image: url("../../assets/jpg/popupbk.jpg");
  filter: blur(13px) grayscale(20%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: -1;
}

@media screen and (max-width: 600px) {
  .schedulePopupContainer::before {
    left: -200px;
  }
  .schedulePopupContainer {
    margin: 0 auto;
    /* margin-left: 50%; */
  }
}

@media screen and (max-width: 400px) {
  .schedulePopupContainer::before {
    left: -100px;
  }
  .schedulePopupContainer {
    align-items: center;
    margin-left: 20%;
  }
}
.schedulePopupTitle {
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 4px;
}
.schedulePopupOptionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: light;
}

.filter-popup {
  /* display: flex; */
  padding-left: 10%;
  padding-right: 10%;
  width: 60rem;
  /* align-items: center; */
}
.schedulePopupButton {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 32px auto 0 auto;
  /* margin-top: 2%; */
  background: transparent;
  color: white;
  text-align: center !important;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px 32px;
  border: 0.0625rem solid var(--teritary);
  border-radius: 4px;
}
.schedulePopupButton:hover {
  background: var(--teritary);
  border-color: var(--teritary);
  transition: 0.3s;
}
.schedulePopupButton:focus {
  outline: none;
}
.close-container-mobile {
  display: flex;
  flex-direction: row-reverse;
}
.close-container-mobile .close-button {
  fill: white;
  position: fixed;
  width: 2rem;
  height: auto;
  cursor: pointer;

  transition: 100ms ease-in-out;
}
.close-container-mobile .close-button:hover {
  transform: scale(1.1);
  fill: var(--teritary);
}

@media screen and (max-width: 850px) {
  .schedulePopupContainer {
    width: 30rem;
    margin-left: -9rem;
    align-items: center;
  }

  .schedulePopupOptionsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* @media screen and (max-width:600px){
   
.filter-popup {
   display: flex;
   padding-left: 10%;
   padding-right: 10%;
   width: 60rem;
   
}
} */
@media screen and (max-width: 700px) {
  .schedulePopupContainer {
    margin: 0 auto;
  }
}
@media screen and (max-width: 540px) {
  .schedulePopupContainer {
    max-height: 80vh;
    margin-top: -60px;
    padding: 0px;
    padding-bottom: 20px;
    padding-right: 5px;
    width: 20rem;
    /* margin-left: -1.5rem; */
  }

  .schedule-popup-content {
    width: 260px;
    max-height: 60vh;
    overflow: scroll;
    padding: 30px;
    overflow-x: hidden;
    /* text-align: center; */
  }

  .schedulePopupTitle {
    margin-top: 10px;
    max-height: 80vh;
  }
  .schedulePopupButton {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px 15px;
  }

  .schedulePopupOptionsContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .close-container-mobile {
    padding-top: -20px;
    padding-right: 15px;
  }

  .close-container-mobile .close-button {
    width: 2rem;
  }
}
