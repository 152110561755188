.container1 {
   width: 80px;
   height: 100px;
   margin: 100px auto;
}
.loader-top {
   margin-top: calc(100vh / 7 - 50px);
}
.loader-center {
   margin-top: calc(100vh / 2 - 50px);
}
.block {
   position: relative;
   box-sizing: border-box;
   float: left;
   margin: 0 10px 10px 0;
   width: 12px;
   height: 12px;
   border-radius: 3px;
   background: #9d45f5;
}

.block:nth-child(4n + 1) {
   animation: wave 2s ease 0s infinite;
}
.block:nth-child(4n + 2) {
   animation: wave 2s ease 0.2s infinite;
}
.block:nth-child(4n + 3) {
   animation: wave 2s ease 0.4s infinite;
}
.block:nth-child(4n + 4) {
   animation: wave 2s ease 0.6s infinite;
   margin-right: 0;
}

@keyframes wave {
   0% {
      top: 0;
      opacity: 1;
   }
   50% {
      top: 30px;
      opacity: 0.2;
   }
   100% {
      top: 0;
      opacity: 1;
   }
}
