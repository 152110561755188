@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap);
.smallSidebarContainer {
   position: fixed !important;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 70px;
   left: 20px;
   border-radius: 20px;
   top: 50%;
   -webkit-transform: translateY(-50%);
           transform: translateY(-50%);
   z-index: 102;
   padding-bottom: 20px;
}

.smallSidebarInnerContainer {
   color: rgb(98, 95, 95);
   display: flex;
   flex-direction: column;
   background-color: #100f16;
   justify-content: space-between;
   width: 100%;
   margin-top: 5%;
}

.navColors {
   display: flex;
   align-items: center;

   height: 45px;
   padding-left: 10px;
}

.navColors div {
   margin: 0 3px;
   width: 8px;
   height: 8px;
   border-radius: 100px;
}

.navbarProfile {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   padding: 15px 0;
   padding-bottom: 30px;
}

.navbarProfileIcon {
   max-width: 40px;
   border-radius: 20px;
   cursor: pointer;
   transition: 0.25s ease;
}
.navbarProfileIcon:hover:not(.active) {
   box-shadow: 0 0 0 4px #100f16, 0 0 0 4px #ffffff;
}

.smallSidebarLink {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   padding: 25px 0;
   position: relative;
   transition: 0.25s ease;
}
.smallSidebarLink:hover {
   -webkit-transform: translateX(10%);
           transform: translateX(10%);
}
.smallSidebarIcon {
   height: 30px;
   width: 30px;
   cursor: pointer;
   -webkit-filter: saturate(1);
           filter: saturate(1);
   /* padding-bottom: 13px; */
}
.smallSidebarActive {
   height: 100%;
   width: 100%;
   border: 1px solid #000;
   border-radius: 13px;
   border: double 1px transparent;
   background-image: linear-gradient(rgb(15 15 16), rgb(15 15 16)), linear-gradient(90deg, #7819bb, #4b44ad);
   background-origin: border-box;
   background-clip: content-box, border-box;
   border-image-slice: 20;
   -webkit-filter: saturate(4);
   filter: saturate(4);
}

.smallSidebarLink .toolTip {
   opacity: 0;
   visibility: hidden;
   width: 9rem;
   height: 80%;
   color: #fff;
   margin-left: 4.5vw;
   border-radius: 0 5px 5px 0;

   /* Position the tooltip */
   position: absolute;
   z-index: 1;
   top: 50%;
   -webkit-transform: translate(-45%, -50%);
           transform: translate(-45%, -50%);
   left: 100%;
   transition: all 0.2s ease;
   z-index: 100;
   /* box-shadow: 4px 8px 32px rgba(31, 38, 135, 0.37); */
}
.toolTipText {
   margin-top: 15%;
   font-size: 14px;
   font-weight: normal;
}
.smallSidebarLink:hover .toolTip {
   opacity: 1;
   visibility: visible;
}
.smallSidebarLink:hover .toolTip::before {
   opacity: 1;
}
.toolTip::before {
   left: 0;
   opacity: 0;
   border-radius: 0 5px 5px 0 !important;
   /* transition: opacity 0.2s ease; */
}
.logout {
   position: absolute;
   bottom: 40px;

   display: flex;
   cursor: pointer;
}

.logoutIcon {
   height: 25px;
   width: 25px;
}
.linkText {
   font-size: 13.5px;
   color: black;
}

@media screen and (max-width: 1700px) {
   .smallSidebarContainer {
      width: 70px;
   }
   .linkText {
      font-size: 12.5px;
   }
}

@media screen and (max-width: 1400px) {
   .smallSidebarContainer {
      width: 70px;
   }
   .linkText {
      font-size: 12px;
   }
}

@media screen and (max-width: 1200px) {
   .smallSidebarContainer {
      width: 70px;
   }
   .linkText {
      font-size: 11.5px;
   }
}

@media screen and (max-width: 1048px) {
   .smallSidebarContainer {
      width: 70px;
   }
   .linkText {
      font-size: 11px;
   }
}

.profilePopupContainer {
   margin: 0 auto;
   background: #191833fb;
   box-shadow: 0px 5px 30px 0px #0000004d;
   border-radius: 12px;
   padding: 64px 72px;
   font-family: "Open Sans", sans-serif;
   margin-left: 90vw;
   margin-top: -70vh;
   width: 55rem;
   height: 30rem;
   position: relative;
}
.profilePopupContainer::before {
   position: absolute;
   content: "";
   top: -160px;
   left: -640px;
   width: 190vw;
   height: 150vh;
   background-image: url(/static/media/popupbk.5aa64556.jpg);
   -webkit-filter: blur(13px) grayscale(20%);
           filter: blur(13px) grayscale(20%);
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   z-index: -1;
}
.profile-row-2 {
   display: flex;
   flex-direction: column;
   grid-gap: 4rem;
   gap: 4rem;
   margin-top: 6%;
}
.row-content {
   width: 35vw;
   margin-top: 3%;
}
@media (min-width: 1500px) and (max-width: 2900px) {
   .profilePopupContainer {
      width: 60rem;
      height: 30rem;
      position: relative;
      margin-top: 20%;
   }
}

@media screen and (max-width: 600px) {
   .profilePopupContainer::before {
      left: -200px;
   }
   .profilePopupContainer {
      /* margin: 0 auto; */
      margin-right: 10%;
   }
   .row-content {
      display: none;
   }
}

@media screen and (max-width: 400px) {
   .profilePopupContainer::before {
      left: -100px;
   }
   .profilePopupContainer {
      align-items: center;
      margin-left: 20%;
   }
   .row-content {
      display: none;
   }
}

.close-container-mobile {
   display: flex;
   flex-direction: row-reverse;
}
.close-container-mobile .close-button {
   fill: white;
   position: fixed;
   width: 2rem;
   height: auto;
   cursor: pointer;
   transition: 100ms ease-in-out;
   z-index: 200 !important;
}
.close-container-mobile .close-button:hover {
   -webkit-transform: scale(1.1);
           transform: scale(1.1);
   fill: var(--teritary);
}

@media screen and (max-width: 850px) {
   .profilePopupContainer {
      width: 30rem;
      margin-left: -9rem;
      align-items: center;
   }
}

@media screen and (max-width: 700px) {
   .profilePopupContainer {
      margin: 0 auto;
   }
}
@media screen and (max-width: 540px) {
   .profilePopupContainer {
      max-height: 70vh;
      margin-top: -60px;
      padding: 0px;
      padding-bottom: 20px;
      padding-right: 5px;
      left: -150px !important;
      width: 18rem;
      margin: 0 auto;
      /* margin-left: -1.5rem; */
   }
   .profilePopupContainer::before {
      position: absolute;
      left: -220px;
   }
   .profile-popup-content {
      width: 260px;
      max-height: 60vh;
      overflow: scroll;
      padding: 30px;
      overflow-x: hidden;
      /* text-align: center; */
   }

   .close-container-mobile {
      padding-top: -20px;
      padding-right: 15px;
   }

   .close-container-mobile .close-button {
      width: 2rem;
   }
}

.popup-arrow {
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
.popup-arrow path {
   fill: rgba(217, 177, 250, 0.233);
}
[role="toolTip1"].popup-content {
   width: 225px;
   height: 145px;
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
   background: rgba(217, 177, 250, 0.233);
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   border-radius: 6px;
}
/* no support backdrop-filter */
@supports not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   [role="toolTip1"].popup-content {
      background: rgba(189, 185, 243, 0.281);
      box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
   }
}
.popup-overlay {
   background: rgba(0, 0, 0, 0.5);
}
[data-popup="toolTip1"].popup-overlay {
   background: transparent;
}
.profile-popup {
   display: flex;
   flex-direction: row;
   border-radius: 6px;
   box-shadow: 4px 0px 12px #000 44;
   color: white;
   position: fixed;
   grid-gap: 1px;
}
.profile-popup .signed-in-text {
   display: flex;
   flex-direction: column;
   padding: 0.8rem 2rem;
   border-bottom: 1px solid #000 25;
   margin-top: 6%;
}
.profile-popup .buttons-box {
   display: flex;
   flex-direction: row;
   grid-gap: 3rem;
   gap: 3rem;
}
.profile-popup button {
   display: flex;
   background-color: transparent;
   border: none;
   cursor: pointer;
   text-align: start;
   font-size: 0.9rem;
   opacity: 0.9;
   padding: 7px 10px;
   color: #fff;
   border: 2px solid #fff;
   border-radius: 5px;
   transition: -webkit-transform 300ms ease-in-out;
   transition: transform 300ms ease-in-out;
   transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
/* .profile-popup button:first-child {
  padding-bottom: 0.5rem;
}
.profile-popup button:last-child {
  padding-top: 0.5rem;
} */
.profile-popup .buttons-box button:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.1px rgb(223, 222, 222) !important;
}
.profile-popup button:focus {
   outline: none;
}
.profile-popup button svg {
   height: 0.9rem;
   width: auto;
   padding-right: 8px;
}
.navbarProfile {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 15px 0;
   padding-bottom: 30px;
}
.navbarProfileIcon {
   max-width: 40px;
   border-radius: 20px;
   cursor: pointer;
}

.login_status .toolTip1 {
   opacity: 0;
   visibility: hidden;
   width: 9rem;
   height: 80%;
   color: #fff;
   margin-left: 4.5vw;
   border-radius: 0 5px 5px 0;

   /* Position the toolTip1 */
   position: absolute;
   z-index: 1;
   top: 50%;
   -webkit-transform: translate(-45%, -50%);
           transform: translate(-45%, -50%);
   left: 100%;
   transition: all 0.2s ease;
   z-index: 100;
   /* box-shadow: 4px 8px 32px rgba(31, 38, 135, 0.37); */
}
.toolTipText1 {
   margin-top: 15%;
   font-size: 14px;
   font-weight: normal;
}
.login_status:hover .toolTip1 {
   opacity: 1;
   visibility: visible;
}
.login_status:hover .toolTip1::before {
   opacity: 1;
}
.toolTip1::before {
   left: 0;
   opacity: 0;
   border-radius: 0 5px 5px 0 !important;

   /* transition: opacity 0.2s ease; */
}

@media screen and (max-width: 700px) {
   .profile-popup {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
   }
}

.bottombarContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin: 0 15px 10px 15px;
   border-radius: 100px;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 102;
   background: rgba(87, 42, 87, 0.438) !important;
   box-shadow: 0 8px 32px 0 #100f1680;
   border-radius: 20px;
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(15px);
}

/* prettier-ignore */
.activeContainer {
 display: flex;
 align-items: center;
 justify-content: center;
 background: #f1f1f1 !important;
 margin-top: -38px;
 height: 55px;
 width: 55px;
 box-shadow: 7px 5px 7px rgba(0, 0, 0, 0.4);
border-radius:100%;

}

.bottomBarLink {
   padding: 15px 20px;
}

@media screen and (max-width: 334px) {
   .activeContainer {
      height: 50px;
      width: 50px;
   }
}

.home-div {
   padding: 3.5%;
}
.home-page-title {
   font-weight: 800;
   text-align: center;
   font-size: 57px;
   background-image: linear-gradient(60deg, #e21143, #3a4eff);
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent;
   font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 768px) {
   .home-page-title {
      font-size: 40px;
   }
   .sponser {
      margin-bottom: 5%;
   }

   .sponsorTitle {
      display: none;
   }
   .sponser-heading {
      margin-bottom: 18px;
   }
   .home-page-description {
      text-align: center;
   }
}

.home-page-description {
   font-size: 18px;
   font-weight: 400 !important;
   color: var(--description-color);
   margin-right: 5%;
   margin-left: 5%;
   margin-top: 5%;
}
/* Landing page */

.outer-div {
   width: 98vw;
   height: 98vh;
   position: relative;
   margin: 0 !important;
}
.logo-reveal-div {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   /* background-color: blue; */
}
.static-mec-div {
   z-index: 9;
   /* background-color: red; */
}
/* About */

.abt-animation {
   padding: 0 auto !important;
   padding-top: 5%;
   margin: 0 auto !important;
}
.prev-logo {
   display: flex;
   justify-content: space-evenly;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.prev_excel {
   height: 45px;
   text-align: center;
}
._2018logo {
   width: 40px;
}

/*-------------------------------- SPONSERS SECTION -------------------------------- */
.sponsors {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   margin-left: 1%;
}
.sponser-logos {
   display: flex;
   justify-content: space-around;
}
.sponser {
   width: -moz-fit-content;
   width: -webkit-fit-content;
   width: fit-content;
}

.sponsorTitle {
   font-size: 1rem;
   text-align: center;
   color: var(--description-color);
}
.sponserImage {
   max-height: 250px;
   margin-right: 10px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
/* @media screen and (max-width: 1000px).prev_excel {
   .mobile-space {margin-bottom:10%;}
   } */
@media screen and (max-width: 1000px) {
   .outer-div {
      width: 100vw;
      height: 65vh;

      position: relative;
   }
   .static-mec-div {
      height: 70vh !important;
      width: 100vw;
   }
   .prev_excel {
      padding-bottom: 24%;
      height: 55px;
      display: flex;
      margin-left: 5%;
      justify-content: space-between !important;
   }
   .about-section {
      margin-top: -5%;
      margin-bottom: 10%;
   }
   .descrip {
      margin-bottom: 20%;
   }
}

@media (min-width: 1800px) and (max-width: 2070px) {
   .home-page-description {
      font-size: 21px;
      font-weight: 400 !important;
      color: var(--description-color);
      padding: 1%;
   }
   .prev_excel {
      height: 100px !important;
      display: flex;
      margin-left: 5%;
      justify-content: space-between !important;
   }
}

.excel2021_logo {
   width: 10.5%;
   height: 10.5%;
   margin-left: 8%;
}
.footerLogo:hover {
   cursor: pointer;
}
.mobileViewMecLogo {
   display: none;
}
.mec_logo {
   width: 14%;
   height: 14%;
}

.icons_footer {
   align-items: center;
   margin-left: 28%;
}
.descrip {
   color: white;
   text-align: center;
   margin-top: 0.5%;
}

.footer_div {
   text-align: center;
   align-items: center;
   display: block !important;
   margin-left: 1%;
   margin-bottom: 1%;
}
.social_logo {
   height: 37px;
   margin-left: 9%;
}
@media screen and (max-width: 1000px) {
   .footer_div {
      margin-bottom: 10%;
      /* padding-bottom: 8%; */
   }
   .mobileViewMecLogo {
      display: unset !important;
   }
   .desktopViewMecLogo {
      display: none;
   }
}
@media screen and (max-width: 800px) {
   .social_logo {
      height: 25px;
      margin-left: 3%;
   }
   .mobileViewMecLogoexcel2021_logo {
      margin-top: 5%;
      width: 30%;
      height: 30%;
   }
}

.container1 {
   width: 80px;
   height: 100px;
   margin: 100px auto;
}
.loader-top {
   margin-top: calc(100vh / 7 - 50px);
}
.loader-center {
   margin-top: calc(100vh / 2 - 50px);
}
.block {
   position: relative;
   box-sizing: border-box;
   float: left;
   margin: 0 10px 10px 0;
   width: 12px;
   height: 12px;
   border-radius: 3px;
   background: #9d45f5;
}

.block:nth-child(4n + 1) {
   -webkit-animation: wave 2s ease 0s infinite;
           animation: wave 2s ease 0s infinite;
}
.block:nth-child(4n + 2) {
   -webkit-animation: wave 2s ease 0.2s infinite;
           animation: wave 2s ease 0.2s infinite;
}
.block:nth-child(4n + 3) {
   -webkit-animation: wave 2s ease 0.4s infinite;
           animation: wave 2s ease 0.4s infinite;
}
.block:nth-child(4n + 4) {
   -webkit-animation: wave 2s ease 0.6s infinite;
           animation: wave 2s ease 0.6s infinite;
   margin-right: 0;
}

@-webkit-keyframes wave {
   0% {
      top: 0;
      opacity: 1;
   }
   50% {
      top: 30px;
      opacity: 0.2;
   }
   100% {
      top: 0;
      opacity: 1;
   }
}

@keyframes wave {
   0% {
      top: 0;
      opacity: 1;
   }
   50% {
      top: 30px;
      opacity: 0.2;
   }
   100% {
      top: 0;
      opacity: 1;
   }
}

.dead-end-container {
   display: flex;
   flex-direction: column;
   grid-gap: 1rem;
   gap: 1rem;
   text-align: center;
   opacity: 0.8;
   /* padding: 1rem; */
   justify-content: center;
}

@media (min-width: 0px) and (max-width: 599px) {
   .dead-end-container {
      padding-top: 2rem;
   }
}
.deadend-img {
   fill: white;
   margin: auto;
   max-width: 12rem;
   height: 160px;
   width: 160px;
}
@media (min-width: 0px) and (max-width: 599px) {
   .deadend-img {
      max-width: 10rem;
   }
}
.dead-end-container .title {
   color: white;
   font-size: 2rem;
   font-weight: 700;
   display: flex;
   justify-content: center;
}
@media (min-width: 0px) and (max-width: 599px) {
   .dead-end-container .title {
      font-size: 1.2rem;
   }
}
.dead-end-container .subtitle1 {
   color: white;
   font-size: 1rem;
}

.dead-end-container .subtitle2 {
   color: white;
   font-size: 1rem;
}

.ripple-button {
   border-radius: 4px;
   border: none;
   margin: 8px;
   width: 6.3rem;
   height: 2.5rem;
   background: rgb(16, 15, 22);
   color: #fff;
   overflow: hidden;
   position: relative;
   cursor: pointer;
   font-size: 0.89rem;
   font-weight: 500;
   letter-spacing: 1px;
   color: #adb5bd;

   /* CHANGE: added a border */
   border: 2px solid #adb5bd;
}

.ripple-button > .ripple {
   width: 20px;
   height: 20px;
   position: absolute;
   background: #b061ff;
   display: block;
   content: "";
   border-radius: 9999px;
   opacity: 1;
   -webkit-animation: 1.5s ease 1 forwards ripple-effect;
           animation: 1.5s ease 1 forwards ripple-effect;
}

@-webkit-keyframes ripple-effect {
   0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
   }
   50% {
      -webkit-transform: scale(20);
              transform: scale(20);
      opacity: 0.375;
   }
   100% {
      -webkit-transform: scale(35);
              transform: scale(35);
      opacity: 0;
   }
}

@keyframes ripple-effect {
   0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
   }
   50% {
      -webkit-transform: scale(20);
              transform: scale(20);
      opacity: 0.375;
   }
   100% {
      -webkit-transform: scale(35);
              transform: scale(35);
      opacity: 0;
   }
}

.ripple-button > .content {
   position: relative;
   z-index: 2;
}
.ripple-button:focus {
   border: 1px solid #b061ff;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
   color: white;
   transition-delay: 0.8s;
   background-color: #b061ff;
   border-radius: 8px;
   transition-property: background-color, border-radius, box-shadow;
}
.ripple-button:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.1px rgb(223, 222, 222) !important;
}
@media screen and (max-width: 700px) {
   .ripple-button {
      width: 5rem;
      margin-right: 1%;
   }
}

.event-img {
   height: 110px;
   width: 110px;
   border-radius: 10%;
}
.competition-tile-div {
   padding: 10px 0;
   padding-left: 15px;
   text-align: left;
}

.eventCardContainer {
   position: relative;
   display: flex;
   grid-gap: 2rem;
   gap: 2rem;
   padding: 2rem 3rem;
   border-radius: 0.55rem;
   color: white;
   cursor: pointer;
   /* background: #331f4e9d; */
   background: #2c1c479d;
}
.eventCardContainer::before {
   position: absolute;
   top: 0;
   left: 0;
}
.eventCardContainer .eventCardHeaderBox {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin: auto 0;
   grid-gap: 8px;
   gap: 8px;
   padding-top: 0.5rem;
}
.eventCardContainer .eventCardHeaderBox .eventCardType {
   opacity: 0.7;
   letter-spacing: 2px;
   font-size: 1rem;
   text-transform: capitalize;
   margin-top: 15px;
}
.eventCardContainer .eventCardHeaderBox .eventCardDate {
   opacity: 0.7;
   letter-spacing: 2px;
   font-size: 0.9rem;
}
.eventCardContainer .eventInfoBox {
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   margin: 10px 0;
   margin-left: -5px;
   line-height: 26px;
}
.eventCardContainer .eventInfoBox .eventCardTitle {
   font-size: 40px;
   font-weight: 900;
   line-height: 40px;
}
.eventCardContainer .eventInfoBox .eventCardInfo {
   margin: 2rem 0;
   position: relative;
   overflow: hidden;
   white-space: pre-line;
   font-size: 19px;
}
@media screen and (max-width: 1500px) {
   .eventCardContainer {
      grid-gap: 2rem;
      gap: 2rem;
      padding: 2rem 3rem;
   }
   .eventCardHeaderBox {
      grid-gap: 80px;
      gap: 80px;
      padding-top: 0.5rem;
      padding-left: 25px;
      padding-right: 25px;
   }
   .eventCardHeaderBox .eventCardType {
      opacity: 0.7;
      margin-top: 10px;
      letter-spacing: 2px;
      font-size: 1rem;
      text-transform: capitalize;
   }
   .eventCardHeaderBox .eventCardDate {
      opacity: 0.7;
      letter-spacing: 2px;
      font-size: 0.9rem;
   }
   .eventInfoBox {
      margin-left: 1rem;
   }
   .eventInfoBox .eventCardTitle {
      font-size: 30px !important;
   }
   .eventInfoBox .eventCardInfo {
      font-size: 16px !important;
      margin: 1rem 0 !important;
   }
}
@media screen and (max-width: 580px) {
   .eventCardContainer {
      padding: 1rem 0.1rem;
   }
   .eventCardContainer .eventCardHeaderBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .eventCardContainer .eventCardHeaderBox .eventCardType {
      display: none;
   }
   .eventCardContainer .eventInfoBox {
      margin-top: 5px;
      margin-left: 0;
   }
   .eventCardContainer .eventInfoBox .eventCardTitle {
      font-size: 23px !important;
   }
   .eventCardContainer .eventInfoBox .eventCardInfo {
      margin-top: 1rem;
      font-size: 15px !important;
   }
}

@media screen and (max-width: 580px) {
   .eventCardContainer {
      flex-direction: column;
   }

   .eventCardContainer .eventCardHeaderBox .flipIcon {
      max-width: 6rem;
      max-height: 6rem;
   }

   .eventCardContainer .eventInfoBox {
      text-align: center;
   }

   .eventCardContainer .eventInfoBox .eventCardTitle {
      font-size: 30px;
   }
}

@media screen and (max-width: 1400px) {
   .eventCardContainer .eventInfoBox {
      padding-left: 2rem;
      padding-right: 2rem;
   }
}

.eventCardImg {
   margin: 0 auto;
   align-self: center;
}

.eventsContainer {
  position: relative;
  z-index: 101;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 85%;
  margin: 0px auto;
  padding: 2rem;
  /* padding-bottom: 100px; */
}
.events-categories {
  display: flex;
  flex-direction: row;
}
.category-div {
  display: none;
}
.events-group-1 {
  display: flex;
  flex-direction: row;
}
.events-group-2 {
  display: flex;
  flex-direction: row;
}
.eventsContainer2 {
  position: relative;
  z-index: 101;
  display: grid;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 85%;
  margin: 20px auto;
  padding: 0 6vw;
  /* padding: 2rem; */
}
.sort-filter-box-2 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 5rem;
  gap: 5rem;
  margin-left: 3%;
  margin-right: 3%;
  padding: 0 6vw;
}
.sort-down {
  padding: 0.4rem;
  padding-right: 0.3rem;
  border: 2px solid #adb5bd;
  border-radius: 5px;

  background-color: #100f16;
  color: white;
  float: center;
  font-size: 1rem;
}

@media screen and (min-width: 850px) {
  .category-row > .category-col {
    display: none;
    /* width: 0; */
  }
}

@media screen and (max-width: 700px) {
  .sort-filter-box-events {
    margin-top: 50px;
    margin-left: 47px;
    margin-right: 51px;
  }
  .events-categories {
    display: none;
  }
  .category-row {
    margin-left: 6%;
  }
  .category-div {
    display: flex;
  }
  .radio-tile-group {
    margin: -11%;
  }

  .sort-filter-box-2 {
    grid-gap: 0rem;
    grid-gap: 0rem;
    gap: 0rem;
    margin-bottom: 10%;
    margin-left: 0px;
    font-size: 11px;
    display: flex;
    justify-content: center;
  }
  .category-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .category-div {
    display: flex;
  }
}

@media (max-width: 580px) {
  .eventsContainer {
    padding: 2rem 1.2rem;
  }
}

.competition-card-container {
   display: flex;
   justify-content: center;
   align-items: center;
}
.competition-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 400px;
   width: 400px;
   color: white;
   background-color: #191629;
   background: transparent;
   box-shadow: 0px 5px 30px 0px #0000004d;
   border-radius: 5%;
}
.competition-card:hover {
   cursor: pointer;
   /* transform: translateY(-8px); */
   transition: 0.5s 0.1s;
}
.competition-card .icon {
   border-radius: 9999px;
   margin: 1rem;
   margin-top: 2rem;
   padding: 2em;
   padding-bottom: 0.5rem;
}

.competition-card .title {
   text-align: center;
   display: flex;
   justify-content: center;
   font-size: 1.3rem;
   /* margin: 1rem 0; */
}
.card-flip-title {
   font-size: 1.3rem !important;
   color: var(--subtitle-color);
}
.competition-card .title span {
   font-weight: bold;
   font-size: 1.1rem !important;
}
.competition-card .category {
   text-transform: capitalize;
   color: var(--subtitle-color);
   font-weight: 600;
}
.competition-card .description {
   margin: 1rem;
   position: relative;
   white-space: pre-line;
}

@-webkit-keyframes anvil {
   0% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   1% {
      -webkit-transform: scale(0.96) translateY(10px);
              transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   100% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
   }
}

@keyframes anvil {
   0% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   1% {
      -webkit-transform: scale(0.96) translateY(10px);
              transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   100% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
   }
}
.popup-content {
   -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
           animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@media screen and (max-width: 1870px) {
   .competition-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 400px;
      width: 400px;
      color: white;
      -webkit-filter: grayscale(20%);
              filter: grayscale(20%);
   }

   .competition-card .icon {
      padding-bottom: 0.5rem;
   }
   .competition-card .icon img {
      max-width: 7.5rem;
   }
   .competition-card .title {
      margin: 1rem 0;
   }
   .competition-card .title span {
      font-size: 1.4rem;
   }
   .competition-card .category {
      font-size: 1rem;
   }
}
@media screen and (max-width: 1700px) {
   .competition-card {
      width: 300px;
      height: 300px;
   }
   .competition-card .icon {
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 5rem;
      max-height: 5rem;
   }
   .competition-card .title {
      margin: 1rem 0;
      margin-bottom: 1.25rem;
   }
   .competition-card .title span {
      font-size: 1.3rem;
   }
   .competition-card .category {
      font-size: 1rem;
   }
}

@media screen and (max-width: 1550px) {
   .competition-card {
      width: 250px;
      height: 250px;
   }
   .competition-card .icon {
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 4.4rem;
      max-height: 4.4rem;
   }
   .competition-card .title {
      margin: 0.2rem 0;
   }
   .competition-card .title span {
      font-size: 1.3rem;
   }
}
@media screen and (max-width: 1290px) {
   .competition-card {
      width: 230px;
      height: 230px;
   }
   .competition-card .icon {
      padding: 1rem;
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 4.2rem;
      max-height: 4.2rem;
   }
   .competition-card .title {
      margin: 0.1rem 0;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
   .competition-card .category {
      font-size: 0.9rem;
   }
}
@media screen and (max-width: 870px) {
   .competition-card-container {
      width: 220px;
   }
   .competition-card {
      width: 220px;
      height: 220px;
   }
   .competition-card .icon {
      padding: 0.2rem;
   }
   .competition-card .icon img {
      max-width: 4rem;
   }
   .competition-card .title {
      margin: 0;
      margin-bottom: 0.5rem;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
}
@media screen and (max-width: 800px) and (max-height: 1300px) {
   .competition-card-container {
      width: 250px;
   }
   .competition-card {
      width: 250px;
      height: 250px;
   }
   .competition-card .icon {
      padding: 1rem;
   }
   .competition-card .icon img {
      max-width: 6rem;
   }
   .competition-card .title {
      margin: 0;
      margin-bottom: 0.5rem;
      margin-top: -35px;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
}
@media screen and (max-width: 720px) and (max-height: 1300px) {
   .competition-card-container {
      margin: 10px;
      width: 210px;
   }
   .competition-card {
      width: 210px;
      height: 210px;
   }
   .competition-card .icon {
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 0;
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 6rem;
   }
   .competition-card .title {
      margin: 0.3rem 0;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
   .competition-card .category {
      font-size: 0.9rem;
   }
}

@media screen and (max-width: 480px) {
   .title {
      font-size: 10px;
      padding-top: 18%;
   }

   .competition-card-container {
      width: 160px;
      margin: 10px;
      /* ;
      */
   }
   .competition-card {
      width: 170px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .competition-card .icon {
      padding: 0rem;
      padding-bottom: 0rem;
      margin-bottom: 0rem;
      padding-top: 12%;
   }
   .competition-card .icon img {
      max-width: 5.5rem;
   }
   .competition-card .title {
      margin: 0.1rem 0;
      padding-top: 10%;
      position: relative;
      top: 0;
      font-size: 10px !important;
   }

   .competition-card .category {
      font-size: 1.2rem;
      padding-bottom: 10px;
   }

   .competition-card .category span {
      font-size: 16px;
   }
   .card-flip-title {
      font-size: 1.2rem !important;
   }
}

@media screen and (max-width: 400px) {
   .competition-card-container {
      width: 150px;
      margin: 10px;
   }
   .competition-card {
      width: 150px;
      height: 150px;
   }
   .icon {
      margin-top: 20px;
   }
   .img {
      max-width: 4rem;
   }
}
.title {
   margin: 1rem 0 0.25rem 0;
   display: flex;
}
.span {
   font-size: 0.95rem;
}

.category {
   font-size: 0.75rem;
}

@media screen and (max-width: 340px) {
   .competition-card-container {
      width: 130px;
      margin: 8px;
   }
   .competition-card {
      width: 130px;
      height: 130px;
   }
   .icon {
      margin-top: 20px;
   }
   .img {
      max-width: 3rem;
   }
}
.title {
   margin: 1rem 0 0.25rem 0;
   display: flex;
}
.span {
   font-size: 0.95rem;
}

.category {
   font-size: 0.75rem;
}

@media screen and (max-width: 580px) {
   .competition-card {
      justify-content: center;
   }

   .competition-card .icon .img {
      max-width: 4rem;
      max-height: 4rem;
   }
}

.card-container2 {
   cursor: pointer;
   height: 250px;
   -webkit-perspective: 600;
           perspective: 600;
   position: relative;
   /* width: 140%; */
}
.card {
   height: 100%;
   position: absolute;
   -webkit-transform-style: preserve-3d;
           transform-style: preserve-3d;
   transition: all 1s ease-in-out;
   width: 100%;
   background: transparent;
}
.card:hover {
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
}
.card .side {
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
   border-radius: 6px;
   height: 100%;
   position: absolute;
   overflow: hidden;
   width: 100%;
   background: #251b419d;

   /* background-image: linear-gradient(60deg, #191629, #231831); */
}
.card .back {
   background: transparent;
   padding: 15%;
   text-align: center;
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
}
@media screen and (max-width: 600px) {
   .card {
      width: 105%;
      height: 95%;
   }
   .card .side,
   .card .back {
      height: 100%;
   }
   .title {
      font-size: 1rem;
   }
}
@media (min-width: 1800px) and (max-width: 2070px) {
   .card-container2 {
      cursor: pointer;
      height: 300px !important;
      -webkit-perspective: 600;
              perspective: 600;
      position: relative;
      /* width: 140%; */
   }
}

.register-button {
   background-color: transparent;
   color: white !important;
   margin: 1rem 2rem;
   padding: 0.5rem;
   font-size: 1rem;
   border-radius: 10px;
   font-family: "Open Sans", sans-serif;
   border: 2px solid #adb5bd !important;
   cursor: pointer;
   transition: 200ms ease-in-out;
   text-decoration: none !important;
}

.register-button:hover {
   background-color: #b061ff !important;
   border: 2px solid #b061ff;
   border-radius: 10px;
   color: white !important;
}

.registered {
   cursor: default;
   font-style: italic;
   font-size: 1rem;
}
.registered:hover {
   -webkit-transform: none;
           transform: none;
   background: transparent;
   color: white;
}

.update-mobile-popup {
   display: flex;
   flex-direction: column;
   background-color: white;
   padding: 1rem;
   border-radius: 6px;
   box-shadow: 0px 0px 12px #000 55;
}
.update-mobile-popup .close-container {
   display: flex;
   flex-direction: row-reverse;
}
.update-mobile-popup .close-container svg {
   height: 1rem;
   width: auto;
}
.update-mobile-popup .content {
   display: flex;
   flex-direction: column;
   padding: 1rem;
   box-sizing: border-box;
}
.update-mobile-popup .content .buttons-container {
   display: flex;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: auto;
   margin-top: 1rem;
}
.update-mobile-popup .content .buttons-container button {
   border: none;
   background-color: #b061ff;
   border-radius: 6px;
   color: white;
   box-shadow: 0px 0px 8px #37b9ff 99;
   padding: 0.6rem;
}

@media screen and (max-width: 580px) {
   .register-button:focus {
      margin: 1rem auto;
   }

   .update-mobile-popup .content {
      width: 80vw;
   }
}

@media screen and (max-width: 850px) {
   .register-button:focus {
      margin: 1rem auto;
   }
}

@media screen and (max-width: 1400px) {
   .reg-closed:hover {
      background-color: white;
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      color: black;
   }
}

.loading-dot {
  height: 0.4rem;
  width: auto;
}

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
  margin-right: 3px;

  svg {
    color: white;
  }
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
  margin-right: 3px;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes dot {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.register-section {
   display: flex;
   flex-direction: column;
   padding: 1rem;
}
.register-section .button-group {
   display: flex;
   justify-content: center;
   margin-bottom: 1rem;
}
.register-section .button-group button {
   font-size: 1rem;
   background-color: transparent;
   box-shadow: none;
   padding: 0.5rem;
   border: 1px solid #b061ff;
   width: 14ch;
   transition: 0.2s;
   color: white;
}
.register-section .button-group button:hover {
   cursor: pointer;
   background: rgba(49, 48, 82, 0.9);
}
.register-section .button-group button:first-child {
   border-radius: 6px 0 0 6px;
   border-right: none;
}
.register-section .button-group button:last-child {
   border-radius: 0 6px 6px 0;
}
.register-section .button-group button.active {
   background-color: #b061ff;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   cursor: default;
}
.register-section .create-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
}
.register-section .create-team-subpage form {
   display: flex;
   flex-direction: column;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   width: 9rem;
   margin: auto;
   margin-top: 1rem;
}
.register-section .create-team-subpage form input[type="text"] {
   font-size: 1rem;
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   width: 9rem;
}
.register-section .create-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 9rem;
   margin: auto;
   margin-top: 0.5rem;
   font-size: 1rem;
   cursor: pointer;
}
.register-section .create-team-subpage form input[type="submit"]:hover {
   -webkit-transform: scale(1.05);
           transform: scale(1.05);
   transition: 0.5s;
}
.register-section .create-team-subpage form .fake-create-button {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 12ch;
   margin: auto;
   margin-top: 0.5rem;
}
.register-section .join-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
}
.register-section .join-team-subpage form {
   display: flex;
   flex-direction: column;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   width: 9rem;
   margin: auto;
   font-size: 1rem;
   margin-top: 1rem;
}
.register-section .join-team-subpage form input[type="number"] {
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   font-size: 1rem;
   width: 9rem;
}
.register-section .join-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: auto;
   margin-top: 0.5rem;
   font-size: 1rem;
   cursor: pointer;
}
.register-section .join-team-subpage form input[type="submit"]:hover {
   -webkit-transform: scale(1.05);
           transform: scale(1.05);
   transition: 0.5s;
}
.register-section .join-team-subpage form .fake-join-button {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 10ch;
   margin: auto;
   margin-top: 0.5rem;
}

@media screen and (max-width: 1400px) {
   .register-section {
      margin-top: 0.1rem;
   }

   .register-section .create-team-subpage {
      margin-top: 1rem;
   }

   .register-section .join-team-subpage {
      margin-top: 1rem;
   }
}

.manage-section {
   display: flex;
   flex-direction: column;
   padding: 1rem;
   text-align: center;
   font-family: "Open Sans", sans-serif;
}
.manage-section .button-group {
   display: flex;
   justify-content: center;
   margin-bottom: 1rem;
}
.manage-section .button-group button {
   font-size: 1rem;
   background-color: transparent;
   box-shadow: none;
   padding: 0.5rem;
   border: 1px solid #b061ff;
   width: 14ch;
   transition: 0.2s;
   color: white;
   font-family: "Open Sans", sans-serif;
}
.manage-section .button-group button:hover {
   cursor: pointer;
   background: rgba(49, 48, 82, 0.9);
}
.manage-section .button-group button:first-child {
   border-radius: 6px 0 0 6px;
   border-right: none;
}
.manage-section .button-group button:last-child {
   border-radius: 0 6px 6px 0;
}
.manage-section .button-group button.active {
   background-color: #b061ff;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   cursor: default;
}
.manage-section .view-subpage {
   display: flex;
   flex-direction: column;
}

.manage-section .view-subpage .team-members-box {
   margin: auto;
   margin-top: 1rem;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   padding: 1rem 2rem 2rem;
   border-radius: 6px;
   text-align: center;
}
.view-team-table {
   display: flex;
   margin: auto !important;
   align-items: center;
   justify-items: center;
}
.manage-section .view-subpage .team-members-box .team-members-container {
   margin-top: 1rem;
}
.manage-section .view-subpage .team-members-box .team-members-container .impossible-state-empty {
   opacity: 0.5;
}
.table-bordered {
   border: 0.6px solid #2b2a2c;
}

.manage-section .view-subpage .team-id-help {
   margin-top: 0.1rem !important;
   font-size: 1rem;
   opacity: 0.8;
}
.manage-section .change-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin-top: 1.5rem;
   font-size: 25px;
}
.manage-section .change-team-subpage form {
   display: flex;
   flex-direction: column;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   margin: auto;
   margin: 1rem auto;
}
.manage-section .change-team-subpage form input[type="number"] {
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   font-size: 1rem;
   width: 9rem;
}
.manage-section .change-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: auto;
   margin-top: 0.5rem;
   cursor: pointer;
   font-size: 1rem;
   width: 9rem;
}
.manage-section .change-team-subpage form input[type="submit"]:hover {
   -webkit-transform: scale(1.02);
           transform: scale(1.02);
   transition: 0.5s;
}

.manage-section .change-team-subpage form .fake-change-button {
   background-color: #b061ff;
   padding: 0.8rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 14ch;
   margin: auto;
   margin-top: 1.3rem;
}
.dot-loader {
   width: 3rem;
   margin: auto;
   margin-top: 2rem;
}

@media screen and (max-width: 1400px) {
   .manage-section {
      margin-top: 0.1rem;
   }

   .manage-section .view-subpage {
      margin-top: 1rem;
   }
}

.popup-bg {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 5;
   /* background-image: url("https://images.unsplash.com/photo-1604871000636-074fa5117945?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"); */
   background-image: linear-gradient(to bottom, rgba(88, 4, 121, 0.411), rgba(26, 31, 75, 0.609)), url("https://images.unsplash.com/photo-1604871000636-074fa5117945?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
}
.competition-image {
   width: 125px;
   height: 105px;
   margin-left: 5% !important;
}
.popup-bg::before {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 4;
}
.popup-content-rows {
   display: flex;
   flex-direction: row;
}

.popup-content-container {
   display: flex;
   flex-direction: column;
   width: 90%;
   border-radius: 12px;
   height: 38rem;
   z-index: 10;
   background: #100f20fb;
   border-radius: 20px;
   color: white;
   font-family: "Open Sans", sans-serif;
}
.popup-content-container .popup-sidebar {
   background-color: transparent;
   display: flex;
   flex-direction: column;
   min-width: 15rem;
   border-radius: 12px 0px 0 12px;
   box-shadow: none;
   z-index: 10;
   border-right: 1px solid rgba(255, 255, 255, 0.18);
   padding: 0 1rem;
   font-family: "Open Sans", sans-serif;
}

.header-container {
   margin-top: 40px;
}
.competition-name {
   font-size: 3.2rem;
   font-weight: 600;
   font-family: "Raleway", sans-serif;
}
.competition-category {
   color: #b061ff;
}
.popup-content-container .popup-sidebar .close-container-mobile {
   display: flex;
   margin: 0.5rem;
   flex-direction: row-reverse;
}
.popup-content-container .popup-sidebar .close-container-mobile .close-button {
   position: fixed;
   width: 2rem;
   height: auto;
   fill: white;
   right: 10px;
}
.popup-content-container .popup-sidebar {
   display: flex;
   flex-direction: column;
}
.popup-content-container .popup-sidebar.image-title-box {
   display: flex;
   flex-direction: column;
}
.popup-content-container .popup-sidebar.image-title-box img {
   padding: 1rem;
   background-color: transparent;
   border-radius: 0px;
   margin: 2rem auto 1rem;
   max-width: 10rem;
   border: none;
   box-shadow: none;
   text-align: center;
}
.popup-content-container .popup-sidebar .header-container .image-title-box .name {
   text-align: center;
   font-size: 1.4rem;
   font-weight: 600;
   margin: 0 2rem 0.5rem 2rem;
   overflow-wrap: break-word;
}
.popup-content-container .popup-sidebar .event-anchor-tag {
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
}
.popup-content-container .popup-sidebar .event-register-button {
   background-color: transparent;
   color: white;
   margin: 0 2rem;
   margin-top: 1rem;
   padding: 0.5rem 2.5rem;
   font-size: 1.3rem;
   border-radius: 10px;
   font-family: "Open Sans", sans-serif;
   border: 0.0625rem solid #0086ce;
   border-color: #afd9f0;
   cursor: pointer;
   transition: 200ms ease-in-out;
}
.popup-content-container .popup-sidebar .header-container .event-register-button:focus {
   outline: none !important;
}
.popup-content-container .popup-sidebar .header-container .event-register-button:hover {
   background-color: white;
   -webkit-transform: scale(1.05);
           transform: scale(1.05);
   color: black;
   outline: none;
}
.popup-content-container .popup-sidebar .reg-prize-box {
   display: flex;
   flex-direction: column;
   grid-gap: 0.3rem;
   gap: 0.3rem;
}
.popup-content-container .popup-sidebar .alt-register {
   margin-top: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}
.popup-content-container .popup-sidebar .alt-register span {
   max-width: 15rem;
   color: white;
   text-align: center;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
   list-style: none;
   padding: 1rem 1rem;
   color: #000;
   font-size: 1rem;
   font-weight: 200 !important;
   margin-top: 0;
   flex-wrap: wrap;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
   padding: 0.4rem;
   margin: 0.9rem 0.5rem;
   border-radius: 3px;
   color: white;
   text-align: center;
   border: 1px solid;
   cursor: pointer;
   transition: 0.5s ease-out;
   font-weight: 100;
   opacity: 0.8;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #191833fb, 0 0 0 3.1px #fff !important;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li.active {
   opacity: 1;
   background-color: #b061ff;
   border: 1px solid #b061ff;
   box-shadow: 0 0 0 3px #191833fb, 0 0 0 3.5px #b061ff;
   color: white;
   border-radius: 6px;
}
.popup-content-container .popup-stack {
   padding: 1rem;
   /* margin: 1rem 0 !important; */
   font-size: 1.2rem;
   line-height: 2rem;
   white-space: pre-line;
   background-color: transparent;
   border-radius: 0px 12px 12px 0px;
   height: 70vh !important;
   overflow-y: scroll;

   width: 95% !important;
}
.popup-content-container .popup-stack .close-container {
   display: none;
}
.close-container .close-button {
   fill: white;
   position: fixed;
   width: 1.7rem;
   height: auto;
   cursor: pointer;
   transition: 100ms ease-in-out;
   position: absolute;
   top: 75px;
   right: 120px;
}
.close-container .close-button:hover {
   -webkit-transform: scale(1.1);
           transform: scale(1.1);
   fill: var(--teritary);
}
.popup-content-container .popup-stack .about-section {
   display: flex;
   justify-content: center;
   font-weight: 200 !important;
   color: var(--description-color);
}
.popup-content-container .popup-stack .about-extra {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   bottom: 0;
   margin: 2rem;
}

.popup-content-container .popup-stack .about-extra-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 2rem 5rem;
   /* font-size: 18px; */
   white-space: nowrap;
}
.about-extra-content {
   margin-bottom: 5%;
   color: #dee2e6;
   font-weight: 200 !important;
}

.popup-content-container .popup-stack .about-extra-content .prize-money,
.popup-content-container .popup-stack .about-extra-content .date,
.popup-content-container .popup-stack .about-extra-content .venue {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.popup-content-container .popup-stack .about-extra-header {
   font-size: 18px;
   font-weight: 500;
   color: #b061ff;
   padding-bottom: 1px;
}
.popup-content-container .popup-stack .section {
   margin: 1rem 1rem;
   font-size: 19px;
}
.popup-content-container .popup-stack .contact-section {
   /* margin-top: 4rem; */
   display: flex;
   justify-content: center;
}
.popup-content-container .popup-stack .contact-section div {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin: 1.5rem 5rem;
}
.popup-content-container .popup-stack .contact-section div img {
   max-width: 20px;
   padding-right: 10px;
}
.popup-content-container .popup-stack .contact-section div .name {
   font-size: 23px;
   font-weight: 400;
   display: flex;
   justify-content: center;
   align-items: center;
   white-space: nowrap;
}
.popup-content-container .popup-stack .contact-section div .email {
   color: #ecebeb;
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .role {
   color: #b061ff;
   text-decoration: none !important;
}
.popup-content-container .popup-stack .contact-section div .email:hover {
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .phone {
   font-weight: 400;
   color: var(--description-color);
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .phone:hover {
   text-decoration: underline;
   cursor: pointer;
}

.competition-tile-div {
   padding: 10px 0;
   padding-left: 15px;
   text-align: left;
}

.image-title-box {
   text-align: center;
}
@media (min-width: 1500px) and (max-width: 5000px) {
   .popup-content-container {
      height: 95vh;
   }
   .popup-content-container .popup-stack .section {
      max-height: 50vh;
      overflow: scroll;
   }
}
@media screen and (max-width: 1400px) {
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      cursor: pointer;
      transition: 125ms ease-in-out;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li:hover:not(.active) {
      opacity: 1;
      transition-duration: 0.3s 0s;

      color: black;
      box-shadow: none;
   }

   .popup-content-container .popup-stack {
      min-width: 30rem;
      width: 60vw;
      overflow-y: scroll;
      max-height: 50vh;
   }

   .popup-content-container .popup-stack .close-container {
      display: flex;
      flex-direction: row-reverse;
      padding-top: -20px;
      padding-right: 15px;
   }
}

@media screen and (max-width: 850px) {
   .popup-content-container {
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 0;
      overflow: scroll;
   }
   .competition-image {
      margin: 0 auto;
   }

   .competition-name {
      font-size: 3rem;
   }

   .popup-sidebar {
      border-radius: 0;
      flex-direction: column;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
   }

   .popup-content-container .popup-sidebar .reg-prize-box {
      margin: auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      display: flex;
      padding: 0rem 1rem;
      margin: auto;
      font-size: 1.2rem;
      justify-content: space-evenly;
   }
   .popup-content-container .popup-stack .about-section {
      text-align: center;
      /* font-size: 17px; */
   }

   .popup-content-container .popup-stack {
      min-width: 30rem;
   }
}

@media screen and (max-width: 700px) {
   .eventHead2 {
      padding-top: 6%;
   }
   /* .about-section {
      font-size: 17px;
   } */
   .competition-name {
      font-size: 2.4rem;
   }
}

@media screen and (max-width: 580px) {
   .popup-content-container {
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 0;
      overflow: scroll;
   }
   .popup-content-container .popup-sidebar {
      border-radius: 0;
      flex-direction: column;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content;
   }

   .popup-content-container .popup-sidebar .close-container-mobile .close-button {
      position: relative;
      right: -10px;
      top: 0px;
      width: 4rem;
   }

   .popup-content-container .popup-sidebar .header-container {
      justify-content: center;
      align-items: center;
   }

   .popup-content-container .popup-sidebar .header-container .image-title-box img {
      margin-top: 0;
   }

   .popup-content-container .popup-sidebar .header-container .image-title-box .name {
      font-size: 2rem !important;
   }

   .popup-content-container .popup-sidebar .header-container .reg-prize-box {
      margin: auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
   }

   .popup-content-container .popup-sidebar .header-container .alt-register {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 15px;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      display: flex;
      padding: 0rem 1rem;
      margin: auto;
      font-size: 1.2rem;
      justify-content: center;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      font-size: 0.9rem;
      padding: 0.4rem 0;
      margin: 0.7rem 0.1rem;
      min-width: 8ch;
      border-radius: 6px;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li.active {
      /* background-color: white; */
      color: black;
      box-shadow: none;
   }

   .popup-content-container .popup-stack {
      font-size: 1.1rem;
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100%;
   }

   .popup-content-container .popup-stack .about-extra {
      margin: 0rem;
      width: 375px;
   }

   .popup-content-container .popup-stack .about-extra-content {
      margin: 0 10px;
      font-size: 17px;
      padding-bottom: 100px;
   }

   .popup-content-container .popup-stack .about-extra-content .venue {
      white-space: normal;
   }

   .popup-content-container .popup-stack .about-extra-header {
      font-size: 20px;
   }

   .popup-content-container .popup-stack .section {
      font-size: 17px;
      margin: 0;
      padding: 0.5rem;
      padding-top: 0.5rem;
      width: 100vw;
      box-sizing: border-box;
   }

   .popup-content-container .popup-stack .contact-section {
      margin-top: 0rem;
      flex-direction: column;
   }

   .popup-content-container .popup-stack .contact-section div {
      /* margin: 20px 0; */
      margin-top: 0px;
   }

   .popup-content-container .popup-stack .contact-section div .name {
      font-size: 18px;
   }
   .competition-name {
      font-size: 2.2rem;
   }
}

@media (max-width: 992px) {
   .about-extra {
      display: flex;
      justify-content: space-around;
   }
   .competition-popup-hr {
      margin-top: -10px;
   }
   .popup-content-rows {
      flex-direction: column;
   }
   /* .contact-section{
      margin-top: 10%;
   } */

   .popup-nav {
      display: flex;
      justify-content: space-evenly;
   }

   .popup-content-container .popup-stack {
      width: auto;
      margin-top: 10px;
      overflow-y: scroll;
   }
   .popup-content-container .popup-stack .section {
      margin: 0px;
      margin-bottom: 30px;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      width: 15%;
   }
   .image-title-box {
      text-align: center;
   }
   .close-container .close-button {
      top: 9px;
      right: 10px;
   }
}

@media (max-width: 500px) {
   .about-extra {
      font-size: 12px;
   }
   .about-section {
      font-size: 0.9rem;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      padding: 0px;
      flex-wrap: nowrap;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      width: 5px;
   }
   .close-container .close-button {
      top: 9px;
      right: 10px;
   }
   .competition-name {
      font-size: 2rem;
   }
}

.competition-grid {
   position: relative;
   z-index: 0;
}
.category-div {
   display: none;
}

@media only screen and (min-width: 850px) {
  .compDropDown > .category-col {
    display: none;
    /* width: 0; */
  }
}

@media only screen and (max-width: 601px) {
   .competition-title {
      font-size: 41px !important;
   }
   .competition-sort-1 {
      display: flex;
      flex-direction: column !important;
   }
   .competition-sort-2 {
      display: flex;
      flex-direction: column !important;
   }
}
.competition-sort-1 {
   display: flex;
   flex-direction: row;
}
.competition-sort-2 {
   display: flex;
   flex-direction: row;
}
.sort-filter-box {
 display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  --bs-gutter-x: 0;
  padding: 0.8rem 5px;
  grid-gap: 5rem;
  gap: 5rem;
  margin-left: 0.5%;
  margin-right: 0.5%;
}
.drop-down-div {
   margin-top: 5px;
   text-align: center;
   -webkit-appearance: none !important;
           appearance: none !important;
}
.competition-grid .sort-filter-box .drop-down {
   border: 2px solid #adb5bd;
   border-radius: 5px;
   padding: 0.4rem;
   padding-right: 0.3rem;
   background-color: #100f16;
   color: white;
   float: center;
   font-size: 1rem;
}

.competition-grid .sort-filter-box .drop-down .is-selected {
   background-color: transparent !important;
   color: white !important;
}
.competition-grid .sort-filter-box .drop-down .Dropdown-option {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   color: white;
}
.competition-grid .sort-filter-box .drop-down .Dropdown-option .is-selected {
   background-color: rgba(224, 173, 247, 0.637) !important;
   color: #333 !important;
}

.competition-cards-container {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 3rem;
   gap: 3rem;
   text-align: center;
   padding: 1.5rem 0;
}
.loading-container {
   text-align: center;
   margin-top: 8rem;
}
.loading-container .image-circle {
   opacity: 0.15;
   background-color: white;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   margin: auto;
   padding: 1rem;
   border-radius: 9999px;
   border: 2px solid #000;
   box-shadow: 6px 6px 24px #05334e, 6px -6px 24px #7ff;
}
.loading-container .image-circle svg {
   margin: auto;
   width: 10rem;
   height: auto;
   -webkit-filter: drop-shadow(10px 3px 2px rgba(0, 0, 0, 0.397));
           filter: drop-shadow(10px 3px 2px rgba(0, 0, 0, 0.397));
   -webkit-animation: rocket 2s ease-in-out infinite;
           animation: rocket 2s ease-in-out infinite;
}
@-webkit-keyframes rocket {
   0% {
      -webkit-transform: translate(10px, -10px);
              transform: translate(10px, -10px);
   }
   25% {
      -webkit-transform: translate(50px, -50px);
              transform: translate(50px, -50px);
   }
   50% {
      -webkit-transform: translate(-15px, 15px);
              transform: translate(-15px, 15px);
   }
   75% {
      -webkit-transform: translate(30px, -30px);
              transform: translate(30px, -30px);
   }
   100% {
      -webkit-transform: translate(10px, -10px);
              transform: translate(10px, -10px);
   }
}
@keyframes rocket {
   0% {
      -webkit-transform: translate(10px, -10px);
              transform: translate(10px, -10px);
   }
   25% {
      -webkit-transform: translate(50px, -50px);
              transform: translate(50px, -50px);
   }
   50% {
      -webkit-transform: translate(-15px, 15px);
              transform: translate(-15px, 15px);
   }
   75% {
      -webkit-transform: translate(30px, -30px);
              transform: translate(30px, -30px);
   }
   100% {
      -webkit-transform: translate(10px, -10px);
              transform: translate(10px, -10px);
   }
}
.drop-down .Dropdown-control {
   background: rgba(195, 138, 221, 0.233) !important;
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(10.5px);
   border-radius: 10px;
   color: white;
   border: none;
   -webkit-transform: scale(1.3);
           transform: scale(1.3);
   z-index: 2;
   transition: none;
   cursor: pointer;
}

@media screen and (max-width: 1700px) {
   .competition-cards-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr) !important;
   }
   .competition-grid {
      padding: 0 6vw;
      padding-bottom: 70px;
   }
}
@media screen and (max-width: 1400px) {
   .competition-cards-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr) !important;
      grid-gap: 1rem !important;
      gap: 1rem !important;
   }
   .competition-grid {
      padding: 0 6vw;
      padding-bottom: 70px;
   }
}
@media screen and (max-width: 1150px) {
   .competition-cards-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr) !important;
   }
   .sort-filter-box {
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
   }
}
@media screen and (max-width: 850px) {
   .competition-cards-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
   }
   .sort-filter-box {
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
   }
   .drop-down {
      max-width: 7rem;
   }
   .competitionfilterbtn {
      display: none !important;
   }
   .category-row {
      display: flex;
      justify-content: space-around;
   }
   .category-div {
      display: flex;
      justify-content: space-around;
      margin-right: 5%;
   }
   .competition-cards-container {
      grid-template-columns: repeat(2, 1fr);
   }
}
@media screen and (max-width: 580px) {

  .competition-grid {
    padding: 0;
    padding-bottom: 70px;
  }
  .competition-cards-container {
    display: grid;
    margin-top: -5%;
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 15px;
    padding-right: 24px;

  }
  .competition-card-container {
    margin-left: 10%;
    margin-right: 14%;
  }
  .sort-filter-box {
    grid-gap: 0rem;
    gap: 0rem;
/* 
   .competition-grid {
      padding: 0;
      padding-bottom: 70px;
   }
   .competition-cards-container {
      display: grid;
      margin-top: -5%;
      grid-template-columns: repeat(2, 1fr) !important;
      padding: 15px;
      padding-right: 24px;
   }
   .competition-card-container {
      margin-left: 10%;
      margin-right: 14%;
   }
   .sort-filter-box {
      gap: 0rem;
*/

      margin-left: 0px;
      font-size: 11px;
      display: flex;
      justify-content: space-between;
   }
   .drop-down {
      max-width: 7rem;
      margin: 0 30px;
   }
   .compDropDown {
      margin-left: 1%;
      display: flex;
      justify-content: space-between !important;
   }
   .sort-filter-box {
      padding-left: 3%;
      padding-right: 3%;
   }
   .Dropdown-control {
      padding: 10px;
   }
   .drop-down-div {
      padding-top: 5%;
      margin-top: 3rem;
   }
}

.radio-tile-group {
  display: flex;
  /* align-self: flex-start !important; */
}
.radio-tile-group .input-container {
  position: relative;
  height: 2.2rem;
  width: 7.8rem;
  margin: 0rem;
}
@media screen and (max-width: 650px) {
  .radio-tile-group .input-container {
    margin: 13px;
  }
  .radio-tile-group {
    display: flex;
    justify-content: center;
  }
}
.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #adb5bd;
  border-radius: 5px;
  padding: 1rem;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}
.radio-tile-group .input-container .icon svg {
  fill: #adb5bd;
  width: 3rem;
  height: 3rem;
}
.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #adb5bd;
  transition: 0.5s ease-out;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
  background-color: #b061ff;
  border: 1px solid #b061ff;
  box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
  color: white;
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  border-radius: 10px;
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .icon
  svg {
  fill: white;
  background-color: #b061ff;
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
  background-color: #b061ff;
}

html {
   scroll-behavior: smooth;
}

.scheduleCardContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   border-radius: 20px;
   padding: 0.5rem 50px;

   color: white;
}
/* @supports not (
  (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
) {
  .scheduleCardContainer {
    background: rgba(189, 185, 243, 0.281);
    box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
  }
} */
.scheduleCardTime {
   font-size: 1.4rem;
   padding-left: 10%;
   text-transform: uppercase;
}
.scheduleContent {
   text-align: left;
   /* width: max-content; */
   width: 10rem;
}
.scheduleCardTitle {
   color: white;
   -webkit-font-feature-settings: "pcap";
           font-feature-settings: "pcap";
   font-variant-caps: petite-caps;
   font-size: 1.4rem;
   font-weight: 700;
   white-space: nowrap;
   letter-spacing: 2px;
}
.scheduleCardRound {
   font-size: 0.9rem;
   font-weight: 400;
   color: #949294;
}
.scheduleCardTime {
   width: 17rem;
   color: #b061ff;
   font-weight: bold;
   font-size: 1.2rem;
   margin-right: -60%;
}
.scheduleCardTime > .time {
   color: white !important;
   font-weight: lighter !important;
}
.outerVenue {
   height: 15px;

   margin-left: 75%;
}
.scheduleCardVenue {
   width: 10rem;
   font-size: 1.2rem;
   overflow-wrap: break-word;
   text-align: left;
   margin-right: -50%;
   flex: content;
   /* margin-left: 75%; */
}
.scheduleCardEventType {
   margin-top: 8px;
   font-size: 1rem;
   text-transform: capitalize;
   -webkit-font-feature-settings: "pcap";
           font-feature-settings: "pcap";
   font-variant-caps: petite-caps;
   font-size: 1.1rem;
   width: -webkit-max-content;
   width: max-content;
}
.scheduleCardDay {
   font-size: 20px;
}
.scheduleflipIconbg {
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 6rem;
   width: 6rem;
}
.scheduleflipIcon {
   max-width: 4rem;
   max-height: 4rem;
}
hr {
   background: rgba(96, 96, 96, 0.5);
   opacity: 0.8;
}
@media only screen and (max-width: 980px) {
   .scheduleCardContainer {
      margin: 12px 0;
   }
   .scheduleCardTime {
      font-size: 0.8rem;
      padding-left: 50%;
   }
   .scheduleCardTitle {
      -webkit-font-feature-settings: "pcap";
              font-feature-settings: "pcap";
      font-variant-caps: petite-caps;
      font-size: 1rem;
   }
   .scheduleContent {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
   }
   .scheduleCardEventType {
      text-transform: capitalize;
      -webkit-font-feature-settings: "pcap";
              font-feature-settings: "pcap";
      font-variant-caps: petite-caps;
   }
   .scheduleCardDay {
      font-size: 20px;
   }
   .outerVenue {
      margin-left: 5%;
   }
   .scheduleCardVenue {
      display: none;
   }
}
@media only screen and (max-width: 650px) {
   .scheduleCardContainer {
      padding: 0.5rem 15px;
   }

   .scheduleCardTime {
      font-size: 1rem;
      padding-left: 6%;
   }
   .scheduleCardTitle {
      font-size: 1rem;
   }
   .scheduleContent {
      width: 5rem;
      padding-left: 4%;
   }

   .scheduleCardVenue {
      width: 5rem;
      text-align: left;
      font-size: 0.9rem;
      display: flex;
      flex-wrap: wrap;
      margin-right: -50%;
      padding-top: 20%;
      padding-left: -20%;
   }
   .scheduleCardRound {
      font-size: 0.8rem;
   }
}
@media only screen and (max-width: 390px) {
   .scheduleCardContainer {
      padding: 0.5rem 15px;
   }
}
@media only screen and (max-width: 390px) {
   .scheduleCardContainer {
      padding: 0.5rem 10px;
   }
   .scheduleContent {
      width: 4rem;
   }
   .scheduleCardTitle {
      font-size: 0.8rem;
   }
   .scheduleCardTime {
      font-size: 0.7rem;
   }
   .scheduleCardVenue {
      font-size: 0.9rem;
      margin-right: -59%;
   }
}

@media screen and (max-width: 1400px) {
   .scheduleCardContainer {
      transition: 0.3s;
   }
}

@media screen and (max-width: 540px) {
   .scheduleflipIcon {
      max-width: 3rem;
      max-height: 3rem;
      margin-right: 0.5rem;
   }
}

.modern-radio-container {
   margin: 12px 8px;
   display: flex;
   cursor: pointer;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
}
.radio-outer-circle {
   width: 18px;
   height: 18px;
   min-width: 18px;
   min-height: 18px;
   border: 2px solid #9d45f5;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   transition: all 0.1s linear;
}
.radio-inner-circle {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: #9d45f5;
   transition: all 0.1s linear;
}
.unselected {
   border: 2px solid;
}
.unselected-circle {
   width: 0;
   height: 0;
}
.helper-text {
   font-size: 18px;
   padding-right: 8px;
   text-transform: capitalize;
}
@media (min-width: 0px) and (max-width: 599px) {
   .helper-text {
      font-size: 15px;
   }
}
.modern-radio-container {
   margin: 7px 8px;
   display: flex;
   cursor: pointer;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
}
.radio-outer-circle {
   width: 18px;
   height: 18px;
   min-width: 18px;
   min-height: 18px;
   border: 2px solid #9d45f5;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   transition: all 0.1s linear;
}
.radio-inner-circle {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: #9d45f5;
   transition: all 0.1s linear;
}
.unselected {
   border: 2px solid;
}
.unselected-circle {
   width: 0;
   height: 0;
}
.helper-text {
   font-size: 18px;
   padding-right: 8px;
   text-transform: capitalize;
}
@media (min-width: 0px) and (max-width: 599px) {
   .helper-text {
      font-size: 15px;
   }
}

.schedulePopupContainer {
  margin: 0 auto;
  background: #191833fb;
  box-shadow: 0px 5px 30px 0px #0000004d;
  border-radius: 12px;
  padding: 64px 72px;
  font-family: "Open Sans", sans-serif;
  margin-left: -6rem;
  margin-top: -8rem;
  width: 55rem;
  position: relative;
}

.schedulePopupContainer::before {
  position: absolute;
  content: "";
  top: -160px;
  left: -400px;
  width: 150vw;
  height: 150vh;
  background-image: url(/static/media/popupbk.5aa64556.jpg);
  -webkit-filter: blur(13px) grayscale(20%);
          filter: blur(13px) grayscale(20%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: -1;
}

@media screen and (max-width: 600px) {
  .schedulePopupContainer::before {
    left: -200px;
  }
  .schedulePopupContainer {
    margin: 0 auto;
    /* margin-left: 50%; */
  }
}

@media screen and (max-width: 400px) {
  .schedulePopupContainer::before {
    left: -100px;
  }
  .schedulePopupContainer {
    align-items: center;
    margin-left: 20%;
  }
}
.schedulePopupTitle {
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 4px;
}
.schedulePopupOptionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: light;
}

.filter-popup {
  /* display: flex; */
  padding-left: 10%;
  padding-right: 10%;
  width: 60rem;
  /* align-items: center; */
}
.schedulePopupButton {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 32px auto 0 auto;
  /* margin-top: 2%; */
  background: transparent;
  color: white;
  text-align: center !important;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px 32px;
  border: 0.0625rem solid var(--teritary);
  border-radius: 4px;
}
.schedulePopupButton:hover {
  background: var(--teritary);
  border-color: var(--teritary);
  transition: 0.3s;
}
.schedulePopupButton:focus {
  outline: none;
}
.close-container-mobile {
  display: flex;
  flex-direction: row-reverse;
}
.close-container-mobile .close-button {
  fill: white;
  position: fixed;
  width: 2rem;
  height: auto;
  cursor: pointer;

  transition: 100ms ease-in-out;
}
.close-container-mobile .close-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  fill: var(--teritary);
}

@media screen and (max-width: 850px) {
  .schedulePopupContainer {
    width: 30rem;
    margin-left: -9rem;
    align-items: center;
  }

  .schedulePopupOptionsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* @media screen and (max-width:600px){
   
.filter-popup {
   display: flex;
   padding-left: 10%;
   padding-right: 10%;
   width: 60rem;
   
}
} */
@media screen and (max-width: 700px) {
  .schedulePopupContainer {
    margin: 0 auto;
  }
}
@media screen and (max-width: 540px) {
  .schedulePopupContainer {
    max-height: 80vh;
    margin-top: -60px;
    padding: 0px;
    padding-bottom: 20px;
    padding-right: 5px;
    width: 20rem;
    /* margin-left: -1.5rem; */
  }

  .schedule-popup-content {
    width: 260px;
    max-height: 60vh;
    overflow: scroll;
    padding: 30px;
    overflow-x: hidden;
    /* text-align: center; */
  }

  .schedulePopupTitle {
    margin-top: 10px;
    max-height: 80vh;
  }
  .schedulePopupButton {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px 15px;
  }

  .schedulePopupOptionsContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .close-container-mobile {
    padding-top: -20px;
    padding-right: 15px;
  }

  .close-container-mobile .close-button {
    width: 2rem;
  }
}

.schedule {
  z-index: 100;
  padding-bottom: 100px;
  overflow: hidden;
}
.scheduleContainer {
  max-width: 75%;
  min-width: 280px;
  margin: 0 auto;
}
.scheduleFilter {
  color: white;
  display: flex;
  pointer-events: none;
}
.scheduleFilterSensitive {
  display: flex;
  align-items: center;
  padding: 12px 6px;
  font-weight: bold;
  cursor: pointer;
  pointer-events: initial;
}
.scheduleFilterSensitive:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: rgb(185, 185, 185);
}
.scheduleFilterInsensitive {
  display: flex;
  flex-grow: 1;
  cursor: default;
}
.scheduleDayContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 0.5rem;
}
.scheduleDayContainer div {
  text-transform: uppercase;
  font-weight: 700;
}
.scheduleDay {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px 0;
  transition: all 0.2s ease;
  color: white;
  z-index: 1;
  border: 2px solid #fff;
  background: #1d1b27;
  border-radius: 20px 2px;
}

.scheduleDay:hover {
  opacity: 1;
  transition-duration: 0.2s;
  color: white;
  background-color: #100f16;
  box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
}

.scheduleSelectedDay {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background: #b061ff;

  color: white;
  z-index: 2;
  cursor: default;
  border-radius: 20px 2px;
}
.sheduledata__container {
  background: #2f1c479d;
  border-radius: 20px;
}
.sheduledata__container2 {
  padding: 2rem;
}

@media only screen and (max-width: 980px) {
  .scheduleContainer {
    max-width: 90%;
  }
  .scheduleDayContainer div {
    -webkit-font-feature-settings: "pcap";
            font-feature-settings: "pcap";
    font-variant-caps: petite-caps;
  }
  .scheduleDay,
  .scheduleSelectedDay {
    display: flex;
    padding: 8px 0;
    font-size: 0.7rem;
    transition: all 0.2s ease;
    color: white;
    z-index: 1;
    border: 2px solid #fff;
  }

  .sheduledata__container2 {
    padding: 0;
    padding-left: 0.5rem;
  }
}

.contact-card {
   margin: 0 10px;
   color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #b680e23b;
   border-radius: 20px;
   margin-bottom: 4%;
   /* box-shadow: 0px 5px 25px 5px rgb(201, 200, 200) */
}
.contact-card:hover {
   -webkit-transform: translateY(-15px);
           transform: translateY(-15px);
   transition: 0.5s 0.1s;
}
.contact-card:hover .contact-card-img {
   -webkit-transform: translateY(-20px);
           transform: translateY(-20px);
   transition: 0.5s 0.1s;
}
.contact-card-img {
   padding: 1.5rem;
   padding-bottom: 0.5rem;
   margin-top: -4rem;
}
.contact-img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.contact-card-img img {
   max-width: 16.5rem;
   width: 245px;
   height: 350px;
   border-radius: 0.55rem !important;
   box-shadow: 0px 5px 30px 0px #0000004d;
}
.contact-card-body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 1.5rem !important;
   padding-top: 0.5rem !important;
}
.contact-name {
   margin-top: 0;
   margin-bottom: 6px;
   font-size: 1.5rem;
   font-weight: 600;
   color: rgb(228, 227, 227);
}
.contact-design {
   font-size: 0.9rem;
   color: rgb(172, 172, 172);
}
.contact-card-links {
   width: 100%;
   height: 25px;

   margin: 1rem 0;
   display: flex;
   justify-content: center;
   align-items: center;
   list-style: none;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
   border-radius: 0.55rem;
   /* background-color: white;
   opacity: 0.8; */
}
.contact-card-links li {
   padding: 0 10px;
   padding: 4%;
}

.contact-card-links li .uil {
   font-size: 1.5rem;
}
.contact-card-links li a img:hover {
   border-radius: 20%;
   -webkit-transform: scale(1.3);
           transform: scale(1.3);
   border: 1px solid #b57bee;
}
@media screen and (max-width: 1500px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 14.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
   .contact-card-links {
      padding: 0.5rem !important;
      /* margin-left: 1rem;
    margin-bottom: 1rem; */
   }
}
@media screen and (max-width: 1390px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 16.75rem;
   }
   .contact-card-body {
      padding: 0.5rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 1.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
   .contact-card-links {
      width: 95%;
   }
}
@media screen and (max-width: 1200px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 14.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
}
@media screen and (max-width: 1048px) {
   .contact-card {
      flex-wrap: wrap;
   }
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 16.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
}

.contacts {
   position: relative;
   z-index: 0;
   overflow-x: hidden;
   background: transparent;

   position: relative;
   z-index: 101;
   display: grid;
   grid-gap: 2.5rem;
   gap: 2.5rem;
   max-width: 100%;
   margin: 0px auto;
   padding: 1.5rem;
}
h1 {
   position: relative;
}
.container {
   display: grid;
   grid-template-columns: auto auto auto auto;
   grid-row-gap: 70px;
   grid-column-gap: 5px;
   z-index: 2;
   margin: 0 170px;
   margin-top: 4rem;
   padding-bottom: 3rem;
   justify-items: center;
}
@media screen and (max-width: 1700px) {
   .container {
      grid-template-columns: auto auto auto auto;
      margin-left: 100px;
      margin-right: 100px;
      margin-bottom: 5vh;
   }
}
@media screen and (max-width: 1550px) {
   .container {
      grid-template-columns: auto auto auto auto;
      margin-left: 0px;
      margin-right: 50px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1390px) {
   .container {
      grid-template-columns: auto auto auto;
      margin-left: 0px;
      margin-right: 100px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1200px) {
   .container {
      grid-template-columns: auto auto auto;
      margin-left: 0px;
      margin-right: 50px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1048px) {
   .container {
      grid-template-columns: auto auto;
      margin-left: 0px;
      margin-right: 120px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 800px) and (max-height: 1300px) {
   .container {
      grid-template-columns: auto;
      margin-left: 0px;
      margin-right: 0px;
   }
}
@media screen and (max-width: 580px) {
   .container {
      grid-template-columns: auto;
      margin-left: 0px;
      margin-right: 0px;
   }

   .page-title {
      color: var(--heading);
      font-size: 63px;
      font-weight: 850;
      font-family: "Raleway", sans-serif;
      text-align: center;
      margin-top: 12% !important;
   }
}

.purecss-fade-in-section {
   opacity: 0;
   -webkit-transform: translateY(3vh);
           transform: translateY(3vh);
   visibility: hidden;
   transition: opacity 1s ease-in, -webkit-transform 1s ease-out;
   transition: opacity 1s ease-in, transform 1s ease-out;
   transition: opacity 1s ease-in, transform 1s ease-out, -webkit-transform 1s ease-out;
   will-change: opacity, visibility;
}
.purecss-fade-in-section.is-visible {
   opacity: 1;
   -webkit-transform: none;
           transform: none;
   visibility: visible;
}
.purecss-zoom-in-section {
   opacity: 0;
   -webkit-transform: scale(0);
           transform: scale(0);
   visibility: hidden;
   transition: opacity 0.9s ease-out, -webkit-transform 1.2s ease-out;
   transition: opacity 0.9s ease-out, transform 1.2s ease-out;
   transition: opacity 0.9s ease-out, transform 1.2s ease-out, -webkit-transform 1.2s ease-out;
   will-change: opacity, visibility;
}
.purecss-zoom-in-section.is-visible {
   opacity: 1;
   -webkit-transform: scale(1);
           transform: scale(1);
   visibility: visible;
}

html {
   scroll-behavior: smooth !important;
}
.app-container {
   height: 100%;
   width: 100%;
   font-family: "Open Sans", sans-serif;
}
.app-container .mobile-profile-icon {
   display: none;
   z-index: 103;
}

.nav-shift {
   margin-left: 110px;
   height: auto;
   min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
   width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
   box-shadow: none;
   border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background: #211f2e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #801dc2;
}
#root {
   overflow-x: hidden;
   width: 100vw;
   position: relative;
}
.backdrop-filter-blur {
   border-radius: 20px;
   background: rgba(217, 177, 250, 0.233);
   position: relative;
}
/* support backdrop-filter */
@supports ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   .backdrop-filter-blur::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      -webkit-backdrop-filter: blur(10.5px);
      backdrop-filter: blur(10.5px);
      z-index: -1;
      border-radius: 20px;
   }
}
/* no support backdrop-filter */
@supports not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   .backdrop-filter-blur {
      background: rgba(189, 185, 243, 0.281);
      box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
   }
}

/* ---------------------------- Responsiveness ----------------------------*/

/* MOBILE */
@media (min-width: 0px) and (max-width: 599px) {
   .app-container .mobile-profile-icon {
      display: block;
      position: absolute;
      top: 2.5rem;
      right: 1rem;
      height: 40px;
      width: 40px;
      z-index: 10;
   }
   .app-container .mobile-profile-icon .navbarProfile {
      padding: 0;
   }
   .nav-shift {
      margin-left: 0px;
   }
}

/* TABLET */
@media (min-width: 600px) and (max-width: 1023) {
}

/* Page transition */

.home-body,
.events-body,
.schedule-body,
.contacts-body,
.competitions-body {
   transition: opacity 100ms ease-in-out;
}

/* .fade-enter {
  opacity: 0;
  transform: translateX(-100%);
  z-index: 10;
} */

.fade-enter.fade-enter-active {
   background: #6c10aa;
   /* opacity: 1; */
   -webkit-transform: translateX(-10%);
           transform: translateX(-10%);
   margin-bottom: 30% !important;
   -webkit-animation-name: swipe;
           animation-name: swipe;
   -webkit-animation-duration: 1000ms;
           animation-duration: 1000ms;
   height: 100vh;
   -webkit-animation-timing-function: cubic-bezier(0.46, 0.96, 0.6, 0.07);
           animation-timing-function: cubic-bezier(0.46, 0.96, 0.6, 0.07);
   z-index: 10;
}

.fade-enter.fade-enter-active .home-body,
.fade-exit.fade-exit-active .home-body,
.fade-enter.fade-enter-active .events-body,
.fade-exit.fade-exit-active .events-body,
.fade-enter.fade-enter-active .schedule-body,
.fade-exit.fade-exit-active .schedule-body,
.fade-enter.fade-enter-active .contacts-body,
.fade-exit.fade-exit-active .contacts-body,
.fade-enter.fade-enter-active .competitions-body,
.fade-exit.fade-exit-active .competitions-body {
   opacity: 0 !important;
}

@-webkit-keyframes swipe {
   0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
   }
   100% {
      /* opacity: 1; */
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
   }
}

@keyframes swipe {
   0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
   }
   100% {
      /* opacity: 1; */
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
   }
}

:root {
   --background-color: #100f16;
   --heading: white;
   --description-color: #cfcdcf;
   --subtitle-color: #9d45f5;
   --primary: "";
   --secondary: "";
   --teritary: #b061ff;
}

body {
   background-color: var(--background-color);
}
select {
   -webkit-appearance: none;
   -moz-appearance: none;
   text-indent: 1px;
   text-overflow: "";
}

.page-title {
   color: var(--heading);
   font-size: 65px;
   font-weight: 800;
   font-family: "Raleway", sans-serif;
   text-align: center;
   margin-top: 24px;
   margin-bottom: 9px;
}
select {
   padding-right: 15px !important;
}
.event-page-title {
   margin-top: 10px;
}

@media only screen and (max-width: 480px) {
   .page-title {
      font-size: 47px;
   }
}
@media only screen and (max-width: 601px) {
   .eventsContainer {
      margin-top: 30px;
      top: -61px;
   }
   .page-title {
      margin-top: 18% !important;
   }
}

@media (min-width: 1800px) and (max-width: 2070px) {
   .page-title {
      font-size: 57px;
   }
}
/* STAR BACKGROUND */
#stars {
   width: 1px;
   height: 1px;
   background: transparent;
   box-shadow: 331px 1458px #adb5bd, 678px 31px #adb5bd, 1726px 1570px #adb5bd, 351px 38px #adb5bd, 219px 1167px #adb5bd, 1488px 217px #adb5bd, 1725px 96px #adb5bd, 1014px 1763px #adb5bd, 300px 1023px #adb5bd, 1497px 1659px #adb5bd, 1528px 1786px #adb5bd, 353px 518px #adb5bd, 1022px 297px #adb5bd,
      1579px 1746px #adb5bd, 28px 1989px #adb5bd, 1469px 1563px #adb5bd, 1703px 720px #adb5bd, 338px 1361px #adb5bd, 1543px 1997px #adb5bd, 785px 810px #adb5bd, 584px 87px #adb5bd, 1599px 1719px #adb5bd, 605px 1383px #adb5bd, 182px 1195px #adb5bd, 1688px 103px #adb5bd, 316px 1657px #adb5bd,
      1696px 383px #adb5bd, 1919px 1376px #adb5bd, 1817px 176px #adb5bd, 1089px 812px #adb5bd, 620px 276px #adb5bd, 744px 1999px #adb5bd, 1568px 768px #adb5bd, 1961px 1672px #adb5bd, 785px 633px #adb5bd, 1546px 999px #adb5bd, 1145px 29px #adb5bd, 469px 1208px #adb5bd, 691px 1737px #adb5bd,
      695px 1244px #adb5bd, 1920px 923px #adb5bd, 487px 118px #adb5bd, 738px 743px #adb5bd, 508px 1742px #adb5bd, 499px 1333px #adb5bd, 663px 1328px #adb5bd, 1058px 1030px #adb5bd, 314px 912px #adb5bd, 555px 1174px #adb5bd, 709px 1190px #adb5bd, 1216px 375px #adb5bd, 1107px 400px #adb5bd,
      956px 569px #adb5bd, 862px 187px #adb5bd, 761px 119px #adb5bd, 601px 986px #adb5bd, 1288px 1367px #adb5bd, 597px 1146px #adb5bd, 564px 592px #adb5bd, 1372px 1052px #adb5bd, 952px 363px #adb5bd, 983px 822px #adb5bd, 1731px 517px #adb5bd, 482px 105px #adb5bd, 1309px 1639px #adb5bd,
      1259px 1358px #adb5bd, 337px 731px #adb5bd, 395px 820px #adb5bd, 225px 647px #adb5bd, 1141px 565px #adb5bd, 1224px 533px #adb5bd, 1526px 90px #adb5bd, 1154px 106px #adb5bd, 970px 729px #adb5bd, 293px 1660px #adb5bd, 1195px 734px #adb5bd, 569px 24px #adb5bd, 779px 576px #adb5bd,
      480px 110px #adb5bd, 895px 1787px #adb5bd, 139px 1702px #adb5bd, 1139px 1618px #adb5bd, 1218px 630px #adb5bd, 811px 1229px #adb5bd, 581px 1628px #adb5bd, 868px 683px #adb5bd, 1012px 1358px #adb5bd, 1408px 1326px #adb5bd, 1928px 908px #adb5bd, 221px 221px #adb5bd, 351px 1725px #adb5bd,
      514px 876px #adb5bd, 736px 1083px #adb5bd, 918px 236px #adb5bd, 1062px 1313px #adb5bd, 177px 1289px #adb5bd, 329px 1326px #adb5bd, 1143px 1354px #adb5bd, 1872px 1236px #adb5bd, 1000px 389px #adb5bd, 1685px 568px #adb5bd, 49px 1996px #adb5bd, 1552px 1773px #adb5bd, 1873px 971px #adb5bd,
      1643px 1222px #adb5bd, 1455px 1620px #adb5bd, 1246px 1160px #adb5bd, 743px 1981px #adb5bd, 1340px 1747px #adb5bd, 274px 273px #adb5bd, 528px 68px #adb5bd, 461px 1585px #adb5bd, 728px 1323px #adb5bd, 1035px 1036px #adb5bd, 1536px 978px #adb5bd, 424px 34px #adb5bd, 1466px 958px #adb5bd,
      930px 257px #adb5bd, 1803px 948px #adb5bd, 1750px 1480px #adb5bd, 1008px 488px #adb5bd, 1334px 114px #adb5bd, 1344px 643px #adb5bd, 945px 618px #adb5bd, 1698px 1569px #adb5bd, 399px 1215px #adb5bd, 492px 537px #adb5bd, 806px 875px #adb5bd, 1776px 695px #adb5bd, 728px 1232px #adb5bd,
      714px 118px #adb5bd, 461px 1765px #adb5bd, 1868px 1460px #adb5bd, 1307px 17px #adb5bd, 395px 1759px #adb5bd, 1620px 1818px #adb5bd, 906px 218px #adb5bd, 1398px 181px #adb5bd, 826px 1502px #adb5bd, 1932px 1963px #adb5bd, 388px 292px #adb5bd, 1580px 415px #adb5bd, 436px 1084px #adb5bd,
      305px 1115px #adb5bd, 973px 1252px #adb5bd, 626px 1135px #adb5bd, 974px 1121px #adb5bd, 1632px 215px #adb5bd, 1670px 627px #adb5bd, 551px 448px #adb5bd, 1758px 1674px #adb5bd, 1825px 1939px #adb5bd, 993px 1176px #adb5bd, 1329px 404px #adb5bd, 1818px 1833px #adb5bd, 329px 257px #adb5bd,
      781px 1142px #adb5bd, 25px 1164px #adb5bd, 644px 863px #adb5bd, 1638px 508px #adb5bd, 107px 1949px #adb5bd, 329px 1192px #adb5bd, 481px 70px #adb5bd, 860px 1123px #adb5bd, 714px 1509px #adb5bd, 798px 29px #adb5bd, 1329px 1528px #adb5bd, 533px 81px #adb5bd, 791px 1345px #adb5bd,
      930px 269px #adb5bd, 54px 967px #adb5bd, 309px 460px #adb5bd, 1405px 1979px #adb5bd, 1548px 1344px #adb5bd, 1249px 945px #adb5bd, 222px 1121px #adb5bd, 726px 263px #adb5bd, 1830px 433px #adb5bd, 1772px 1049px #adb5bd, 1883px 868px #adb5bd, 1173px 844px #adb5bd, 1587px 1879px #adb5bd,
      564px 692px #adb5bd, 1635px 1495px #adb5bd, 811px 424px #adb5bd, 1902px 1279px #adb5bd, 202px 1429px #adb5bd, 154px 19px #adb5bd, 1629px 982px #adb5bd, 28px 1087px #adb5bd, 68px 1940px #adb5bd, 363px 129px #adb5bd, 95px 329px #adb5bd, 1758px 95px #adb5bd, 726px 1546px #adb5bd,
      1812px 1643px #adb5bd, 1880px 193px #adb5bd, 1145px 251px #adb5bd, 427px 597px #adb5bd, 1632px 810px #adb5bd, 1206px 15px #adb5bd, 1986px 1382px #adb5bd, 1233px 591px #adb5bd, 549px 1280px #adb5bd, 1382px 1638px #adb5bd, 1447px 500px #adb5bd, 642px 359px #adb5bd, 1933px 809px #adb5bd,
      978px 41px #adb5bd, 515px 1024px #adb5bd, 927px 1349px #adb5bd, 1121px 137px #adb5bd, 1406px 1247px #adb5bd, 217px 1662px #adb5bd, 1947px 85px #adb5bd, 1939px 1548px #adb5bd, 560px 931px #adb5bd, 1139px 343px #adb5bd, 1326px 63px #adb5bd, 1689px 1349px #adb5bd, 1223px 1202px #adb5bd,
      796px 762px #adb5bd, 802px 488px #adb5bd, 832px 519px #adb5bd, 1808px 361px #adb5bd, 515px 1991px #adb5bd, 587px 117px #adb5bd, 1040px 1231px #adb5bd, 1944px 15px #adb5bd, 235px 1826px #adb5bd, 1474px 1172px #adb5bd, 952px 1131px #adb5bd, 1122px 43px #adb5bd, 1305px 246px #adb5bd,
      389px 1811px #adb5bd, 1783px 516px #adb5bd, 1901px 534px #adb5bd, 926px 57px #adb5bd, 1029px 1924px #adb5bd, 1835px 1616px #adb5bd, 1234px 356px #adb5bd, 140px 1176px #adb5bd, 1518px 1312px #adb5bd, 1298px 556px #adb5bd, 916px 1106px #adb5bd, 1397px 1374px #adb5bd, 350px 1444px #adb5bd,
      737px 88px #adb5bd, 1695px 1671px #adb5bd, 432px 1784px #adb5bd, 25px 1911px #adb5bd, 1156px 8px #adb5bd, 401px 620px #adb5bd, 301px 1816px #adb5bd, 526px 1536px #adb5bd, 8px 1706px #adb5bd, 416px 1472px #adb5bd, 1196px 888px #adb5bd, 1110px 877px #adb5bd, 896px 210px #adb5bd,
      840px 595px #adb5bd, 1889px 1096px #adb5bd, 1486px 1568px #adb5bd, 148px 1700px #adb5bd, 1839px 911px #adb5bd, 377px 563px #adb5bd, 890px 1103px #adb5bd, 126px 645px #adb5bd, 1506px 1748px #adb5bd, 1732px 1231px #adb5bd, 1618px 98px #adb5bd, 981px 453px #adb5bd, 1113px 1475px #adb5bd,
      477px 1518px #adb5bd, 1113px 1771px #adb5bd, 73px 334px #adb5bd, 1464px 1368px #adb5bd, 11px 666px #adb5bd, 351px 1328px #adb5bd, 1875px 96px #adb5bd, 1867px 1449px #adb5bd, 272px 171px #adb5bd, 318px 1465px #adb5bd, 1109px 1161px #adb5bd, 273px 1098px #adb5bd, 1968px 848px #adb5bd,
      955px 474px #adb5bd, 1892px 1501px #adb5bd, 432px 107px #adb5bd, 1246px 221px #adb5bd, 945px 1656px #adb5bd, 1450px 819px #adb5bd, 594px 452px #adb5bd, 441px 621px #adb5bd, 594px 1399px #adb5bd, 843px 997px #adb5bd, 823px 885px #adb5bd, 30px 4px #adb5bd, 832px 468px #adb5bd,
      1681px 986px #adb5bd, 1196px 274px #adb5bd, 1593px 328px #adb5bd, 775px 28px #adb5bd, 71px 1706px #adb5bd, 971px 691px #adb5bd, 175px 695px #adb5bd, 145px 321px #adb5bd, 1055px 1059px #adb5bd, 403px 233px #adb5bd, 865px 456px #adb5bd, 950px 1826px #adb5bd, 59px 84px #adb5bd, 740px 68px #adb5bd,
      1744px 1949px #adb5bd, 373px 1286px #adb5bd, 1955px 1607px #adb5bd, 1946px 1606px #adb5bd, 804px 471px #adb5bd, 960px 949px #adb5bd, 1675px 1027px #adb5bd, 97px 1019px #adb5bd, 1594px 894px #adb5bd, 1267px 1736px #adb5bd, 1768px 1974px #adb5bd, 1710px 531px #adb5bd, 1961px 1946px #adb5bd,
      464px 144px #adb5bd, 1354px 1021px #adb5bd, 1485px 681px #adb5bd, 612px 1973px #adb5bd, 305px 1409px #adb5bd, 513px 739px #adb5bd, 234px 813px #adb5bd, 538px 1610px #adb5bd, 404px 508px #adb5bd, 1516px 745px #adb5bd, 436px 1408px #adb5bd, 1917px 1434px #adb5bd, 1488px 1989px #adb5bd,
      1118px 610px #adb5bd, 126px 512px #adb5bd, 1988px 1102px #adb5bd, 1785px 1538px #adb5bd, 1618px 348px #adb5bd, 542px 595px #adb5bd, 48px 1691px #adb5bd, 155px 70px #adb5bd, 358px 1460px #adb5bd, 1796px 332px #adb5bd, 700px 431px #adb5bd, 914px 856px #adb5bd, 500px 1294px #adb5bd,
      1983px 38px #adb5bd, 1655px 1034px #adb5bd, 998px 277px #adb5bd, 742px 1946px #adb5bd, 1022px 862px #adb5bd, 853px 782px #adb5bd, 657px 113px #adb5bd, 170px 1576px #adb5bd, 1163px 1794px #adb5bd, 169px 1837px #adb5bd, 888px 633px #adb5bd, 1717px 647px #adb5bd, 1345px 432px #adb5bd,
      12px 620px #adb5bd, 1599px 891px #adb5bd, 875px 589px #adb5bd, 771px 1596px #adb5bd, 1984px 1445px #adb5bd, 1810px 576px #adb5bd, 630px 396px #adb5bd, 765px 1591px #adb5bd, 364px 1773px #adb5bd, 520px 1984px #adb5bd, 298px 218px #adb5bd, 790px 1846px #adb5bd, 58px 1525px #adb5bd,
      44px 382px #adb5bd, 831px 655px #adb5bd, 376px 1919px #adb5bd, 691px 765px #adb5bd, 1416px 1121px #adb5bd, 284px 1184px #adb5bd, 1123px 765px #adb5bd, 1835px 238px #adb5bd, 1889px 1262px #adb5bd, 1516px 546px #adb5bd, 1989px 1190px #adb5bd, 663px 1291px #adb5bd, 1338px 1759px #adb5bd,
      428px 1715px #adb5bd, 1296px 874px #adb5bd, 984px 929px #adb5bd, 154px 1062px #adb5bd, 1891px 1307px #adb5bd, 93px 378px #adb5bd, 292px 1013px #adb5bd, 808px 238px #adb5bd, 1888px 833px #adb5bd, 634px 629px #adb5bd, 1862px 598px #adb5bd, 239px 85px #adb5bd, 1449px 473px #adb5bd,
      1633px 1867px #adb5bd, 13px 356px #adb5bd, 1569px 961px #adb5bd, 1906px 249px #adb5bd, 973px 866px #adb5bd, 70px 801px #adb5bd, 1202px 1392px #adb5bd, 1446px 1141px #adb5bd, 1938px 663px #adb5bd, 1179px 138px #adb5bd, 573px 1507px #adb5bd, 1693px 595px #adb5bd, 661px 930px #adb5bd,
      74px 575px #adb5bd, 369px 1966px #adb5bd, 1591px 457px #adb5bd, 1858px 252px #adb5bd, 1528px 12px #adb5bd, 67px 872px #adb5bd, 1381px 875px #adb5bd, 445px 465px #adb5bd, 657px 223px #adb5bd, 171px 1194px #adb5bd, 1140px 1571px #adb5bd, 1095px 1282px #adb5bd, 314px 1681px #adb5bd,
      69px 1650px #adb5bd, 99px 1468px #adb5bd, 1540px 1826px #adb5bd, 155px 1291px #adb5bd, 1985px 417px #adb5bd, 266px 62px #adb5bd, 802px 1209px #adb5bd, 662px 1203px #adb5bd, 251px 1267px #adb5bd, 1117px 1127px #adb5bd, 1328px 725px #adb5bd, 17px 1887px #adb5bd, 1528px 270px #adb5bd,
      1802px 1483px #adb5bd, 979px 1003px #adb5bd, 1355px 1299px #adb5bd, 1152px 1335px #adb5bd, 467px 29px #adb5bd, 393px 1210px #adb5bd, 341px 179px #adb5bd, 501px 1043px #adb5bd, 758px 801px #adb5bd, 482px 1225px #adb5bd, 564px 305px #adb5bd, 1401px 1015px #adb5bd, 437px 1044px #adb5bd,
      1889px 64px #adb5bd, 1238px 1121px #adb5bd, 1000px 1805px #adb5bd, 578px 897px #adb5bd, 1387px 1859px #adb5bd, 427px 1422px #adb5bd, 427px 771px #adb5bd, 1234px 1183px #adb5bd, 508px 625px #adb5bd, 490px 1676px #adb5bd, 906px 1327px #adb5bd, 1202px 1050px #adb5bd, 1350px 1600px #adb5bd,
      783px 1671px #adb5bd, 147px 1170px #adb5bd, 1038px 867px #adb5bd, 832px 104px #adb5bd, 34px 1194px #adb5bd, 392px 643px #adb5bd, 1598px 417px #adb5bd, 1254px 1036px #adb5bd, 1111px 1748px #adb5bd, 1503px 1698px #adb5bd, 80px 1459px #adb5bd, 1747px 1905px #adb5bd, 1646px 1958px #adb5bd,
      1196px 1797px #adb5bd, 312px 539px #adb5bd, 1139px 1576px #adb5bd, 910px 691px #adb5bd, 1103px 1073px #adb5bd, 710px 735px #adb5bd, 1636px 244px #adb5bd, 1486px 518px #adb5bd, 1230px 656px #adb5bd, 1058px 1198px #adb5bd, 1591px 1456px #adb5bd, 1715px 1274px #adb5bd, 1617px 180px #adb5bd,
      484px 1337px #adb5bd, 816px 880px #adb5bd, 774px 1172px #adb5bd, 809px 1503px #adb5bd, 1478px 1683px #adb5bd, 195px 347px #adb5bd, 1916px 1403px #adb5bd, 400px 1608px #adb5bd, 1632px 40px #adb5bd, 1045px 444px #adb5bd, 478px 499px #adb5bd, 444px 1000px #adb5bd, 864px 1496px #adb5bd,
      1321px 1979px #adb5bd, 494px 655px #adb5bd, 1509px 1711px #adb5bd, 1366px 1243px #adb5bd, 649px 710px #adb5bd, 1079px 1965px #adb5bd, 839px 1132px #adb5bd, 1899px 1397px #adb5bd, 950px 1408px #adb5bd, 1980px 480px #adb5bd, 1809px 1138px #adb5bd, 1214px 1748px #adb5bd, 1264px 734px #adb5bd,
      850px 1504px #adb5bd, 483px 222px #adb5bd, 826px 1614px #adb5bd, 352px 724px #adb5bd, 1735px 668px #adb5bd, 1235px 283px #adb5bd, 1932px 622px #adb5bd, 483px 326px #adb5bd, 776px 694px #adb5bd, 994px 1334px #adb5bd, 274px 576px #adb5bd, 249px 812px #adb5bd, 1677px 1979px #adb5bd,
      395px 38px #adb5bd, 237px 78px #adb5bd, 1623px 1121px #adb5bd, 1992px 585px #adb5bd, 367px 1678px #adb5bd, 1247px 1882px #adb5bd, 1734px 289px #adb5bd, 1058px 863px #adb5bd, 82px 118px #adb5bd, 1687px 1857px #adb5bd, 513px 407px #adb5bd, 76px 1460px #adb5bd, 119px 7px #adb5bd,
      1836px 1969px #adb5bd, 1809px 1527px #adb5bd, 1792px 1763px #adb5bd, 1858px 1491px #adb5bd, 236px 1284px #adb5bd, 1222px 270px #adb5bd, 1396px 1921px #adb5bd, 1839px 59px #adb5bd, 1283px 277px #adb5bd, 1219px 1705px #adb5bd, 583px 504px #adb5bd, 62px 1456px #adb5bd, 1530px 1801px #adb5bd,
      1318px 1463px #adb5bd, 268px 1636px #adb5bd, 178px 1259px #adb5bd, 1110px 245px #adb5bd, 113px 103px #adb5bd, 1961px 691px #adb5bd, 123px 565px #adb5bd, 474px 112px #adb5bd, 1315px 195px #adb5bd, 682px 1991px #adb5bd, 1925px 574px #adb5bd, 1549px 1587px #adb5bd, 401px 1906px #adb5bd,
      1301px 221px #adb5bd, 1224px 482px #adb5bd, 831px 242px #adb5bd, 1726px 753px #adb5bd, 1088px 1842px #adb5bd, 165px 1950px #adb5bd, 1176px 312px #adb5bd, 1456px 921px #adb5bd, 1519px 1173px #adb5bd, 1337px 293px #adb5bd, 1541px 1473px #adb5bd, 1924px 166px #adb5bd, 298px 779px #adb5bd,
      1896px 1913px #adb5bd, 797px 334px #adb5bd, 182px 282px #adb5bd, 1635px 1459px #adb5bd, 765px 732px #adb5bd, 72px 777px #adb5bd, 1676px 1831px #adb5bd, 541px 1442px #adb5bd, 602px 1773px #adb5bd, 953px 1013px #adb5bd, 1520px 829px #adb5bd, 70px 95px #adb5bd, 840px 888px #adb5bd,
      625px 1675px #adb5bd, 1772px 65px #adb5bd, 1739px 1160px #adb5bd, 422px 937px #adb5bd, 1790px 1982px #adb5bd, 271px 1695px #adb5bd, 1018px 793px #adb5bd, 1088px 1124px #adb5bd, 1811px 865px #adb5bd, 1556px 76px #adb5bd, 390px 634px #adb5bd, 1163px 230px #adb5bd, 665px 1023px #adb5bd,
      1820px 787px #adb5bd, 262px 17px #adb5bd, 1602px 346px #adb5bd, 320px 555px #adb5bd, 266px 1485px #adb5bd, 182px 1555px #adb5bd, 1865px 1881px #adb5bd, 348px 317px #adb5bd, 370px 1747px #adb5bd, 1081px 661px #adb5bd, 1315px 243px #adb5bd, 1536px 1400px #adb5bd, 199px 1174px #adb5bd,
      1421px 1795px #adb5bd, 247px 840px #adb5bd, 356px 882px #adb5bd, 1296px 650px #adb5bd, 513px 1597px #adb5bd, 1094px 1784px #adb5bd, 358px 550px #adb5bd, 1238px 101px #adb5bd, 1985px 1469px #adb5bd, 1444px 1295px #adb5bd, 509px 131px #adb5bd, 119px 1297px #adb5bd, 848px 588px #adb5bd,
      1962px 1464px #adb5bd, 1561px 737px #adb5bd, 1061px 357px #adb5bd, 105px 617px #adb5bd, 1687px 755px #adb5bd, 822px 787px #adb5bd, 1318px 478px #adb5bd, 1678px 1068px #adb5bd, 1871px 816px #adb5bd, 1202px 939px #adb5bd, 187px 1264px #adb5bd, 1909px 861px #adb5bd, 109px 1019px #adb5bd,
      1165px 235px #adb5bd, 923px 1404px #adb5bd, 153px 450px #adb5bd, 1707px 1187px #adb5bd, 1246px 479px #adb5bd, 77px 1232px #adb5bd, 1236px 1125px #adb5bd, 1277px 1873px #adb5bd, 1418px 148px #adb5bd, 284px 930px #adb5bd, 942px 1585px #adb5bd, 1813px 854px #adb5bd, 1822px 875px #adb5bd,
      1190px 1091px #adb5bd, 1909px 1713px #adb5bd, 1914px 1475px #adb5bd, 746px 521px #adb5bd, 936px 1797px #adb5bd, 1423px 50px #adb5bd, 309px 558px #adb5bd, 659px 401px #adb5bd, 446px 1485px #adb5bd, 780px 1276px #adb5bd, 1333px 1437px #adb5bd, 1160px 760px #adb5bd, 1563px 1908px #adb5bd,
      1903px 1902px #adb5bd, 895px 1829px #adb5bd, 1955px 1947px #adb5bd, 1919px 1136px #adb5bd, 1121px 1938px #adb5bd, 597px 1152px #adb5bd, 989px 1996px #adb5bd, 1545px 1223px #adb5bd, 855px 64px #adb5bd, 1515px 143px #adb5bd, 718px 765px #adb5bd, 1607px 1081px #adb5bd, 1768px 1557px #adb5bd,
      1398px 470px #adb5bd, 1149px 1653px #adb5bd, 98px 1296px #adb5bd, 1074px 1390px #adb5bd, 1572px 623px #adb5bd, 1913px 1621px #adb5bd, 1812px 1585px #adb5bd, 1052px 1806px #adb5bd, 1890px 765px #adb5bd, 753px 1959px #adb5bd;
   -webkit-animation: animStar 80s linear infinite;
           animation: animStar 80s linear infinite;
}
#stars:after {
   content: " ";
   position: absolute;
   top: 2000px;
   width: 1px;
   height: 1px;
   background: transparent;
   box-shadow: 331px 1458px #adb5bd, 678px 31px #adb5bd, 1726px 1570px #adb5bd, 351px 38px #adb5bd, 219px 1167px #adb5bd, 1488px 217px #adb5bd, 1725px 96px #adb5bd, 1014px 1763px #adb5bd, 300px 1023px #adb5bd, 1497px 1659px #adb5bd, 1528px 1786px #adb5bd, 353px 518px #adb5bd, 1022px 297px #adb5bd,
      1579px 1746px #adb5bd, 28px 1989px #adb5bd, 1469px 1563px #adb5bd, 1703px 720px #adb5bd, 338px 1361px #adb5bd, 1543px 1997px #adb5bd, 785px 810px #adb5bd, 584px 87px #adb5bd, 1599px 1719px #adb5bd, 605px 1383px #adb5bd, 182px 1195px #adb5bd, 1688px 103px #adb5bd, 316px 1657px #adb5bd,
      1696px 383px #adb5bd, 1919px 1376px #adb5bd, 1817px 176px #adb5bd, 1089px 812px #adb5bd, 620px 276px #adb5bd, 744px 1999px #adb5bd, 1568px 768px #adb5bd, 1961px 1672px #adb5bd, 785px 633px #adb5bd, 1546px 999px #adb5bd, 1145px 29px #adb5bd, 469px 1208px #adb5bd, 691px 1737px #adb5bd,
      695px 1244px #adb5bd, 1920px 923px #adb5bd, 487px 118px #adb5bd, 738px 743px #adb5bd, 508px 1742px #adb5bd, 499px 1333px #adb5bd, 663px 1328px #adb5bd, 1058px 1030px #adb5bd, 314px 912px #adb5bd, 555px 1174px #adb5bd, 709px 1190px #adb5bd, 1216px 375px #adb5bd, 1107px 400px #adb5bd,
      956px 569px #adb5bd, 862px 187px #adb5bd, 761px 119px #adb5bd, 601px 986px #adb5bd, 1288px 1367px #adb5bd, 597px 1146px #adb5bd, 564px 592px #adb5bd, 1372px 1052px #adb5bd, 952px 363px #adb5bd, 983px 822px #adb5bd, 1731px 517px #adb5bd, 482px 105px #adb5bd, 1309px 1639px #adb5bd,
      1259px 1358px #adb5bd, 337px 731px #adb5bd, 395px 820px #adb5bd, 225px 647px #adb5bd, 1141px 565px #adb5bd, 1224px 533px #adb5bd, 1526px 90px #adb5bd, 1154px 106px #adb5bd, 970px 729px #adb5bd, 293px 1660px #adb5bd, 1195px 734px #adb5bd, 569px 24px #adb5bd, 779px 576px #adb5bd,
      480px 110px #adb5bd, 895px 1787px #adb5bd, 139px 1702px #adb5bd, 1139px 1618px #adb5bd, 1218px 630px #adb5bd, 811px 1229px #adb5bd, 581px 1628px #adb5bd, 868px 683px #adb5bd, 1012px 1358px #adb5bd, 1408px 1326px #adb5bd, 1928px 908px #adb5bd, 221px 221px #adb5bd, 351px 1725px #adb5bd,
      514px 876px #adb5bd, 736px 1083px #adb5bd, 918px 236px #adb5bd, 1062px 1313px #adb5bd, 177px 1289px #adb5bd, 329px 1326px #adb5bd, 1143px 1354px #adb5bd, 1872px 1236px #adb5bd, 1000px 389px #adb5bd, 1685px 568px #adb5bd, 49px 1996px #adb5bd, 1552px 1773px #adb5bd, 1873px 971px #adb5bd,
      1643px 1222px #adb5bd, 1455px 1620px #adb5bd, 1246px 1160px #adb5bd, 743px 1981px #adb5bd, 1340px 1747px #adb5bd, 274px 273px #adb5bd, 528px 68px #adb5bd, 461px 1585px #adb5bd, 728px 1323px #adb5bd, 1035px 1036px #adb5bd, 1536px 978px #adb5bd, 424px 34px #adb5bd, 1466px 958px #adb5bd,
      930px 257px #adb5bd, 1803px 948px #adb5bd, 1750px 1480px #adb5bd, 1008px 488px #adb5bd, 1334px 114px #adb5bd, 1344px 643px #adb5bd, 945px 618px #adb5bd, 1698px 1569px #adb5bd, 399px 1215px #adb5bd, 492px 537px #adb5bd, 806px 875px #adb5bd, 1776px 695px #adb5bd, 728px 1232px #adb5bd,
      714px 118px #adb5bd, 461px 1765px #adb5bd, 1868px 1460px #adb5bd, 1307px 17px #adb5bd, 395px 1759px #adb5bd, 1620px 1818px #adb5bd, 906px 218px #adb5bd, 1398px 181px #adb5bd, 826px 1502px #adb5bd, 1932px 1963px #adb5bd, 388px 292px #adb5bd, 1580px 415px #adb5bd, 436px 1084px #adb5bd,
      305px 1115px #adb5bd, 973px 1252px #adb5bd, 626px 1135px #adb5bd, 974px 1121px #adb5bd, 1632px 215px #adb5bd, 1670px 627px #adb5bd, 551px 448px #adb5bd, 1758px 1674px #adb5bd, 1825px 1939px #adb5bd, 993px 1176px #adb5bd, 1329px 404px #adb5bd, 1818px 1833px #adb5bd, 329px 257px #adb5bd,
      781px 1142px #adb5bd, 25px 1164px #adb5bd, 644px 863px #adb5bd, 1638px 508px #adb5bd, 107px 1949px #adb5bd, 329px 1192px #adb5bd, 481px 70px #adb5bd, 860px 1123px #adb5bd, 714px 1509px #adb5bd, 798px 29px #adb5bd, 1329px 1528px #adb5bd, 533px 81px #adb5bd, 791px 1345px #adb5bd,
      930px 269px #adb5bd, 54px 967px #adb5bd, 309px 460px #adb5bd, 1405px 1979px #adb5bd, 1548px 1344px #adb5bd, 1249px 945px #adb5bd, 222px 1121px #adb5bd, 726px 263px #adb5bd, 1830px 433px #adb5bd, 1772px 1049px #adb5bd, 1883px 868px #adb5bd, 1173px 844px #adb5bd, 1587px 1879px #adb5bd,
      564px 692px #adb5bd, 1635px 1495px #adb5bd, 811px 424px #adb5bd, 1902px 1279px #adb5bd, 202px 1429px #adb5bd, 154px 19px #adb5bd, 1629px 982px #adb5bd, 28px 1087px #adb5bd, 68px 1940px #adb5bd, 363px 129px #adb5bd, 95px 329px #adb5bd, 1758px 95px #adb5bd, 726px 1546px #adb5bd,
      1812px 1643px #adb5bd, 1880px 193px #adb5bd, 1145px 251px #adb5bd, 427px 597px #adb5bd, 1632px 810px #adb5bd, 1206px 15px #adb5bd, 1986px 1382px #adb5bd, 1233px 591px #adb5bd, 549px 1280px #adb5bd, 1382px 1638px #adb5bd, 1447px 500px #adb5bd, 642px 359px #adb5bd, 1933px 809px #adb5bd,
      978px 41px #adb5bd, 515px 1024px #adb5bd, 927px 1349px #adb5bd, 1121px 137px #adb5bd, 1406px 1247px #adb5bd, 217px 1662px #adb5bd, 1947px 85px #adb5bd, 1939px 1548px #adb5bd, 560px 931px #adb5bd, 1139px 343px #adb5bd, 1326px 63px #adb5bd, 1689px 1349px #adb5bd, 1223px 1202px #adb5bd,
      796px 762px #adb5bd, 802px 488px #adb5bd, 832px 519px #adb5bd, 1808px 361px #adb5bd, 515px 1991px #adb5bd, 587px 117px #adb5bd, 1040px 1231px #adb5bd, 1944px 15px #adb5bd, 235px 1826px #adb5bd, 1474px 1172px #adb5bd, 952px 1131px #adb5bd, 1122px 43px #adb5bd, 1305px 246px #adb5bd,
      389px 1811px #adb5bd, 1783px 516px #adb5bd, 1901px 534px #adb5bd, 926px 57px #adb5bd, 1029px 1924px #adb5bd, 1835px 1616px #adb5bd, 1234px 356px #adb5bd, 140px 1176px #adb5bd, 1518px 1312px #adb5bd, 1298px 556px #adb5bd, 916px 1106px #adb5bd, 1397px 1374px #adb5bd, 350px 1444px #adb5bd,
      737px 88px #adb5bd, 1695px 1671px #adb5bd, 432px 1784px #adb5bd, 25px 1911px #adb5bd, 1156px 8px #adb5bd, 401px 620px #adb5bd, 301px 1816px #adb5bd, 526px 1536px #adb5bd, 8px 1706px #adb5bd, 416px 1472px #adb5bd, 1196px 888px #adb5bd, 1110px 877px #adb5bd, 896px 210px #adb5bd,
      840px 595px #adb5bd, 1889px 1096px #adb5bd, 1486px 1568px #adb5bd, 148px 1700px #adb5bd, 1839px 911px #adb5bd, 377px 563px #adb5bd, 890px 1103px #adb5bd, 126px 645px #adb5bd, 1506px 1748px #adb5bd, 1732px 1231px #adb5bd, 1618px 98px #adb5bd, 981px 453px #adb5bd, 1113px 1475px #adb5bd,
      477px 1518px #adb5bd, 1113px 1771px #adb5bd, 73px 334px #adb5bd, 1464px 1368px #adb5bd, 11px 666px #adb5bd, 351px 1328px #adb5bd, 1875px 96px #adb5bd, 1867px 1449px #adb5bd, 272px 171px #adb5bd, 318px 1465px #adb5bd, 1109px 1161px #adb5bd, 273px 1098px #adb5bd, 1968px 848px #adb5bd,
      955px 474px #adb5bd, 1892px 1501px #adb5bd, 432px 107px #adb5bd, 1246px 221px #adb5bd, 945px 1656px #adb5bd, 1450px 819px #adb5bd, 594px 452px #adb5bd, 441px 621px #adb5bd, 594px 1399px #adb5bd, 843px 997px #adb5bd, 823px 885px #adb5bd, 30px 4px #adb5bd, 832px 468px #adb5bd,
      1681px 986px #adb5bd, 1196px 274px #adb5bd, 1593px 328px #adb5bd, 775px 28px #adb5bd, 71px 1706px #adb5bd, 971px 691px #adb5bd, 175px 695px #adb5bd, 145px 321px #adb5bd, 1055px 1059px #adb5bd, 403px 233px #adb5bd, 865px 456px #adb5bd, 950px 1826px #adb5bd, 59px 84px #adb5bd, 740px 68px #adb5bd,
      1744px 1949px #adb5bd, 373px 1286px #adb5bd, 1955px 1607px #adb5bd, 1946px 1606px #adb5bd, 804px 471px #adb5bd, 960px 949px #adb5bd, 1675px 1027px #adb5bd, 97px 1019px #adb5bd, 1594px 894px #adb5bd, 1267px 1736px #adb5bd, 1768px 1974px #adb5bd, 1710px 531px #adb5bd, 1961px 1946px #adb5bd,
      464px 144px #adb5bd, 1354px 1021px #adb5bd, 1485px 681px #adb5bd, 612px 1973px #adb5bd, 305px 1409px #adb5bd, 513px 739px #adb5bd, 234px 813px #adb5bd, 538px 1610px #adb5bd, 404px 508px #adb5bd, 1516px 745px #adb5bd, 436px 1408px #adb5bd, 1917px 1434px #adb5bd, 1488px 1989px #adb5bd,
      1118px 610px #adb5bd, 126px 512px #adb5bd, 1988px 1102px #adb5bd, 1785px 1538px #adb5bd, 1618px 348px #adb5bd, 542px 595px #adb5bd, 48px 1691px #adb5bd, 155px 70px #adb5bd, 358px 1460px #adb5bd, 1796px 332px #adb5bd, 700px 431px #adb5bd, 914px 856px #adb5bd, 500px 1294px #adb5bd,
      1983px 38px #adb5bd, 1655px 1034px #adb5bd, 998px 277px #adb5bd, 742px 1946px #adb5bd, 1022px 862px #adb5bd, 853px 782px #adb5bd, 657px 113px #adb5bd, 170px 1576px #adb5bd, 1163px 1794px #adb5bd, 169px 1837px #adb5bd, 888px 633px #adb5bd, 1717px 647px #adb5bd, 1345px 432px #adb5bd,
      12px 620px #adb5bd, 1599px 891px #adb5bd, 875px 589px #adb5bd, 771px 1596px #adb5bd, 1984px 1445px #adb5bd, 1810px 576px #adb5bd, 630px 396px #adb5bd, 765px 1591px #adb5bd, 364px 1773px #adb5bd, 520px 1984px #adb5bd, 298px 218px #adb5bd, 790px 1846px #adb5bd, 58px 1525px #adb5bd,
      44px 382px #adb5bd, 831px 655px #adb5bd, 376px 1919px #adb5bd, 691px 765px #adb5bd, 1416px 1121px #adb5bd, 284px 1184px #adb5bd, 1123px 765px #adb5bd, 1835px 238px #adb5bd, 1889px 1262px #adb5bd, 1516px 546px #adb5bd, 1989px 1190px #adb5bd, 663px 1291px #adb5bd, 1338px 1759px #adb5bd,
      428px 1715px #adb5bd, 1296px 874px #adb5bd, 984px 929px #adb5bd, 154px 1062px #adb5bd, 1891px 1307px #adb5bd, 93px 378px #adb5bd, 292px 1013px #adb5bd, 808px 238px #adb5bd, 1888px 833px #adb5bd, 634px 629px #adb5bd, 1862px 598px #adb5bd, 239px 85px #adb5bd, 1449px 473px #adb5bd,
      1633px 1867px #adb5bd, 13px 356px #adb5bd, 1569px 961px #adb5bd, 1906px 249px #adb5bd, 973px 866px #adb5bd, 70px 801px #adb5bd, 1202px 1392px #adb5bd, 1446px 1141px #adb5bd, 1938px 663px #adb5bd, 1179px 138px #adb5bd, 573px 1507px #adb5bd, 1693px 595px #adb5bd, 661px 930px #adb5bd,
      74px 575px #adb5bd, 369px 1966px #adb5bd, 1591px 457px #adb5bd;
}

#stars3 {
   width: 3px;
   height: 3px;
   background: transparent;
   box-shadow: 1559px 582px #845ef7, 277px 632px #845ef7, 767px 989px #845ef7, 1760px 1898px #845ef7, 1523px 1926px #845ef7, 149px 278px #845ef7, 593px 324px #845ef7, 691px 230px #845ef7, 277px 1212px #845ef7, 659px 444px #845ef7, 1672px 1574px #845ef7, 1247px 951px #845ef7, 1800px 235px #845ef7,
      1835px 824px #845ef7, 1325px 37px #845ef7, 123px 1512px #845ef7, 146px 1377px #845ef7, 1642px 1609px #845ef7, 1472px 581px #845ef7, 594px 116px #845ef7, 1609px 1023px #845ef7, 1554px 1174px #845ef7, 1883px 491px #845ef7, 870px 1385px #845ef7, 1546px 1622px #845ef7, 442px 1867px #845ef7,
      1102px 1419px #845ef7, 812px 1210px #845ef7, 69px 594px #845ef7, 1638px 755px #845ef7, 491px 1262px #845ef7, 372px 1222px #845ef7, 700px 858px #845ef7, 451px 535px #845ef7, 240px 1312px #845ef7, 878px 1322px #845ef7, 1510px 969px #845ef7, 151px 309px #845ef7, 254px 1646px #845ef7,
      547px 1450px #845ef7, 1039px 1164px #845ef7, 561px 118px #845ef7, 1190px 1352px #845ef7, 1238px 1624px #845ef7, 323px 1704px #845ef7, 1697px 127px #845ef7, 1497px 551px #845ef7, 624px 442px #845ef7, 1912px 300px #845ef7, 1153px 1137px #845ef7, 860px 797px #845ef7, 1065px 703px #845ef7,
      891px 3px #845ef7, 1186px 885px #845ef7, 82px 389px #845ef7, 1515px 683px #845ef7, 259px 565px #845ef7, 70px 1492px #845ef7, 657px 191px #845ef7, 1224px 1809px #845ef7, 900px 1666px #845ef7, 504px 673px #845ef7, 1001px 370px #845ef7, 51px 641px #845ef7, 1634px 1834px #845ef7,
      771px 1852px #845ef7, 781px 394px #845ef7, 557px 1195px #845ef7, 1846px 1373px #845ef7, 452px 1745px #845ef7, 281px 732px #845ef7, 766px 1505px #845ef7, 1357px 1309px #845ef7, 868px 1797px #845ef7, 670px 190px #845ef7, 1881px 480px #845ef7, 1793px 539px #845ef7, 488px 422px #845ef7,
      529px 599px #845ef7, 230px 874px #845ef7, 88px 1058px #845ef7, 1566px 453px #845ef7, 1596px 1703px #845ef7, 782px 755px #845ef7, 851px 1163px #845ef7, 733px 341px #845ef7, 109px 1047px #845ef7, 758px 526px #845ef7, 1365px 1949px #845ef7, 1239px 52px #845ef7, 334px 189px #845ef7,
      1810px 415px #845ef7, 1387px 1350px #845ef7, 1198px 1789px #845ef7, 131px 1478px #845ef7, 102px 1206px #845ef7, 1687px 1428px #845ef7, 1545px 720px #845ef7, 1547px 783px #845ef7, 1521px 1139px #845ef7;
   -webkit-animation: animStar 180s linear infinite;
           animation: animStar 180s linear infinite;
}
#stars3:after {
   content: " ";
   position: absolute;
   top: 2000px;
   width: 3px;
   height: 3px;
   background: transparent;
   box-shadow: 1559px 582px #845ef7, 277px 632px #845ef7, 767px 989px #845ef7, 1760px 1898px #845ef7, 1523px 1926px #845ef7, 149px 278px #845ef7, 593px 324px #845ef7, 691px 230px #845ef7, 277px 1212px #845ef7, 659px 444px #845ef7, 1672px 1574px #845ef7, 1247px 951px #845ef7, 1800px 235px #845ef7,
      1835px 824px #845ef7, 1325px 37px #845ef7, 123px 1512px #845ef7, 146px 1377px #845ef7, 1642px 1609px #845ef7, 1472px 581px #845ef7, 594px 116px #845ef7, 1609px 1023px #845ef7, 1554px 1174px #845ef7, 1883px 491px #845ef7, 870px 1385px #845ef7, 1546px 1622px #845ef7, 442px 1867px #845ef7,
      1102px 1419px #845ef7, 812px 1210px #845ef7, 69px 594px #845ef7, 1638px 755px #845ef7, 491px 1262px #845ef7, 372px 1222px #845ef7, 700px 858px #845ef7, 451px 535px #845ef7, 240px 1312px #845ef7, 878px 1322px #845ef7, 1510px 969px #845ef7, 151px 309px #845ef7, 254px 1646px #845ef7,
      547px 1450px #845ef7, 1039px 1164px #845ef7, 561px 118px #845ef7, 1190px 1352px #845ef7, 1238px 1624px #845ef7, 323px 1704px #845ef7, 1697px 127px #845ef7, 1497px 551px #845ef7, 624px 442px #845ef7, 1912px 300px #845ef7, 1153px 1137px #845ef7, 860px 797px #845ef7, 1065px 703px #845ef7,
      891px 3px #845ef7, 1186px 885px #845ef7, 82px 389px #845ef7, 1515px 683px #845ef7, 259px 565px #845ef7, 70px 1492px #845ef7, 657px 191px #845ef7, 1224px 1809px #845ef7, 900px 1666px #845ef7, 504px 673px #845ef7, 1001px 370px #845ef7, 51px 641px #845ef7, 1634px 1834px #845ef7,
      771px 1852px #845ef7, 781px 394px #845ef7, 557px 1195px #845ef7, 1846px 1373px #845ef7, 452px 1745px #845ef7, 281px 732px #845ef7, 766px 1505px #845ef7, 1357px 1309px #845ef7, 868px 1797px #845ef7, 670px 190px #845ef7, 1881px 480px #845ef7, 1793px 539px #845ef7, 488px 422px #845ef7,
      529px 599px #845ef7, 230px 874px #845ef7, 88px 1058px #845ef7, 1566px 453px #845ef7, 1596px 1703px #845ef7, 782px 755px #845ef7, 851px 1163px #845ef7, 733px 341px #845ef7, 109px 1047px #845ef7, 758px 526px #845ef7, 1365px 1949px #845ef7, 1239px 52px #845ef7, 334px 189px #845ef7,
      1810px 415px #845ef7, 1387px 1350px #845ef7, 1198px 1789px #845ef7, 131px 1478px #845ef7, 102px 1206px #845ef7, 1687px 1428px #845ef7, 1545px 720px #845ef7, 1547px 783px #845ef7, 1521px 1139px #845ef7;
}

@-webkit-keyframes animStar {
   from {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
   }
   to {
      -webkit-transform: translateY(-2000px);
              transform: translateY(-2000px);
   }
}

@keyframes animStar {
   from {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
   }
   to {
      -webkit-transform: translateY(-2000px);
              transform: translateY(-2000px);
   }
}

