.popup-bg {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 5;
   /* background-image: url("https://images.unsplash.com/photo-1604871000636-074fa5117945?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"); */
   background-image: linear-gradient(to bottom, rgba(88, 4, 121, 0.411), rgba(26, 31, 75, 0.609)), url("https://images.unsplash.com/photo-1604871000636-074fa5117945?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
}
.competition-image {
   width: 125px;
   height: 105px;
   margin-left: 5% !important;
}
.popup-bg::before {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 4;
}
.popup-content-rows {
   display: flex;
   flex-direction: row;
}

.popup-content-container {
   display: flex;
   flex-direction: column;
   width: 90%;
   border-radius: 12px;
   height: 38rem;
   z-index: 10;
   background: #100f20fb;
   border-radius: 20px;
   color: white;
   font-family: "Open Sans", sans-serif;
}
.popup-content-container .popup-sidebar {
   background-color: transparent;
   display: flex;
   flex-direction: column;
   min-width: 15rem;
   border-radius: 12px 0px 0 12px;
   box-shadow: none;
   z-index: 10;
   border-right: 1px solid rgba(255, 255, 255, 0.18);
   padding: 0 1rem;
   font-family: "Open Sans", sans-serif;
}

.header-container {
   margin-top: 40px;
}
.competition-name {
   font-size: 3.2rem;
   font-weight: 600;
   font-family: "Raleway", sans-serif;
}
.competition-category {
   color: #b061ff;
}
.popup-content-container .popup-sidebar .close-container-mobile {
   display: flex;
   margin: 0.5rem;
   flex-direction: row-reverse;
}
.popup-content-container .popup-sidebar .close-container-mobile .close-button {
   position: fixed;
   width: 2rem;
   height: auto;
   fill: white;
   right: 10px;
}
.popup-content-container .popup-sidebar {
   display: flex;
   flex-direction: column;
}
.popup-content-container .popup-sidebar.image-title-box {
   display: flex;
   flex-direction: column;
}
.popup-content-container .popup-sidebar.image-title-box img {
   padding: 1rem;
   background-color: transparent;
   border-radius: 0px;
   margin: 2rem auto 1rem;
   max-width: 10rem;
   border: none;
   box-shadow: none;
   text-align: center;
}
.popup-content-container .popup-sidebar .header-container .image-title-box .name {
   text-align: center;
   font-size: 1.4rem;
   font-weight: 600;
   margin: 0 2rem 0.5rem 2rem;
   overflow-wrap: break-word;
}
.popup-content-container .popup-sidebar .event-anchor-tag {
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
}
.popup-content-container .popup-sidebar .event-register-button {
   background-color: transparent;
   color: white;
   margin: 0 2rem;
   margin-top: 1rem;
   padding: 0.5rem 2.5rem;
   font-size: 1.3rem;
   border-radius: 10px;
   font-family: "Open Sans", sans-serif;
   border: 0.0625rem solid #0086ce;
   border-color: #afd9f0;
   cursor: pointer;
   transition: 200ms ease-in-out;
}
.popup-content-container .popup-sidebar .header-container .event-register-button:focus {
   outline: none !important;
}
.popup-content-container .popup-sidebar .header-container .event-register-button:hover {
   background-color: white;
   transform: scale(1.05);
   color: black;
   outline: none;
}
.popup-content-container .popup-sidebar .reg-prize-box {
   display: flex;
   flex-direction: column;
   gap: 0.3rem;
}
.popup-content-container .popup-sidebar .alt-register {
   margin-top: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}
.popup-content-container .popup-sidebar .alt-register span {
   max-width: 15rem;
   color: white;
   text-align: center;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
   list-style: none;
   padding: 1rem 1rem;
   color: #000;
   font-size: 1rem;
   font-weight: 200 !important;
   margin-top: 0;
   flex-wrap: wrap;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
   padding: 0.4rem;
   margin: 0.9rem 0.5rem;
   border-radius: 3px;
   color: white;
   text-align: center;
   border: 1px solid;
   cursor: pointer;
   transition: 0.5s ease-out;
   font-weight: 100;
   opacity: 0.8;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #191833fb, 0 0 0 3.1px #fff !important;
}
.popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li.active {
   opacity: 1;
   background-color: #b061ff;
   border: 1px solid #b061ff;
   box-shadow: 0 0 0 3px #191833fb, 0 0 0 3.5px #b061ff;
   color: white;
   border-radius: 6px;
}
.popup-content-container .popup-stack {
   padding: 1rem;
   /* margin: 1rem 0 !important; */
   font-size: 1.2rem;
   line-height: 2rem;
   white-space: pre-line;
   background-color: transparent;
   border-radius: 0px 12px 12px 0px;
   height: 70vh !important;
   overflow-y: scroll;

   width: 95% !important;
}
.popup-content-container .popup-stack .close-container {
   display: none;
}
.close-container .close-button {
   fill: white;
   position: fixed;
   width: 1.7rem;
   height: auto;
   cursor: pointer;
   transition: 100ms ease-in-out;
   position: absolute;
   top: 75px;
   right: 120px;
}
.close-container .close-button:hover {
   transform: scale(1.1);
   fill: var(--teritary);
}
.popup-content-container .popup-stack .about-section {
   display: flex;
   justify-content: center;
   font-weight: 200 !important;
   color: var(--description-color);
}
.popup-content-container .popup-stack .about-extra {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   bottom: 0;
   margin: 2rem;
}

.popup-content-container .popup-stack .about-extra-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 2rem 5rem;
   /* font-size: 18px; */
   white-space: nowrap;
}
.about-extra-content {
   margin-bottom: 5%;
   color: #dee2e6;
   font-weight: 200 !important;
}

.popup-content-container .popup-stack .about-extra-content .prize-money,
.popup-content-container .popup-stack .about-extra-content .date,
.popup-content-container .popup-stack .about-extra-content .venue {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.popup-content-container .popup-stack .about-extra-header {
   font-size: 18px;
   font-weight: 500;
   color: #b061ff;
   padding-bottom: 1px;
}
.popup-content-container .popup-stack .section {
   margin: 1rem 1rem;
   font-size: 19px;
}
.popup-content-container .popup-stack .contact-section {
   /* margin-top: 4rem; */
   display: flex;
   justify-content: center;
}
.popup-content-container .popup-stack .contact-section div {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin: 1.5rem 5rem;
}
.popup-content-container .popup-stack .contact-section div img {
   max-width: 20px;
   padding-right: 10px;
}
.popup-content-container .popup-stack .contact-section div .name {
   font-size: 23px;
   font-weight: 400;
   display: flex;
   justify-content: center;
   align-items: center;
   white-space: nowrap;
}
.popup-content-container .popup-stack .contact-section div .email {
   color: #ecebeb;
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .role {
   color: #b061ff;
   text-decoration: none !important;
}
.popup-content-container .popup-stack .contact-section div .email:hover {
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .phone {
   font-weight: 400;
   color: var(--description-color);
   text-decoration: none;
}
.popup-content-container .popup-stack .contact-section div .phone:hover {
   text-decoration: underline;
   cursor: pointer;
}

.competition-tile-div {
   padding: 10px 0;
   padding-left: 15px;
   text-align: left;
}

.image-title-box {
   text-align: center;
}
@media (min-width: 1500px) and (max-width: 5000px) {
   .popup-content-container {
      height: 95vh;
   }
   .popup-content-container .popup-stack .section {
      max-height: 50vh;
      overflow: scroll;
   }
}
@media screen and (max-width: 1400px) {
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      cursor: pointer;
      transition: 125ms ease-in-out;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li:hover:not(.active) {
      opacity: 1;
      transition-duration: 0.3s 0s;

      color: black;
      box-shadow: none;
   }

   .popup-content-container .popup-stack {
      min-width: 30rem;
      width: 60vw;
      overflow-y: scroll;
      max-height: 50vh;
   }

   .popup-content-container .popup-stack .close-container {
      display: flex;
      flex-direction: row-reverse;
      padding-top: -20px;
      padding-right: 15px;
   }
}

@media screen and (max-width: 850px) {
   .popup-content-container {
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 0;
      overflow: scroll;
   }
   .competition-image {
      margin: 0 auto;
   }

   .competition-name {
      font-size: 3rem;
   }

   .popup-sidebar {
      border-radius: 0;
      flex-direction: column;
      min-height: fit-content;
   }

   .popup-content-container .popup-sidebar .reg-prize-box {
      margin: auto;
      width: fit-content;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      display: flex;
      padding: 0rem 1rem;
      margin: auto;
      font-size: 1.2rem;
      justify-content: space-evenly;
   }
   .popup-content-container .popup-stack .about-section {
      text-align: center;
      /* font-size: 17px; */
   }

   .popup-content-container .popup-stack {
      min-width: 30rem;
   }
}

@media screen and (max-width: 700px) {
   .eventHead2 {
      padding-top: 6%;
   }
   /* .about-section {
      font-size: 17px;
   } */
   .competition-name {
      font-size: 2.4rem;
   }
}

@media screen and (max-width: 580px) {
   .popup-content-container {
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 0;
      overflow: scroll;
   }
   .popup-content-container .popup-sidebar {
      border-radius: 0;
      flex-direction: column;
      min-height: fit-content;
   }

   .popup-content-container .popup-sidebar .close-container-mobile .close-button {
      position: relative;
      right: -10px;
      top: 0px;
      width: 4rem;
   }

   .popup-content-container .popup-sidebar .header-container {
      justify-content: center;
      align-items: center;
   }

   .popup-content-container .popup-sidebar .header-container .image-title-box img {
      margin-top: 0;
   }

   .popup-content-container .popup-sidebar .header-container .image-title-box .name {
      font-size: 2rem !important;
   }

   .popup-content-container .popup-sidebar .header-container .reg-prize-box {
      margin: auto;
      width: fit-content;
   }

   .popup-content-container .popup-sidebar .header-container .alt-register {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 15px;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      display: flex;
      padding: 0rem 1rem;
      margin: auto;
      font-size: 1.2rem;
      justify-content: center;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      font-size: 0.9rem;
      padding: 0.4rem 0;
      margin: 0.7rem 0.1rem;
      min-width: 8ch;
      border-radius: 6px;
   }

   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li.active {
      /* background-color: white; */
      color: black;
      box-shadow: none;
   }

   .popup-content-container .popup-stack {
      font-size: 1.1rem;
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100%;
   }

   .popup-content-container .popup-stack .about-extra {
      margin: 0rem;
      width: 375px;
   }

   .popup-content-container .popup-stack .about-extra-content {
      margin: 0 10px;
      font-size: 17px;
      padding-bottom: 100px;
   }

   .popup-content-container .popup-stack .about-extra-content .venue {
      white-space: normal;
   }

   .popup-content-container .popup-stack .about-extra-header {
      font-size: 20px;
   }

   .popup-content-container .popup-stack .section {
      font-size: 17px;
      margin: 0;
      padding: 0.5rem;
      padding-top: 0.5rem;
      width: 100vw;
      box-sizing: border-box;
   }

   .popup-content-container .popup-stack .contact-section {
      margin-top: 0rem;
      flex-direction: column;
   }

   .popup-content-container .popup-stack .contact-section div {
      /* margin: 20px 0; */
      margin-top: 0px;
   }

   .popup-content-container .popup-stack .contact-section div .name {
      font-size: 18px;
   }
   .competition-name {
      font-size: 2.2rem;
   }
}

@media (max-width: 992px) {
   .about-extra {
      display: flex;
      justify-content: space-around;
   }
   .competition-popup-hr {
      margin-top: -10px;
   }
   .popup-content-rows {
      flex-direction: column;
   }
   /* .contact-section{
      margin-top: 10%;
   } */

   .popup-nav {
      display: flex;
      justify-content: space-evenly;
   }

   .popup-content-container .popup-stack {
      width: auto;
      margin-top: 10px;
      overflow-y: scroll;
   }
   .popup-content-container .popup-stack .section {
      margin: 0px;
      margin-bottom: 30px;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      width: 15%;
   }
   .image-title-box {
      text-align: center;
   }
   .close-container .close-button {
      top: 9px;
      right: 10px;
   }
}

@media (max-width: 500px) {
   .about-extra {
      font-size: 12px;
   }
   .about-section {
      font-size: 0.9rem;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav {
      padding: 0px;
      flex-wrap: nowrap;
   }
   .popup-content-container .popup-sidebar .popup-stack-switcher .popup-nav li {
      width: 5px;
   }
   .close-container .close-button {
      top: 9px;
      right: 10px;
   }
   .competition-name {
      font-size: 2rem;
   }
}
