html {
   scroll-behavior: smooth;
}

.scheduleCardContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   text-align: center;
   border-radius: 20px;
   padding: 0.5rem 50px;

   color: white;
}
/* @supports not (
  (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))
) {
  .scheduleCardContainer {
    background: rgba(189, 185, 243, 0.281);
    box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
  }
} */
.scheduleCardTime {
   font-size: 1.4rem;
   padding-left: 10%;
   text-transform: uppercase;
}
.scheduleContent {
   text-align: left;
   /* width: max-content; */
   width: 10rem;
}
.scheduleCardTitle {
   color: white;
   font-variant-caps: petite-caps;
   font-size: 1.4rem;
   font-weight: 700;
   white-space: nowrap;
   letter-spacing: 2px;
}
.scheduleCardRound {
   font-size: 0.9rem;
   font-weight: 400;
   color: #949294;
}
.scheduleCardTime {
   width: 17rem;
   color: #b061ff;
   font-weight: bold;
   font-size: 1.2rem;
   margin-right: -60%;
}
.scheduleCardTime > .time {
   color: white !important;
   font-weight: lighter !important;
}
.outerVenue {
   height: 15px;

   margin-left: 75%;
}
.scheduleCardVenue {
   width: 10rem;
   font-size: 1.2rem;
   overflow-wrap: break-word;
   text-align: left;
   margin-right: -50%;
   flex: content;
   /* margin-left: 75%; */
}
.scheduleCardEventType {
   margin-top: 8px;
   font-size: 1rem;
   text-transform: capitalize;
   font-variant-caps: petite-caps;
   font-size: 1.1rem;
   width: max-content;
}
.scheduleCardDay {
   font-size: 20px;
}
.scheduleflipIconbg {
   border-radius: 12px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 6rem;
   width: 6rem;
}
.scheduleflipIcon {
   max-width: 4rem;
   max-height: 4rem;
}
hr {
   background: rgba(96, 96, 96, 0.5);
   opacity: 0.8;
}
@media only screen and (max-width: 980px) {
   .scheduleCardContainer {
      margin: 12px 0;
   }
   .scheduleCardTime {
      font-size: 0.8rem;
      padding-left: 50%;
   }
   .scheduleCardTitle {
      font-variant-caps: petite-caps;
      font-size: 1rem;
   }
   .scheduleContent {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
   }
   .scheduleCardEventType {
      text-transform: capitalize;
      font-variant-caps: petite-caps;
   }
   .scheduleCardDay {
      font-size: 20px;
   }
   .outerVenue {
      margin-left: 5%;
   }
   .scheduleCardVenue {
      display: none;
   }
}
@media only screen and (max-width: 650px) {
   .scheduleCardContainer {
      padding: 0.5rem 15px;
   }

   .scheduleCardTime {
      font-size: 1rem;
      padding-left: 6%;
   }
   .scheduleCardTitle {
      font-size: 1rem;
   }
   .scheduleContent {
      width: 5rem;
      padding-left: 4%;
   }

   .scheduleCardVenue {
      width: 5rem;
      text-align: left;
      font-size: 0.9rem;
      display: flex;
      flex-wrap: wrap;
      margin-right: -50%;
      padding-top: 20%;
      padding-left: -20%;
   }
   .scheduleCardRound {
      font-size: 0.8rem;
   }
}
@media only screen and (max-width: 390px) {
   .scheduleCardContainer {
      padding: 0.5rem 15px;
   }
}
@media only screen and (max-width: 390px) {
   .scheduleCardContainer {
      padding: 0.5rem 10px;
   }
   .scheduleContent {
      width: 4rem;
   }
   .scheduleCardTitle {
      font-size: 0.8rem;
   }
   .scheduleCardTime {
      font-size: 0.7rem;
   }
   .scheduleCardVenue {
      font-size: 0.9rem;
      margin-right: -59%;
   }
}

@media screen and (max-width: 1400px) {
   .scheduleCardContainer {
      transition: 0.3s;
   }
}

@media screen and (max-width: 540px) {
   .scheduleflipIcon {
      max-width: 3rem;
      max-height: 3rem;
      margin-right: 0.5rem;
   }
}
