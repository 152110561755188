@import url("https://fonts.googleapis.com/css?family=Raleway");

.contacts {
   position: relative;
   z-index: 0;
   overflow-x: hidden;
   background: transparent;

   position: relative;
   z-index: 101;
   display: grid;
   gap: 2.5rem;
   max-width: 100%;
   margin: 0px auto;
   padding: 1.5rem;
}
h1 {
   position: relative;
}
.container {
   display: grid;
   grid-template-columns: auto auto auto auto;
   grid-row-gap: 70px;
   grid-column-gap: 5px;
   z-index: 2;
   margin: 0 170px;
   margin-top: 4rem;
   padding-bottom: 3rem;
   justify-items: center;
}
@media screen and (max-width: 1700px) {
   .container {
      grid-template-columns: auto auto auto auto;
      margin-left: 100px;
      margin-right: 100px;
      margin-bottom: 5vh;
   }
}
@media screen and (max-width: 1550px) {
   .container {
      grid-template-columns: auto auto auto auto;
      margin-left: 0px;
      margin-right: 50px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1390px) {
   .container {
      grid-template-columns: auto auto auto;
      margin-left: 0px;
      margin-right: 100px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1200px) {
   .container {
      grid-template-columns: auto auto auto;
      margin-left: 0px;
      margin-right: 50px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 1048px) {
   .container {
      grid-template-columns: auto auto;
      margin-left: 0px;
      margin-right: 120px;
   }
   .title {
      font-size: 2.5rem;
   }
}
@media screen and (max-width: 800px) and (max-height: 1300px) {
   .container {
      grid-template-columns: auto;
      margin-left: 0px;
      margin-right: 0px;
   }
}
@media screen and (max-width: 580px) {
   .container {
      grid-template-columns: auto;
      margin-left: 0px;
      margin-right: 0px;
   }

   .page-title {
      color: var(--heading);
      font-size: 63px;
      font-weight: 850;
      font-family: "Raleway", sans-serif;
      text-align: center;
      margin-top: 12% !important;
   }
}
