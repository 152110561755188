.profilePopupContainer {
   margin: 0 auto;
   background: #191833fb;
   box-shadow: 0px 5px 30px 0px #0000004d;
   border-radius: 12px;
   padding: 64px 72px;
   font-family: "Open Sans", sans-serif;
   margin-left: 90vw;
   margin-top: -70vh;
   width: 55rem;
   height: 30rem;
   position: relative;
}
.profilePopupContainer::before {
   position: absolute;
   content: "";
   top: -160px;
   left: -640px;
   width: 190vw;
   height: 150vh;
   background-image: url("../../assets/jpg/popupbk.jpg");
   filter: blur(13px) grayscale(20%);
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   z-index: -1;
}
.profile-row-2 {
   display: flex;
   flex-direction: column;
   gap: 4rem;
   margin-top: 6%;
}
.row-content {
   width: 35vw;
   margin-top: 3%;
}
@media (min-width: 1500px) and (max-width: 2900px) {
   .profilePopupContainer {
      width: 60rem;
      height: 30rem;
      position: relative;
      margin-top: 20%;
   }
}

@media screen and (max-width: 600px) {
   .profilePopupContainer::before {
      left: -200px;
   }
   .profilePopupContainer {
      /* margin: 0 auto; */
      margin-right: 10%;
   }
   .row-content {
      display: none;
   }
}

@media screen and (max-width: 400px) {
   .profilePopupContainer::before {
      left: -100px;
   }
   .profilePopupContainer {
      align-items: center;
      margin-left: 20%;
   }
   .row-content {
      display: none;
   }
}

.close-container-mobile {
   display: flex;
   flex-direction: row-reverse;
}
.close-container-mobile .close-button {
   fill: white;
   position: fixed;
   width: 2rem;
   height: auto;
   cursor: pointer;
   transition: 100ms ease-in-out;
   z-index: 200 !important;
}
.close-container-mobile .close-button:hover {
   transform: scale(1.1);
   fill: var(--teritary);
}

@media screen and (max-width: 850px) {
   .profilePopupContainer {
      width: 30rem;
      margin-left: -9rem;
      align-items: center;
   }
}

@media screen and (max-width: 700px) {
   .profilePopupContainer {
      margin: 0 auto;
   }
}
@media screen and (max-width: 540px) {
   .profilePopupContainer {
      max-height: 70vh;
      margin-top: -60px;
      padding: 0px;
      padding-bottom: 20px;
      padding-right: 5px;
      left: -150px !important;
      width: 18rem;
      margin: 0 auto;
      /* margin-left: -1.5rem; */
   }
   .profilePopupContainer::before {
      position: absolute;
      left: -220px;
   }
   .profile-popup-content {
      width: 260px;
      max-height: 60vh;
      overflow: scroll;
      padding: 30px;
      overflow-x: hidden;
      /* text-align: center; */
   }

   .close-container-mobile {
      padding-top: -20px;
      padding-right: 15px;
   }

   .close-container-mobile .close-button {
      width: 2rem;
   }
}
