.contact-card {
   margin: 0 10px;
   color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #b680e23b;
   border-radius: 20px;
   margin-bottom: 4%;
   /* box-shadow: 0px 5px 25px 5px rgb(201, 200, 200) */
}
.contact-card:hover {
   transform: translateY(-15px);
   transition: 0.5s 0.1s;
}
.contact-card:hover .contact-card-img {
   transform: translateY(-20px);
   transition: 0.5s 0.1s;
}
.contact-card-img {
   padding: 1.5rem;
   padding-bottom: 0.5rem;
   margin-top: -4rem;
}
.contact-img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.contact-card-img img {
   max-width: 16.5rem;
   width: 245px;
   height: 350px;
   border-radius: 0.55rem !important;
   box-shadow: 0px 5px 30px 0px #0000004d;
}
.contact-card-body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 1.5rem !important;
   padding-top: 0.5rem !important;
}
.contact-name {
   margin-top: 0;
   margin-bottom: 6px;
   font-size: 1.5rem;
   font-weight: 600;
   color: rgb(228, 227, 227);
}
.contact-design {
   font-size: 0.9rem;
   color: rgb(172, 172, 172);
}
.contact-card-links {
   width: 100%;
   height: 25px;

   margin: 1rem 0;
   display: flex;
   justify-content: center;
   align-items: center;
   list-style: none;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
   border-radius: 0.55rem;
   /* background-color: white;
   opacity: 0.8; */
}
.contact-card-links li {
   padding: 0 10px;
   padding: 4%;
}

.contact-card-links li .uil {
   font-size: 1.5rem;
}
.contact-card-links li a img:hover {
   border-radius: 20%;
   transform: scale(1.3);
   border: 1px solid #b57bee;
}
@media screen and (max-width: 1500px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 14.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
   .contact-card-links {
      padding: 0.5rem !important;
      /* margin-left: 1rem;
    margin-bottom: 1rem; */
   }
}
@media screen and (max-width: 1390px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 16.75rem;
   }
   .contact-card-body {
      padding: 0.5rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 1.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
   .contact-card-links {
      width: 95%;
   }
}
@media screen and (max-width: 1200px) {
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 14.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
}
@media screen and (max-width: 1048px) {
   .contact-card {
      flex-wrap: wrap;
   }
   .contact-card-img {
      padding: 1.5rem;
      padding-bottom: 0.5rem;
   }
   .contact-card-img img {
      max-width: 16.75rem;
   }
   .contact-card-body {
      padding: 1.5rem !important;
      padding-top: 0.5rem !important;
   }
   .contact-name {
      margin-top: 0;
      margin-bottom: 6px;
      font-size: 1.25rem;
   }
   .contact-design {
      font-size: 1rem;
   }
}
