.home-div {
   padding: 3.5%;
}
.home-page-title {
   font-weight: 800;
   text-align: center;
   font-size: 57px;
   background-image: linear-gradient(60deg, #e21143, #3a4eff);
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent;
   font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 768px) {
   .home-page-title {
      font-size: 40px;
   }
   .sponser {
      margin-bottom: 5%;
   }

   .sponsorTitle {
      display: none;
   }
   .sponser-heading {
      margin-bottom: 18px;
   }
   .home-page-description {
      text-align: center;
   }
}

.home-page-description {
   font-size: 18px;
   font-weight: 400 !important;
   color: var(--description-color);
   margin-right: 5%;
   margin-left: 5%;
   margin-top: 5%;
}
/* Landing page */

.outer-div {
   width: 98vw;
   height: 98vh;
   position: relative;
   margin: 0 !important;
}
.logo-reveal-div {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   /* background-color: blue; */
}
.static-mec-div {
   z-index: 9;
   /* background-color: red; */
}
/* About */

.abt-animation {
   padding: 0 auto !important;
   padding-top: 5%;
   margin: 0 auto !important;
}
.prev-logo {
   display: flex;
   justify-content: space-evenly;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.prev_excel {
   height: 45px;
   text-align: center;
}
._2018logo {
   width: 40px;
}

/*-------------------------------- SPONSERS SECTION -------------------------------- */
.sponsors {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   margin-left: 1%;
}
.sponser-logos {
   display: flex;
   justify-content: space-around;
}
.sponser {
   width: -moz-fit-content;
   width: fit-content;
}

.sponsorTitle {
   font-size: 1rem;
   text-align: center;
   color: var(--description-color);
}
.sponserImage {
   max-height: 250px;
   margin-right: 10px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
/* @media screen and (max-width: 1000px).prev_excel {
   .mobile-space {margin-bottom:10%;}
   } */
@media screen and (max-width: 1000px) {
   .outer-div {
      width: 100vw;
      height: 65vh;

      position: relative;
   }
   .static-mec-div {
      height: 70vh !important;
      width: 100vw;
   }
   .prev_excel {
      padding-bottom: 24%;
      height: 55px;
      display: flex;
      margin-left: 5%;
      justify-content: space-between !important;
   }
   .about-section {
      margin-top: -5%;
      margin-bottom: 10%;
   }
   .descrip {
      margin-bottom: 20%;
   }
}

@media (min-width: 1800px) and (max-width: 2070px) {
   .home-page-description {
      font-size: 21px;
      font-weight: 400 !important;
      color: var(--description-color);
      padding: 1%;
   }
   .prev_excel {
      height: 100px !important;
      display: flex;
      margin-left: 5%;
      justify-content: space-between !important;
   }
}
