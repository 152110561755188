.eventCardContainer {
   position: relative;
   display: flex;
   gap: 2rem;
   padding: 2rem 3rem;
   border-radius: 0.55rem;
   color: white;
   cursor: pointer;
   /* background: #331f4e9d; */
   background: #2c1c479d;
}
.eventCardContainer::before {
   position: absolute;
   top: 0;
   left: 0;
}
.eventCardContainer .eventCardHeaderBox {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin: auto 0;
   gap: 8px;
   padding-top: 0.5rem;
}
.eventCardContainer .eventCardHeaderBox .eventCardType {
   opacity: 0.7;
   letter-spacing: 2px;
   font-size: 1rem;
   text-transform: capitalize;
   margin-top: 15px;
}
.eventCardContainer .eventCardHeaderBox .eventCardDate {
   opacity: 0.7;
   letter-spacing: 2px;
   font-size: 0.9rem;
}
.eventCardContainer .eventInfoBox {
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   margin: 10px 0;
   margin-left: -5px;
   line-height: 26px;
}
.eventCardContainer .eventInfoBox .eventCardTitle {
   font-size: 40px;
   font-weight: 900;
   line-height: 40px;
}
.eventCardContainer .eventInfoBox .eventCardInfo {
   margin: 2rem 0;
   position: relative;
   overflow: hidden;
   white-space: pre-line;
   font-size: 19px;
}
@media screen and (max-width: 1500px) {
   .eventCardContainer {
      gap: 2rem;
      padding: 2rem 3rem;
   }
   .eventCardHeaderBox {
      gap: 80px;
      padding-top: 0.5rem;
      padding-left: 25px;
      padding-right: 25px;
   }
   .eventCardHeaderBox .eventCardType {
      opacity: 0.7;
      margin-top: 10px;
      letter-spacing: 2px;
      font-size: 1rem;
      text-transform: capitalize;
   }
   .eventCardHeaderBox .eventCardDate {
      opacity: 0.7;
      letter-spacing: 2px;
      font-size: 0.9rem;
   }
   .eventInfoBox {
      margin-left: 1rem;
   }
   .eventInfoBox .eventCardTitle {
      font-size: 30px !important;
   }
   .eventInfoBox .eventCardInfo {
      font-size: 16px !important;
      margin: 1rem 0 !important;
   }
}
@media screen and (max-width: 580px) {
   .eventCardContainer {
      padding: 1rem 0.1rem;
   }
   .eventCardContainer .eventCardHeaderBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .eventCardContainer .eventCardHeaderBox .eventCardType {
      display: none;
   }
   .eventCardContainer .eventInfoBox {
      margin-top: 5px;
      margin-left: 0;
   }
   .eventCardContainer .eventInfoBox .eventCardTitle {
      font-size: 23px !important;
   }
   .eventCardContainer .eventInfoBox .eventCardInfo {
      margin-top: 1rem;
      font-size: 15px !important;
   }
}

@media screen and (max-width: 580px) {
   .eventCardContainer {
      flex-direction: column;
   }

   .eventCardContainer .eventCardHeaderBox .flipIcon {
      max-width: 6rem;
      max-height: 6rem;
   }

   .eventCardContainer .eventInfoBox {
      text-align: center;
   }

   .eventCardContainer .eventInfoBox .eventCardTitle {
      font-size: 30px;
   }
}

@media screen and (max-width: 1400px) {
   .eventCardContainer .eventInfoBox {
      padding-left: 2rem;
      padding-right: 2rem;
   }
}

.eventCardImg {
   margin: 0 auto;
   align-self: center;
}
