.competition-card-container {
   display: flex;
   justify-content: center;
   align-items: center;
}
.competition-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 400px;
   width: 400px;
   color: white;
   background-color: #191629;
   background: transparent;
   box-shadow: 0px 5px 30px 0px #0000004d;
   border-radius: 5%;
}
.competition-card:hover {
   cursor: pointer;
   /* transform: translateY(-8px); */
   transition: 0.5s 0.1s;
}
.competition-card .icon {
   border-radius: 9999px;
   margin: 1rem;
   margin-top: 2rem;
   padding: 2em;
   padding-bottom: 0.5rem;
}

.competition-card .title {
   text-align: center;
   display: flex;
   justify-content: center;
   font-size: 1.3rem;
   /* margin: 1rem 0; */
}
.card-flip-title {
   font-size: 1.3rem !important;
   color: var(--subtitle-color);
}
.competition-card .title span {
   font-weight: bold;
   font-size: 1.1rem !important;
}
.competition-card .category {
   text-transform: capitalize;
   color: var(--subtitle-color);
   font-weight: 600;
}
.competition-card .description {
   margin: 1rem;
   position: relative;
   white-space: pre-line;
}

@keyframes anvil {
   0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
   }
   100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
   }
}
.popup-content {
   animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@media screen and (max-width: 1870px) {
   .competition-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 400px;
      width: 400px;
      color: white;
      filter: grayscale(20%);
   }

   .competition-card .icon {
      padding-bottom: 0.5rem;
   }
   .competition-card .icon img {
      max-width: 7.5rem;
   }
   .competition-card .title {
      margin: 1rem 0;
   }
   .competition-card .title span {
      font-size: 1.4rem;
   }
   .competition-card .category {
      font-size: 1rem;
   }
}
@media screen and (max-width: 1700px) {
   .competition-card {
      width: 300px;
      height: 300px;
   }
   .competition-card .icon {
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 5rem;
      max-height: 5rem;
   }
   .competition-card .title {
      margin: 1rem 0;
      margin-bottom: 1.25rem;
   }
   .competition-card .title span {
      font-size: 1.3rem;
   }
   .competition-card .category {
      font-size: 1rem;
   }
}

@media screen and (max-width: 1550px) {
   .competition-card {
      width: 250px;
      height: 250px;
   }
   .competition-card .icon {
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 4.4rem;
      max-height: 4.4rem;
   }
   .competition-card .title {
      margin: 0.2rem 0;
   }
   .competition-card .title span {
      font-size: 1.3rem;
   }
}
@media screen and (max-width: 1290px) {
   .competition-card {
      width: 230px;
      height: 230px;
   }
   .competition-card .icon {
      padding: 1rem;
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 4.2rem;
      max-height: 4.2rem;
   }
   .competition-card .title {
      margin: 0.1rem 0;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
   .competition-card .category {
      font-size: 0.9rem;
   }
}
@media screen and (max-width: 870px) {
   .competition-card-container {
      width: 220px;
   }
   .competition-card {
      width: 220px;
      height: 220px;
   }
   .competition-card .icon {
      padding: 0.2rem;
   }
   .competition-card .icon img {
      max-width: 4rem;
   }
   .competition-card .title {
      margin: 0;
      margin-bottom: 0.5rem;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
}
@media screen and (max-width: 800px) and (max-height: 1300px) {
   .competition-card-container {
      width: 250px;
   }
   .competition-card {
      width: 250px;
      height: 250px;
   }
   .competition-card .icon {
      padding: 1rem;
   }
   .competition-card .icon img {
      max-width: 6rem;
   }
   .competition-card .title {
      margin: 0;
      margin-bottom: 0.5rem;
      margin-top: -35px;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
}
@media screen and (max-width: 720px) and (max-height: 1300px) {
   .competition-card-container {
      margin: 10px;
      width: 210px;
   }
   .competition-card {
      width: 210px;
      height: 210px;
   }
   .competition-card .icon {
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 0;
      padding-bottom: 0rem;
   }
   .competition-card .icon img {
      max-width: 6rem;
   }
   .competition-card .title {
      margin: 0.3rem 0;
   }
   .competition-card .title span {
      font-size: 1.2rem;
   }
   .competition-card .category {
      font-size: 0.9rem;
   }
}

@media screen and (max-width: 480px) {
   .title {
      font-size: 10px;
      padding-top: 18%;
   }

   .competition-card-container {
      width: 160px;
      margin: 10px;
      /* ;
      */
   }
   .competition-card {
      width: 170px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .competition-card .icon {
      padding: 0rem;
      padding-bottom: 0rem;
      margin-bottom: 0rem;
      padding-top: 12%;
   }
   .competition-card .icon img {
      max-width: 5.5rem;
   }
   .competition-card .title {
      margin: 0.1rem 0;
      padding-top: 10%;
      position: relative;
      top: 0;
      font-size: 10px !important;
   }

   .competition-card .category {
      font-size: 1.2rem;
      padding-bottom: 10px;
   }

   .competition-card .category span {
      font-size: 16px;
   }
   .card-flip-title {
      font-size: 1.2rem !important;
   }
}

@media screen and (max-width: 400px) {
   .competition-card-container {
      width: 150px;
      margin: 10px;
   }
   .competition-card {
      width: 150px;
      height: 150px;
   }
   .icon {
      margin-top: 20px;
   }
   .img {
      max-width: 4rem;
   }
}
.title {
   margin: 1rem 0 0.25rem 0;
   display: flex;
}
.span {
   font-size: 0.95rem;
}

.category {
   font-size: 0.75rem;
}

@media screen and (max-width: 340px) {
   .competition-card-container {
      width: 130px;
      margin: 8px;
   }
   .competition-card {
      width: 130px;
      height: 130px;
   }
   .icon {
      margin-top: 20px;
   }
   .img {
      max-width: 3rem;
   }
}
.title {
   margin: 1rem 0 0.25rem 0;
   display: flex;
}
.span {
   font-size: 0.95rem;
}

.category {
   font-size: 0.75rem;
}

@media screen and (max-width: 580px) {
   .competition-card {
      justify-content: center;
   }

   .competition-card .icon .img {
      max-width: 4rem;
      max-height: 4rem;
   }
}
