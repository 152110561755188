.purecss-fade-in-section {
   opacity: 0;
   transform: translateY(3vh);
   visibility: hidden;
   transition: opacity 1s ease-in, transform 1s ease-out;
   will-change: opacity, visibility;
}
.purecss-fade-in-section.is-visible {
   opacity: 1;
   transform: none;
   visibility: visible;
}
.purecss-zoom-in-section {
   opacity: 0;
   transform: scale(0);
   visibility: hidden;
   transition: opacity 0.9s ease-out, transform 1.2s ease-out;
   will-change: opacity, visibility;
}
.purecss-zoom-in-section.is-visible {
   opacity: 1;
   transform: scale(1);
   visibility: visible;
}
