.manage-section {
   display: flex;
   flex-direction: column;
   padding: 1rem;
   text-align: center;
   font-family: "Open Sans", sans-serif;
}
.manage-section .button-group {
   display: flex;
   justify-content: center;
   margin-bottom: 1rem;
}
.manage-section .button-group button {
   font-size: 1rem;
   background-color: transparent;
   box-shadow: none;
   padding: 0.5rem;
   border: 1px solid #b061ff;
   width: 14ch;
   transition: 0.2s;
   color: white;
   font-family: "Open Sans", sans-serif;
}
.manage-section .button-group button:hover {
   cursor: pointer;
   background: rgba(49, 48, 82, 0.9);
}
.manage-section .button-group button:first-child {
   border-radius: 6px 0 0 6px;
   border-right: none;
}
.manage-section .button-group button:last-child {
   border-radius: 0 6px 6px 0;
}
.manage-section .button-group button.active {
   background-color: #b061ff;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   cursor: default;
}
.manage-section .view-subpage {
   display: flex;
   flex-direction: column;
}

.manage-section .view-subpage .team-members-box {
   margin: auto;
   margin-top: 1rem;
   width: fit-content;
   padding: 1rem 2rem 2rem;
   border-radius: 6px;
   text-align: center;
}
.view-team-table {
   display: flex;
   margin: auto !important;
   align-items: center;
   justify-items: center;
}
.manage-section .view-subpage .team-members-box .team-members-container {
   margin-top: 1rem;
}
.manage-section .view-subpage .team-members-box .team-members-container .impossible-state-empty {
   opacity: 0.5;
}
.table-bordered {
   border: 0.6px solid #2b2a2c;
}

.manage-section .view-subpage .team-id-help {
   margin-top: 0.1rem !important;
   font-size: 1rem;
   opacity: 0.8;
}
.manage-section .change-team-subpage {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin-top: 1.5rem;
   font-size: 25px;
}
.manage-section .change-team-subpage form {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   margin: auto;
   margin: 1rem auto;
}
.manage-section .change-team-subpage form input[type="number"] {
   padding: 0.5rem;
   border-radius: 4px;
   border: 1px solid #000 44;
   box-shadow: 0px 0px 3px #000 44;
   font-size: 1rem;
   width: 9rem;
}
.manage-section .change-team-subpage form input[type="submit"] {
   background-color: #b061ff;
   padding: 0.5rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: fit-content;
   margin: auto;
   margin-top: 0.5rem;
   cursor: pointer;
   font-size: 1rem;
   width: 9rem;
}
.manage-section .change-team-subpage form input[type="submit"]:hover {
   transform: scale(1.02);
   transition: 0.5s;
}

.manage-section .change-team-subpage form .fake-change-button {
   background-color: #b061ff;
   padding: 0.8rem;
   border-radius: 4px;
   border: none;
   color: white;
   box-shadow: 2px 2px 10px #b061ff 55;
   width: 14ch;
   margin: auto;
   margin-top: 1.3rem;
}
.dot-loader {
   width: 3rem;
   margin: auto;
   margin-top: 2rem;
}

@media screen and (max-width: 1400px) {
   .manage-section {
      margin-top: 0.1rem;
   }

   .manage-section .view-subpage {
      margin-top: 1rem;
   }
}
