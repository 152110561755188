.eventsContainer {
  position: relative;
  z-index: 101;
  display: grid;
  gap: 2rem;
  max-width: 85%;
  margin: 0px auto;
  padding: 2rem;
  /* padding-bottom: 100px; */
}
.events-categories {
  display: flex;
  flex-direction: row;
}
.category-div {
  display: none;
}
.events-group-1 {
  display: flex;
  flex-direction: row;
}
.events-group-2 {
  display: flex;
  flex-direction: row;
}
.eventsContainer2 {
  position: relative;
  z-index: 101;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 85%;
  margin: 20px auto;
  padding: 0 6vw;
  /* padding: 2rem; */
}
.sort-filter-box-2 {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 5rem;
  margin-left: 3%;
  margin-right: 3%;
  padding: 0 6vw;
}
.sort-down {
  padding: 0.4rem;
  padding-right: 0.3rem;
  border: 2px solid #adb5bd;
  border-radius: 5px;

  background-color: #100f16;
  color: white;
  float: center;
  font-size: 1rem;
}

@media screen and (min-width: 850px) {
  .category-row > .category-col {
    display: none;
    /* width: 0; */
  }
}

@media screen and (max-width: 700px) {
  .sort-filter-box-events {
    margin-top: 50px;
    margin-left: 47px;
    margin-right: 51px;
  }
  .events-categories {
    display: none;
  }
  .category-row {
    margin-left: 6%;
  }
  .category-div {
    display: flex;
  }
  .radio-tile-group {
    margin: -11%;
  }

  .sort-filter-box-2 {
    grid-gap: 0rem;
    gap: 0rem;
    margin-bottom: 10%;
    margin-left: 0px;
    font-size: 11px;
    display: flex;
    justify-content: center;
  }
  .category-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .category-div {
    display: flex;
  }
}

@media (max-width: 580px) {
  .eventsContainer {
    padding: 2rem 1.2rem;
  }
}
