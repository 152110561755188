.popup-arrow {
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
.popup-arrow path {
   fill: rgba(217, 177, 250, 0.233);
}
[role="toolTip1"].popup-content {
   width: 225px;
   height: 145px;
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
   background: rgba(217, 177, 250, 0.233);
   -webkit-backdrop-filter: blur(10.5px);
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   border-radius: 6px;
}
/* no support backdrop-filter */
@supports not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
   [role="toolTip1"].popup-content {
      background: rgba(189, 185, 243, 0.281);
      box-shadow: 0 5px 20px 0 rgba(4, 6, 44, 0.568);
   }
}
.popup-overlay {
   background: rgba(0, 0, 0, 0.5);
}
[data-popup="toolTip1"].popup-overlay {
   background: transparent;
}
.profile-popup {
   display: flex;
   flex-direction: row;
   border-radius: 6px;
   box-shadow: 4px 0px 12px #000 44;
   color: white;
   position: fixed;
   grid-gap: 1px;
}
.profile-popup .signed-in-text {
   display: flex;
   flex-direction: column;
   padding: 0.8rem 2rem;
   border-bottom: 1px solid #000 25;
   margin-top: 6%;
}
.profile-popup .buttons-box {
   display: flex;
   flex-direction: row;
   gap: 3rem;
}
.profile-popup button {
   display: flex;
   background-color: transparent;
   border: none;
   cursor: pointer;
   text-align: start;
   font-size: 0.9rem;
   opacity: 0.9;
   padding: 7px 10px;
   color: #fff;
   border: 2px solid #fff;
   border-radius: 5px;
   transition: transform 300ms ease-in-out;
}
/* .profile-popup button:first-child {
  padding-bottom: 0.5rem;
}
.profile-popup button:last-child {
  padding-top: 0.5rem;
} */
.profile-popup .buttons-box button:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.1px rgb(223, 222, 222) !important;
}
.profile-popup button:focus {
   outline: none;
}
.profile-popup button svg {
   height: 0.9rem;
   width: auto;
   padding-right: 8px;
}
.navbarProfile {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 15px 0;
   padding-bottom: 30px;
}
.navbarProfileIcon {
   max-width: 40px;
   border-radius: 20px;
   cursor: pointer;
}

.login_status .toolTip1 {
   opacity: 0;
   visibility: hidden;
   width: 9rem;
   height: 80%;
   color: #fff;
   margin-left: 4.5vw;
   border-radius: 0 5px 5px 0;

   /* Position the toolTip1 */
   position: absolute;
   z-index: 1;
   top: 50%;
   transform: translate(-45%, -50%);
   left: 100%;
   transition: all 0.2s ease;
   z-index: 100;
   /* box-shadow: 4px 8px 32px rgba(31, 38, 135, 0.37); */
}
.toolTipText1 {
   margin-top: 15%;
   font-size: 14px;
   font-weight: normal;
}
.login_status:hover .toolTip1 {
   opacity: 1;
   visibility: visible;
}
.login_status:hover .toolTip1::before {
   opacity: 1;
}
.toolTip1::before {
   left: 0;
   opacity: 0;
   border-radius: 0 5px 5px 0 !important;

   /* transition: opacity 0.2s ease; */
}

@media screen and (max-width: 700px) {
   .profile-popup {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
   }
}
