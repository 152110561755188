.radio-tile-group {
  display: flex;
  /* align-self: flex-start !important; */
}
.radio-tile-group .input-container {
  position: relative;
  height: 2.2rem;
  width: 7.8rem;
  margin: 0rem;
}
@media screen and (max-width: 650px) {
  .radio-tile-group .input-container {
    margin: 13px;
  }
  .radio-tile-group {
    display: flex;
    justify-content: center;
  }
}
.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #adb5bd;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
}
.radio-tile-group .input-container .icon svg {
  fill: #adb5bd;
  width: 3rem;
  height: 3rem;
}
.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #adb5bd;
  transition: 0.5s ease-out;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
  background-color: #b061ff;
  border: 1px solid #b061ff;
  box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
  color: white;
  transform: scale(1.1, 1.1);
  border-radius: 10px;
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .icon
  svg {
  fill: white;
  background-color: #b061ff;
}
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
  background-color: #b061ff;
}
