.event-img {
   height: 110px;
   width: 110px;
   border-radius: 10%;
}
.competition-tile-div {
   padding: 10px 0;
   padding-left: 15px;
   text-align: left;
}
