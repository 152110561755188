.dead-end-container {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   text-align: center;
   opacity: 0.8;
   /* padding: 1rem; */
   justify-content: center;
}

@media (min-width: 0px) and (max-width: 599px) {
   .dead-end-container {
      padding-top: 2rem;
   }
}
.deadend-img {
   fill: white;
   margin: auto;
   max-width: 12rem;
   height: 160px;
   width: 160px;
}
@media (min-width: 0px) and (max-width: 599px) {
   .deadend-img {
      max-width: 10rem;
   }
}
.dead-end-container .title {
   color: white;
   font-size: 2rem;
   font-weight: 700;
   display: flex;
   justify-content: center;
}
@media (min-width: 0px) and (max-width: 599px) {
   .dead-end-container .title {
      font-size: 1.2rem;
   }
}
.dead-end-container .subtitle1 {
   color: white;
   font-size: 1rem;
}

.dead-end-container .subtitle2 {
   color: white;
   font-size: 1rem;
}
