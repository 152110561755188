.ripple-button {
   border-radius: 4px;
   border: none;
   margin: 8px;
   width: 6.3rem;
   height: 2.5rem;
   background: rgb(16, 15, 22);
   color: #fff;
   overflow: hidden;
   position: relative;
   cursor: pointer;
   font-size: 0.89rem;
   font-weight: 500;
   letter-spacing: 1px;
   color: #adb5bd;

   /* CHANGE: added a border */
   border: 2px solid #adb5bd;
}

.ripple-button > .ripple {
   width: 20px;
   height: 20px;
   position: absolute;
   background: #b061ff;
   display: block;
   content: "";
   border-radius: 9999px;
   opacity: 1;
   animation: 1.5s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
   0% {
      transform: scale(1);
      opacity: 1;
   }
   50% {
      transform: scale(20);
      opacity: 0.375;
   }
   100% {
      transform: scale(35);
      opacity: 0;
   }
}

.ripple-button > .content {
   position: relative;
   z-index: 2;
}
.ripple-button:focus {
   border: 1px solid #b061ff;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.5px #b061ff;
   color: white;
   transition-delay: 0.8s;
   background-color: #b061ff;
   border-radius: 8px;
   transition-property: background-color, border-radius, box-shadow;
}
.ripple-button:hover {
   opacity: 1;
   color: white !important;
   box-shadow: 0 0 0 3px #100f16, 0 0 0 3.1px rgb(223, 222, 222) !important;
}
@media screen and (max-width: 700px) {
   .ripple-button {
      width: 5rem;
      margin-right: 1%;
   }
}
