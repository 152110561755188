.card-container2 {
   cursor: pointer;
   height: 250px;
   perspective: 600;
   position: relative;
   /* width: 140%; */
}
.card {
   height: 100%;
   position: absolute;
   transform-style: preserve-3d;
   transition: all 1s ease-in-out;
   width: 100%;
   background: transparent;
}
.card:hover {
   transform: rotateY(180deg);
}
.card .side {
   backface-visibility: hidden;
   border-radius: 6px;
   height: 100%;
   position: absolute;
   overflow: hidden;
   width: 100%;
   background: #251b419d;

   /* background-image: linear-gradient(60deg, #191629, #231831); */
}
.card .back {
   background: transparent;
   padding: 15%;
   text-align: center;
   transform: rotateY(180deg);
}
@media screen and (max-width: 600px) {
   .card {
      width: 105%;
      height: 95%;
   }
   .card .side,
   .card .back {
      height: 100%;
   }
   .title {
      font-size: 1rem;
   }
}
@media (min-width: 1800px) and (max-width: 2070px) {
   .card-container2 {
      cursor: pointer;
      height: 300px !important;
      perspective: 600;
      position: relative;
      /* width: 140%; */
   }
}
