.register-button {
   background-color: transparent;
   color: white !important;
   margin: 1rem 2rem;
   padding: 0.5rem;
   font-size: 1rem;
   border-radius: 10px;
   font-family: "Open Sans", sans-serif;
   border: 2px solid #adb5bd !important;
   cursor: pointer;
   transition: 200ms ease-in-out;
   text-decoration: none !important;
}

.register-button:hover {
   background-color: #b061ff !important;
   border: 2px solid #b061ff;
   border-radius: 10px;
   color: white !important;
}

.registered {
   cursor: default;
   font-style: italic;
   font-size: 1rem;
}
.registered:hover {
   transform: none;
   background: transparent;
   color: white;
}

.update-mobile-popup {
   display: flex;
   flex-direction: column;
   background-color: white;
   padding: 1rem;
   border-radius: 6px;
   box-shadow: 0px 0px 12px #000 55;
}
.update-mobile-popup .close-container {
   display: flex;
   flex-direction: row-reverse;
}
.update-mobile-popup .close-container svg {
   height: 1rem;
   width: auto;
}
.update-mobile-popup .content {
   display: flex;
   flex-direction: column;
   padding: 1rem;
   box-sizing: border-box;
}
.update-mobile-popup .content .buttons-container {
   display: flex;
   gap: 0.5rem;
   width: fit-content;
   margin: auto;
   margin-top: 1rem;
}
.update-mobile-popup .content .buttons-container button {
   border: none;
   background-color: #b061ff;
   border-radius: 6px;
   color: white;
   box-shadow: 0px 0px 8px #37b9ff 99;
   padding: 0.6rem;
}

@media screen and (max-width: 580px) {
   .register-button:focus {
      margin: 1rem auto;
   }

   .update-mobile-popup .content {
      width: 80vw;
   }
}

@media screen and (max-width: 850px) {
   .register-button:focus {
      margin: 1rem auto;
   }
}

@media screen and (max-width: 1400px) {
   .reg-closed:hover {
      background-color: white;
      transform: scale(1.05);
      color: black;
   }
}
